import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { NamedLink } from '../index';

import css from './DMCAPrivacyPolicy.module.css';

const DMCAPrivacyPolicy = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  // prettier-ignore
  return (
    <div className={classes}>
      <b className={css.subHeading}>Queer Healers Copyright/DMCA Policy
      
</b>

      <p className={css.lastUpdated}>Reporting Claims of Copyright Infringement
</p>

      <p>
      We take claims of copyright infringement seriously. We will respond to notices of alleged copyright infringement that comply with applicable law. Our designated copyright agent for all DMCA notices and counternotices is:
</p>
<div className={css.address} >
      <p>
      Bridget Conway-Taylor
      </p>
      <p>
      Queer Healers Co.
  
      </p>
      <p>
      374 Eastern Parkway D1    
      </p>
      <p>
      Brooklyn, NY 11225    
      </p>
      <p>
      Phone: 3105271689     
      </p>
      <p>
      Email: <a href='mailto:hello@queerhealers.com' > hello@queerhealers.com</a> 
      </p>
</div>


    <ol className={css.listContainer} >
      <li  className={css.listHeading} >
      Notification Procedures
      </li>
      <li><p>If you believe any materials on the Queer Healers Service infringe your copyright, you may request removal of those materials by submitting written notification to our copyright agent designated above. In accordance with the Online Copyright Infringement Liability Limitation Act of the Digital Millennium Copyright Act (17 U.S.C. § 512) ("DMCA"), the written notice (the "DMCA Notice") must include substantially the following:</p>
     </li>
       <li>
      <ul  className={css.subListContainer} >
      

      <ul>
        <li style={{listStyleType:"square"}} >Your physical or electronic signature.

 
</li>
        <li style={{listStyleType:"square"}} >Identification of the copyrighted work you believe to have been infringed or, if the claim involves multiple works on the Website, a representative list of such works.


</li>
        <li style={{listStyleType:"square"}} >Identification of the material you believe to be infringing in a sufficiently precise manner to allow us to locate that material.

</li>
        <li style={{listStyleType:"square"}} >Adequate information by which we can contact you (including your name, postal address, telephone number, and, if available, email address).


</li>
<li style={{listStyleType:"square"}} >A statement that you have a good faith belief that use of the copyrighted material is not authorized by the copyright owner, its agent, or the law.



</li>
<li style={{listStyleType:"square"}} >A statement that the information in the written notice is accurate.



</li>
<li style={{listStyleType:"square"}} >A statement, under penalty of perjury, that you are authorized to act on behalf of the copyright owner.



</li>

        

      </ul>

     

     
    

    
     
      </ul>

      </li>
      <li><p>
      If you fail to comply with all of the requirements of Section 512(c)(3) of the DMCA, your DMCA Notice may not be effective.</p></li>
    </ol>

    <ol className={css.listContainer} >
      <li  className={css.listHeading} >
      Counter Notification Procedures
      </li>
      <li><p>If you believe that material you posted on the Queer Healers Service was removed by mistake or misidentification, you may file a counter notification with us (a "Counter Notice") by submitting written notification to our copyright agent designated above. Pursuant to the DMCA, the Counter Notice must include substantially the following:</p>
     </li>
       <li>
      <ul  className={css.subListContainer} >
      

      <ul>
        <li style={{listStyleType:"square"}} >Your physical or electronic signature.


 
</li>
        <li style={{listStyleType:"square"}} >An identification of the material that has been removed or to which access has been disabled and the location at which the material appeared before it was removed or access disabled. 



</li>
        <li style={{listStyleType:"square"}} >Adequate information by which we can contact you (including your name, postal address, telephone number, and, if available, email address).


</li>
        <li style={{listStyleType:"square"}} >A statement under penalty of perjury by you that you have a good faith belief that the material identified above was removed or disabled as a result of a mistake or misidentification of the material to be removed or disabled.


</li>
<li style={{listStyleType:"square"}} >A statement that you will consent to the jurisdiction of the Federal District Court for the judicial district in which your address is located (or if you reside outside the United States for any judicial district in which the Website may be found) and that you will accept service from the person (or an agent of that person) who provided the Website with the complaint at issue.




</li>


        

      </ul>

     

     
    

    
     
      </ul>

      </li>
      <li><p>
      The DMCA allows us to restore the removed content if the party filing the original DMCA Notice does not file a court action against you within ten business days of receiving the copy of your Counter Notice.
</p></li>
    </ol>


    <ol className={css.listContainer} >
      <li  className={css.listHeading} >
      Material Misrepresentations

      </li>

      <li><p>
      Please be aware that if you knowingly materially misrepresent that material or activity on the Service is infringing your copyright, or was removed by mistake or misidentification, you may be liable for damages (including costs and attorneys' fees) under Section 512(f) of the DMCA.

</p></li>
    </ol>

  



    <ol className={css.listContainer} >
      <li  className={css.listHeading} >
      Repeat Infringers
      </li>

      <li><p>
      It is our policy in appropriate circumstances to disable and/or terminate the accounts of users who are repeat infringers.

</p></li>
    </ol>

   

   



    </div>
  );
};

DMCAPrivacyPolicy.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

DMCAPrivacyPolicy.propTypes = {
  rootClassName: string,
  className: string,
};

export default DMCAPrivacyPolicy;
