import React, { useState, useEffect } from 'react';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Field, Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';

import { BsCheck2Circle } from 'react-icons/bs';
import {
  maxLength,
  required,
  composeValidators,
  autocompleteSearchRequired,
  autocompletePlaceSelected,
  maxChars,
  requiredFieldArrayCheckbox,
} from '../../util/validators';
import {
  Form,
  Button,
  FieldTextInput,
  LocationAutocompleteInputField,
  FieldSelect,
  FieldAirbnbDateTimePicker,
  FieldSelectModern,
  FieldRadioButton,
  IconSpinner,
} from '../../components';
import locationCss from '../EditListingLocationForm/EditListingLocationForm.module.css';
import css from './EditListingDescriptionForm.module.css';
import UploadField from './UploadField';
import moment from 'moment';
import { connect, useDispatch } from 'react-redux';
import config from '../../config';
import getCountryCodes from '../../translations/countryCodes';
import { createSlug } from '../../util/urlHelpers';
import { requestUpdateListingTitle } from '../../containers/EditListingPage/EditListingPage.duck';

const DESCRIPTION_MAX_LENGTH = 600;

const identity = v => v;

const EditListingDescriptionFormComponent = props => (
  <FinalForm
    {...props}
    render={formRenderProps => {
      const {
        className,
        disabled,
        ready,
        handleSubmit,
        intl,
        invalid,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
        values,
        onImageUpload,
        // images,
        onRemoveImage,
        onUpdateImageOrder,
        currentListing,
        form,
        page,
        errors,
        uploadingImage,
        updateListingTitleError,
        updateListingTitleInProgress,
        showtickMarkState,
      } = formRenderProps;
      const dispatch = useDispatch();
      const [showTickMark, setShowTickMark] = useState(showtickMarkState);
      useEffect(() => {
        if (updateListingTitleError) {
          setShowTickMark(false);
        }
      }, [updateListingTitleError]);

      const handleSlug = async () => {
        try {
          await dispatch(requestUpdateListingTitle({ listingTitle: values.listingTitle }));
          setShowTickMark(true);
        } catch (e) {
          console.log(e);
        }
      };

      const currentListingImages =
        currentListing && currentListing.images ? currentListing.images : [];

      // Images not yet connected to the listing
      const imageOrder = page.imageOrder || [];
      const unattachedImages = imageOrder.map(i => page.images[i]);

      const allImages = currentListingImages.concat(unattachedImages);
      const removedImageIds = page.removedImageIds || [];
      const images = allImages.filter(img => {
        return !removedImageIds.includes(img.id);
      });

      const maxLengthMessage = intl.formatMessage(
        { id: 'EditListingDescriptionForm.maxLength' },
        {
          maxLength: DESCRIPTION_MAX_LENGTH,
        }
      );

      const descriptionMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.description',
      });
      const descriptionPlaceholderMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.descriptionPlaceholder',
      });
      const maxLength300Message = maxLength(maxLengthMessage, DESCRIPTION_MAX_LENGTH);
      const descriptionRequiredMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.descriptionRequired',
      });
      const locationRequiredMessage = intl.formatMessage({ id: 'EditListingLocationForm.address' });
      const addressPlaceholderMessage = intl.formatMessage({
        id: 'EditListingLocationForm.addressPlaceholder',
      });
      const addressRequiredMessage = intl.formatMessage({
        id: 'EditListingLocationForm.addressRequired',
      });
      const addressNotRecognizedMessage = intl.formatMessage({
        id: 'EditListingLocationForm.addressNotRecognized',
      });

      const { updateListingError, createListingDraftError, showListingsError } = fetchErrors || {};
      const errorMessageUpdateListing = updateListingError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingDescriptionForm.updateFailed" />
        </p>
      ) : null;

      // This error happens only on first tab (of EditListingWizard)
      const errorMessageCreateListingDraft = createListingDraftError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingDescriptionForm.createListingDraftError" />
        </p>
      ) : null;

      const errorMessageShowListing = showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingDescriptionForm.showListingFailed" />
        </p>
      ) : null;

      const classes = classNames(css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const spokenLanguages = values?.languages_spoken ?? [];
      const submitDisabled =
        invalid ||
        disabled ||
        submitInProgress ||
        updateListingTitleError ||
        // (images ?? []).length == 0 ||
        // uploadingImage ||
        !(spokenLanguages.length > 0);

      const countryCodes = getCountryCodes(config.locale).map(country => ({
        key: country.code,
        value: country.code,
        label: country.name,
      }));

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          {errorMessageCreateListingDraft}
          {errorMessageUpdateListing}
          {errorMessageShowListing}

          {/* <div className="flexRowForm">
            <div>
              <FieldTextInput
                id="legal_first_name"
                name="legal_first_name"
                label="Legal First Name"
                placeholder="First"
                inputRootClass="hwInput"
              />
            </div>

            <div>
              <FieldTextInput
                id="legal_middle_name"
                name="legal_middle_name"
                label="Legal Middle Name"
                placeholder="Middle"
                inputRootClass="hwInput"
              />
            </div>

            <div>
              <FieldTextInput
                id="legal_last_name"
                name="legal_last_name"
                label="Legal Last Name"
                placeholder="Last"
                inputRootClass="hwInput"
              />
            </div>
          </div> */}

          <div className="push-top-smedium">
            <label>
              {intl.formatMessage({ id: 'EditListingDescriptionForm.profileFullNameLabel' })}{' '}
              <span className="red">*</span>{' '}
              <span className="grey typeLight">
                {intl.formatMessage({ id: 'EditListingDescriptionForm.profileFullNameSubLabel' })}{' '}
              </span>
            </label>
            <FieldTextInput
              id="display_name"
              name="display_name"
              placeholder={intl.formatMessage({
                id: 'EditListingDescriptionForm.profileFullNamePlaceholder',
              })}
              onBlur={e => {
                handleSlug();
              }}
              inputRootClass="hwInput"
              onFieldChange={e => {
                form.change('listingTitle', createSlug(e.target.value));
              }}
              validate={composeValidators(
                required(
                  intl.formatMessage({ id: 'EditListingDescriptionForm.profileFullNameRequired' })
                ),
                maxChars(
                  intl.formatMessage({
                    id: 'EditListingDescriptionForm.profileFullNameMaxCharacters',
                  }),
                  50
                )
              )}
            />
          </div>

          <div className="push-top-smedium">
            <FieldTextInput
              id="post-nominal-letters"
              name="post_nominal_letters"
              label={intl.formatMessage({
                id: 'EditListingDescriptionForm.postNominalLetterLabel',
              })}
              placeholder={intl.formatMessage({
                id: 'EditListingDescriptionForm.postNominalLettersPlaceholder',
              })}
              inputRootClass="hwInput"
            />
          </div>
          <div className="push-top-smedium">
            <label>
              {intl.formatMessage({
                id: 'EditListingDescriptionForm.EditListingDescriptionForm.slug',
              })}
              <span className="red">*</span>
              <span> {showTickMark && <BsCheck2Circle className={css.tickmark} />}</span>
              {/* <span className="grey typeLight">
                {intl.formatMessage({ id: 'EditListingDescriptionForm.profileFullNameSubLabel' })}{' '}
              </span> */}
            </label>
            <FieldTextInput
              id="listingTitle"
              name="listingTitle"
              placeholder={intl.formatMessage({
                id: 'EditListingDescriptionForm.EditListingDescriptionForm.slugTitle',
              })}
              inputRootClass="hwInput"
              validate={required(
                intl.formatMessage({
                  id: 'EditListingDescriptionForm.EditListingDescriptionForm.slugReq',
                })
              )}
              onBlur={e => {
                handleSlug();
              }}
            />
          </div>
          {updateListingTitleInProgress && (
            <p className={css.info}>
              <span>
                Loading... <IconSpinner />
              </span>
            </p>
          )}
          {updateListingTitleError ? (
            <p className={css.error}>{intl.formatMessage({ id: updateListingTitleError })}</p>
          ) : null}
          <div className="push-top-smedium">
            <label>
              {intl.formatMessage({ id: 'EditListingDescriptionForm.preferredFirstNameLabel' })}{' '}
              <span className="red">*</span>{' '}
              <span className="grey typeLight">
                {intl.formatMessage({ id: 'EditListingDescriptionForm.preferredFirstNameSubText' })}
              </span>
            </label>
            <FieldTextInput
              id="preferred_first_name"
              name="preferred_first_name"
              placeholder={intl.formatMessage({
                id: 'EditListingDescriptionForm.preferredFirstNamePlaceholder',
              })}
              inputRootClass="hwInput"
              validate={required('Preferred First Name is required')}
            />
          </div>
          <div className="push-top-smedium">
            <label>
              {intl.formatMessage({ id: 'EditListingDescriptionForm.businessNameLabel' })}{' '}
              <span className="grey typeLight">
                {intl.formatMessage({ id: 'EditListingDescriptionForm.businessNameSubText' })}
                {/* This is your business name. This field is not required. */}
              </span>
            </label>
            <FieldTextInput
              id="business_name"
              name="business_name"
              inputRootClass="hwInput"
              placeholder={intl.formatMessage({
                id: 'EditListingDescriptionForm.businessNamePlaceholder',
              })}
            />
          </div>

          {/* <div className="push-top-smedium">
            <label className="push-bottom-small">
              Photos <span className="red">*</span>{' '}
              <span className="grey typeLight">Add up to 10 photos</span>
            </label>

            <UploadField
              name="photos"
              id="photos"
              currentListing={currentListing}
              onImageUpload={onImageUpload}
              // images={images}
              onRemoveImage={onRemoveImage}
              onUpdateImageOrder={onUpdateImageOrder}
              intl={intl}
              form={form}
              values={values}
            />
          </div> */}
          {/* <div className="push-top-smedium">
            <label className="push-bottom-small">
              Pronouns <span className="red">*</span>{' '}
            </label>
            <FieldSelect
              id="pronouns"
              name="pronouns"
              selectClassName="hwInput"
              validate={required('This field is required.')}
            >
              {config.custom.pronouns.map(c => (
                <option key={c.key} value={c.key}>
                  {c.label}
                </option>
              ))}
                          </FieldSelect>
          </div>

             */}

          <div className="push-top-smedium">
            <label className="push-bottom-small">
              <FieldRadioButton
                id="display_my_pronouns"
                label={intl.formatMessage({
                  id: 'EditListingDescriptionForm.displayMyPronounsRadioButtonLabel',
                })}
                name="pronouns_option"
                labelAsHeading
                value={'display_my_pronouns'}
                validate={required(
                  intl.formatMessage({
                    id: 'EditListingDescriptionForm.displayMyPronounsRadioButtonRequiredMessage',
                  })
                )}
              />
            </label>
            {values?.pronouns_option == 'display_my_pronouns' && (
              <FieldSelectModern
                // className="hwInput"
                id="pronouns"
                name="pronouns"
                // inputRootClass="hwInput"
                placeholder={intl.formatMessage({
                  id: 'EditListingDescriptionForm.pronounsSelectorPlaceholder',
                })}
                options={config.custom.pronouns}
                isMulti={true}
                isSearchable={true}
                // validate={requiredFieldArrayCheckbox('This field is required.')}
                disabled={values.pronouns_option !== 'display_my_pronouns'}
              />
            )}
          </div>
          <div>
            <label className="push-bottom-small">
              <FieldRadioButton
                id="pronouns_other"
                label={intl.formatMessage({ id: 'EditListingDescriptionForm.pronounsOtherLabel' })}
                labelAsHeading
                name="pronouns_option"
                value={'pronouns_other'}
                validate={required(
                  intl.formatMessage({
                    id: 'EditListingDescriptionForm.pronounsOtherRequiredMessage',
                  })
                )}
              />
            </label>
            {values?.pronouns_option == 'pronouns_other' && (
              <fieldset
                className={css.fieldset}
                disabled={values.pronouns_option !== 'pronouns_other'}
              >
                {config.custom.otherPronouns.map(p => (
                  <FieldRadioButton
                    id={`other_pronouns.${p.key}`}
                    name="other_pronouns"
                    value={p.key}
                    label={p.label}
                    // validate={required('This field is required.')}
                  />
                ))}
              </fieldset>
            )}
          </div>

          <div className="push-top-smedium">
            <label>
              {intl.formatMessage({ id: 'EditListingDescriptionForm.dobLabel' })}{' '}
              <span className="red">*</span>{' '}
              <span className="grey typeLight">
                {intl.formatMessage({ id: 'EditListingDescriptionForm.dobSubText' })}
              </span>
            </label>
            {/* <FieldTextInput
              id="date_of_birth"
              name="date_of_birth"
              inputRootClass="hwInput"
              type="date"
              placeholder="What's your date of birth?"
              validate={required('Date of Birth is required')}
              max={moment(new Date())
                .subtract(18, 'years')
                .toISOString()}
            /> */}
            <FieldAirbnbDateTimePicker
              id="date_of_birth"
              name="date_of_birth"
              inputRootClass="hwInput"
              // label={playerDOBLabel}
              className={css.field}
              maxDate={moment(new Date())
                .subtract(18, 'years')
                .toISOString()}
              placeholder={intl.formatMessage({ id: 'EditListingDescriptionForm.dobPlaceholder' })}
              validate={required(
                intl.formatMessage({ id: 'EditListingDescriptionForm.dobRequiredMessage' })
              )}
            />
          </div>
          <div className="push-top-smedium">
            <label>
              {intl.formatMessage({ id: 'EditListingDescriptionForm.mapLocationLabel' })}{' '}
              <span className="red">*</span>{' '}
              <span className="grey typeLight">
                {intl.formatMessage({ id: 'EditListingDescriptionForm.mapLocationSubText' })}
                <p className={css.subText}>
                  {intl.formatMessage({ id: 'EditListingDescriptionForm.mapLocationSubText2' })}
                </p>
                <p className={css.subText}>
                  {intl.formatMessage({ id: 'EditListingDescriptionForm.mapLocationSubText3' })}
                </p>
              </span>
            </label>

            <LocationAutocompleteInputField
              className={locationCss.locationAddress}
              inputClassName="hwInput h-36"
              // inputClassName={locationCss.locationAutocompleteInput}
              iconClassName={locationCss.locationAutocompleteInputIcon}
              predictionsClassName={locationCss.predictionsRoot}
              validClassName={locationCss.validLocation}
              name="location"
              // label={locationRequiredMessage}
              placeholder={addressPlaceholderMessage}
              useDefaultPredictions={false}
              format={identity}
              valueFromForm={values.location}
              validate={composeValidators(
                autocompleteSearchRequired(addressRequiredMessage),
                autocompletePlaceSelected(addressNotRecognizedMessage)
              )}
            />
          </div>
          <div className="push-top-smedium">
            <label>
              {intl.formatMessage({ id: 'EditListingDescriptionForm.languagesSpokenLabel' })}{' '}
              <span className="red">*</span>{' '}
              <span className="grey typeLight">
                {intl.formatMessage({ id: 'EditListingDescriptionForm.languagesSpokenSubText' })}
              </span>
            </label>
            {/* <FieldTextInput
              className="hwInput"
              id="languages_spoken"
              name="languages_spoken"
              inputRootClass="hwInput"
              placeholder="Separate each language with a comma"
              validate={required('Languages Spoken is required')}
            /> */}
            <FieldSelectModern
              // className="hwInput"
              id="languages_spoken"
              name="languages_spoken"
              // inputRootClass="hwInput"
              placeholder={intl.formatMessage({
                id: 'EditListingDescriptionForm.languagesSpokenPlaceholder',
              })}
              validate={requiredFieldArrayCheckbox(
                intl.formatMessage({
                  id: 'EditListingDescriptionForm.langaugesSpokenRequiredMessage',
                })
              )}
              options={config.custom.languages}
              isMulti={true}
              isSearchable={true}
            />
          </div>

          <div className="push-top-smedium">
            <label>
              {intl.formatMessage({ id: 'EditListingDescriptionForm.shortBioLabel' })}{' '}
              <span className="red">*</span>
            </label>
            <FieldTextInput
              className="hwInput"
              inputRootClass="hwInput"
              id="description"
              name="description"
              type="textarea"
              placeholder={intl.formatMessage({
                id: 'EditListingDescriptionForm.shortBioPlaceholder',
              })}
              validate={composeValidators(
                required(descriptionRequiredMessage),
                maxLength300Message
              )}
            />
          </div>
          <div className="push-top-smedium">
            <label>
              {intl.formatMessage({ id: 'EditListingDescriptionForm.accrediationsLabel' })}
            </label>
            <FieldTextInput
              className="hwInput"
              inputRootClass="hwInput"
              type="textarea"
              id="accrediations"
              name="accrediations"
              placeholder={intl.formatMessage({
                id: 'EditListingDescriptionForm.accrediationsPlaceholder',
              })}
              validate={composeValidators(
                // required('Accrediations are required'),
                maxChars(
                  intl.formatMessage({
                    id: 'EditListingDescriptionForm.accrediationsMaxCharsMessage',
                  }),
                  1000
                )
              )}
            />
          </div>

          <div className="push-top-smedium">
            <label>
              {intl.formatMessage({ id: 'EditListingDescriptionForm.countryOfOriginLabel' })}{' '}
              <span className="red">*</span>{' '}
              <span className="grey typeLight">
                {intl.formatMessage({ id: 'EditListingDescriptionForm.countryOfOriginSubText' })}
              </span>
            </label>

            <FieldSelectModern
              id="country_of_origin"
              name="country_of_origin"
              placeholder={intl.formatMessage({
                id: 'EditListingDescriptionForm.countryOfOriginPlaceholder',
              })}
              validate={requiredFieldArrayCheckbox(
                intl.formatMessage({
                  id: 'EditListingDescriptionForm.countryOfOriginRequiredMessage',
                })
              )}
              options={countryCodes}
              isSearchable={true}
            />
          </div>

          <div className="push-top-smedium ">
            <label>
              {intl.formatMessage({ id: 'EditListingDescriptionForm.testimonial1Label' })}{' '}
              <span className="red">*</span>{' '}
              <span className="grey typeLight">
                {intl.formatMessage({ id: 'EditListingDescriptionForm.testimonial1SubText' })}
              </span>
            </label>
            <FieldTextInput
              className="hwInput"
              inputRootClass="hwInput"
              type="textarea"
              id="past_customer_review_1"
              name="past_customer_review_1"
              placeholder={intl.formatMessage({
                id: 'EditListingDescriptionForm.testimonial1Placeholder',
              })}
              validate={composeValidators(
                required(
                  intl.formatMessage({ id: 'EditListingDescriptionForm.testimonial1Required' })
                ),
                maxChars(
                  intl.formatMessage({ id: 'EditListingDescriptionForm.testimonial1MaxChars' }),
                  1000
                )
              )}
            />
          </div>
          <div className="push-top-smedium push-bottom-large">
            <label>
              {intl.formatMessage({ id: 'EditListingDescriptionForm.testimonial2Label' })}{' '}
              <span className="red">*</span>{' '}
              <span className="grey typeLight">
                {intl.formatMessage({ id: 'EditListingDescriptionForm.testimonial2SubText' })}
              </span>
            </label>
            <FieldTextInput
              className="hwInput"
              inputRootClass="hwInput"
              type="textarea"
              id="past_customer_review_2"
              name="past_customer_review_2"
              placeholder={intl.formatMessage({
                id: 'EditListingDescriptionForm.testimonial2Placeholder',
              })}
              validate={composeValidators(
                required(
                  intl.formatMessage({
                    id: 'EditListingDescriptionForm.testimonial2RequiredMessage',
                  })
                ),
                maxChars(
                  intl.formatMessage({
                    id: 'EditListingDescriptionForm.testimonial2MaxCharsMessage',
                  }),
                  1000
                )
              )}
            />
          </div>

          {/* <FieldTextInput
              id="description"
              name="description"
              className={css.description}
              type="textarea"
              label={descriptionMessage}
              placeholder={descriptionPlaceholderMessage}
              validate={composeValidators(required(descriptionRequiredMessage))}
            /> */}

          {/* <CustomCertificateSelectFieldMaybe
            id="certificate"
            name="certificate"
            certificateOptions={certificateOptions}
            intl={intl}
          /> */}

          <Button
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
            ready={submitReady}
          >
            {saveActionMsg}
          </Button>
        </Form>
      );
    }}
  />
);

EditListingDescriptionFormComponent.defaultProps = { className: null, fetchErrors: null };

EditListingDescriptionFormComponent.propTypes = {
  className: string,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    createListingDraftError: propTypes.error,
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  // REMOVING CERTIFICATE FOR NOW
  // certificateOptions: arrayOf(
  //   shape({
  //     key: string.isRequired,
  //     label: string.isRequired,
  //   })
  // ),
};

const mapStateToProps = state => {
  return {
    page: state.EditListingPage,
    uploadingImage: state.EditListingPage.uploadingImage,
  };
};

export default compose(injectIntl, connect(mapStateToProps))(EditListingDescriptionFormComponent);
