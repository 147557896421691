import React from 'react';
import { string } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { NamedLink } from '../../components';

import css from './SectionHero.module.css';

const SectionHero = props => {
  const { rootClassName, className } = props;

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <div className={css.heroContent}>
        <h1 className={css.heroMainTitle}>
          <FormattedMessage id="SectionHero.title" />
        </h1>
        <h2 className={css.heroSubTitle}>
          <FormattedMessage id="SectionHero.subTitle" />
        </h2>
        <div className={css.btnGroup}>
            <div className="butSection">
              <NamedLink
                name="SearchPage"
                // to={{
                //   search:
                //     'address=United%20States%20of%20America&bounds=71.540724%2C-66.885444%2C18.765563%2C-179.9',
                // }}
                className={css.heroButton}
              >
                <FormattedMessage id="SectionHero.browseButton" />
              </NamedLink>
              {/* <NamedLink
                name="SignupPage"
                to={{
                  search:
                    'address=United%20States%20of%20America&bounds=71.540724%2C-66.885444%2C18.765563%2C-179.9',
                }}
                className={css.subHeroButton}
              >
                <FormattedMessage id="SectionHero.secondaryBrowseButton" />
              </NamedLink> */}
            </div>
      </div>
    </div>
    </div>
  );
};

SectionHero.defaultProps = { rootClassName: null, className: null };

SectionHero.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionHero;
