import React from 'react';
import { bool, func, shape, string } from 'prop-types';
import classNames from 'classnames';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { FormattedMessage } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import config from '../../config';
import { Button, Form } from '../../components';

import css from './EditListingFeaturedIdentitiesForm.module.css';
import CheckboxGroup from '../../components/CustomGroupCheckbox/CustomGroupCheckbox';

const EditListingFeaturedIdentitiesFormComponent = props => (
  <FinalForm
    {...props}
    mutators={{ ...arrayMutators }}
    render={formRenderProps => {
      const {
        disabled,
        ready,
        rootClassName,
        className,
        name,
        handleSubmit,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
        filterConfig,
        form,
        values,
        initialValues,
        backgroundIdentities,
        disabilityIdentities,
        sexualityIdentities,
        spiritualityIdentities,
        featuredIdentities,
        genderIdentities,
        miscIdentities,
      } = formRenderProps;

      // const [selected, setSelected] = React.useState(featuredIdentities ?? []);

      const classes = classNames(rootClassName || css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;

      const { updateListingError, showListingsError } = fetchErrors || {};
      const errorMessage = updateListingError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingFeaturesForm.updateFailed" />
        </p>
      ) : null;

      const errorMessageShowListing = showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingFeaturesForm.showListingFailed" />
        </p>
      ) : null;

      const setSelected = value => form.change('featuredIdentities', value);
      const selected = values?.featuredIdentities ?? featuredIdentities ?? [];

      const allThreeSelected = selected.length == 3;

      const submitDisabled = disabled || submitInProgress || !allThreeSelected;

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          {errorMessage}
          {errorMessageShowListing}

          {/* Background */}
          <div className="push-top-smedium">
            <label className="push-bottom-small">
              Background -{' '}
              <span className="grey typeLight">
                Select options applicable to your lived-experience
              </span>
            </label>

            <div className="highlighted-top">
              <div className="tags flex">
                <CheckboxGroup name="Background" value={selected} onChange={setSelected}>
                  {Checkbox => (
                    <>
                      {backgroundIdentities.map(v => (
                        <label
                          data-disabled={
                            selected.includes(v.key) ? 'false' : allThreeSelected ? 'true' : 'false'
                          }
                          className={` ${
                            selected.includes(v.key)
                              ? 'selected btn greyOutlineRounded'
                              : 'btn greyOutlineRounded'
                          }`}
                        >
                          <div style={{ display: 'none' }}>
                            <Checkbox
                              disabled={selected.includes(v.key) ? false : allThreeSelected}
                              value={v.key}
                            />
                          </div>
                          {v.label}
                        </label>
                      ))}
                    </>
                  )}
                </CheckboxGroup>
              </div>
            </div>
          </div>
          {/* Disability */}
          <div className="push-top-smedium">
            <label className="push-bottom-small">
              Disability -{' '}
              <span className="grey typeLight">
                Select options applicable to your lived-experience
              </span>
            </label>

            <div className="highlighted-top">
              <div className="tags flex">
                <CheckboxGroup name="Disability" value={selected} onChange={setSelected}>
                  {Checkbox => (
                    <>
                      {disabilityIdentities.map(v => (
                        <label
                          data-disabled={
                            selected.includes(v.key) ? 'false' : allThreeSelected ? 'true' : 'false'
                          }
                          className={` ${
                            selected.includes(v.key)
                              ? 'selected btn greyOutlineRounded'
                              : 'btn greyOutlineRounded'
                          }`}
                        >
                          <div style={{ display: 'none' }}>
                            <Checkbox
                              disabled={selected.includes(v.key) ? false : allThreeSelected}
                              value={v.key}
                            />
                          </div>
                          {v.label}
                        </label>
                      ))}
                    </>
                  )}
                </CheckboxGroup>
              </div>
            </div>
          </div>
          {/* Gender */}
          <div className="push-top-smedium">
            <label className="push-bottom-small">
              Gender -{' '}
              <span className="grey typeLight">
                Select options applicable to your lived-experience
              </span>
            </label>

            <div className="highlighted-top">
              <div className="tags flex">
                <CheckboxGroup name="Gender" value={selected} onChange={setSelected}>
                  {Checkbox => (
                    <>
                      {genderIdentities.map(v => (
                        <label
                          data-disabled={
                            selected.includes(v.key) ? 'false' : allThreeSelected ? 'true' : 'false'
                          }
                          className={` ${
                            selected.includes(v.key)
                              ? 'selected btn greyOutlineRounded'
                              : 'btn greyOutlineRounded'
                          }`}
                        >
                          <div style={{ display: 'none' }}>
                            <Checkbox
                              disabled={selected.includes(v.key) ? false : allThreeSelected}
                              value={v.key}
                            />
                          </div>
                          {v.label}
                        </label>
                      ))}
                    </>
                  )}
                </CheckboxGroup>
              </div>
            </div>
          </div>
          {/* Sexuality */}
          <div className="push-top-smedium">
            <label className="push-bottom-small">
              Sexuality -{' '}
              <span className="grey typeLight">
                Select options applicable to your lived-experience
              </span>
            </label>

            <div className="highlighted-top">
              <div className="tags flex">
                <CheckboxGroup name="Sexuality" value={selected} onChange={setSelected}>
                  {Checkbox => (
                    <>
                      {sexualityIdentities.map(v => (
                        <label
                          data-disabled={
                            selected.includes(v.key) ? 'false' : allThreeSelected ? 'true' : 'false'
                          }
                          className={` ${
                            selected.includes(v.key)
                              ? 'selected btn greyOutlineRounded'
                              : 'btn greyOutlineRounded'
                          }`}
                        >
                          <div style={{ display: 'none' }}>
                            <Checkbox
                              disabled={selected.includes(v.key) ? false : allThreeSelected}
                              value={v.key}
                            />
                          </div>
                          {v.label}
                        </label>
                      ))}
                    </>
                  )}
                </CheckboxGroup>
              </div>
            </div>
          </div>
          {/* Spirituality */}
          <div className="push-top-smedium">
            <label className="push-bottom-small">
              Spirituality -{' '}
              <span className="grey typeLight">
                Select options applicable to your lived-experience
              </span>
            </label>

            <div className="highlighted-top">
              <div className="tags flex">
                <CheckboxGroup name="Spirituality" value={selected} onChange={setSelected}>
                  {Checkbox => (
                    <>
                      {spiritualityIdentities.map(v => (
                        <label
                          data-disabled={
                            selected.includes(v.key) ? 'false' : allThreeSelected ? 'true' : 'false'
                          }
                          className={` ${
                            selected.includes(v.key)
                              ? 'selected btn greyOutlineRounded'
                              : 'btn greyOutlineRounded'
                          }`}
                        >
                          <div style={{ display: 'none' }}>
                            <Checkbox
                              disabled={selected.includes(v.key) ? false : allThreeSelected}
                              value={v.key}
                            />
                          </div>
                          {v.label}
                        </label>
                      ))}
                    </>
                  )}
                </CheckboxGroup>
              </div>
            </div>
          </div>

          {/* Misc identities */}
          <div className="push-top-smedium">
            <label className="push-bottom-small">
              Other -{' '}
              <span className="grey typeLight">
                Select options applicable to your lived-experience
              </span>
            </label>

            <div className="highlighted-top">
              <div className="tags flex">
                <CheckboxGroup name="Misc" value={selected} onChange={setSelected}>
                  {Checkbox => (
                    <>
                      {miscIdentities.map(v => (
                        <label
                          data-disabled={
                            selected.includes(v.key) ? 'false' : allThreeSelected ? 'true' : 'false'
                          }
                          className={` ${
                            selected.includes(v.key)
                              ? 'selected btn greyOutlineRounded'
                              : 'btn greyOutlineRounded'
                          }`}
                        >
                          <div style={{ display: 'none' }}>
                            <Checkbox
                              disabled={selected.includes(v.key) ? false : allThreeSelected}
                              value={v.key}
                            />
                          </div>
                          {v.label}
                        </label>
                      ))}
                    </>
                  )}
                </CheckboxGroup>
              </div>
            </div>
          </div>
          <Button
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
            ready={submitReady}
          >
            {saveActionMsg}
          </Button>
        </Form>
      );

      // return (
      //   <Form className={classes} onSubmit={handleSubmit}>
      //     {errorMessage}
      //     {errorMessageShowListing}
      //     <div className="push-top-smedium">
      //       <label className="push-bottom-small">
      //         Background -{' '}
      //         <span className="grey typeLight">
      //           Select options applicable to your lived-experience
      //         </span>
      //       </label>
      //       <div className="highlighted-top">
      //         <div
      //           className="tags flex"
      //           onClick={e => {
      //             const name = e.target.dataset.name;
      //             if (typeof name == 'undefined') return;
      //             handleClick(e.target.dataset.name, 'featured_background_identities');
      //           }}
      //         >
      //           {config.custom.backgroundIdentities
      //             .filter(v => initialValues?.['background_identities'].includes(v.key))
      //             .map(l => (
      //               <li
      //                 style={{
      //                   pointerEvents: allIdentities?.includes(l.key)
      //                     ? 'auto'
      //                     : pointerEventsNoneMaybe,
      //                 }}
      //                 key={l.key}
      //                 data-name={l.key}
      //                 className={
      //                   values?.['featured_background_identities']?.includes(l.key)
      //                     ? 'selected btn greyOutlineRounded'
      //                     : ' btn greyOutlineRounded'
      //                 }
      //               >
      //                 {l.label}
      //               </li>
      //             ))}
      //         </div>
      //       </div>
      //     </div>
      //     <div className="push-top-smedium">
      //       <label className="push-bottom-small">
      //         Disability -{' '}
      //         <span className="grey typeLight">
      //           Select options applicable to your lived-experience
      //         </span>
      //       </label>
      //       <div className="highlighted-top">
      //         <div
      //           className="tags flex"
      //           onClick={e => {
      //             const name = e.target.dataset.name;
      //             if (typeof name == 'undefined') return;
      //             handleClick(e.target.dataset.name, 'featured_disability_identities');
      //           }}
      //         >
      //           {config.custom.disabilitiesIdentity
      //             .filter(v => initialValues?.['disability_identities'].includes(v.key))
      //             .map(l => (
      //               <li
      //                 style={{
      //                   pointerEvents: allIdentities?.includes(l.key)
      //                     ? 'auto'
      //                     : pointerEventsNoneMaybe,
      //                 }}
      //                 key={l.key}
      //                 data-name={l.key}
      //                 className={
      //                   values?.['featured_disability_identities']?.includes(l.key)
      //                     ? 'selected btn greyOutlineRounded'
      //                     : ' btn greyOutlineRounded'
      //                 }
      //               >
      //                 {l.label}
      //               </li>
      //             ))}
      //         </div>
      //       </div>
      //     </div>
      //     <div className="push-top-smedium">
      //       <label className="push-bottom-small">
      //         Sexuality -{' '}
      //         <span className="grey typeLight">
      //           Select options applicable to your lived-experience
      //         </span>
      //       </label>
      //       <div className="highlighted-top">
      //         <div
      //           className="tags flex"
      //           onClick={e => {
      //             const name = e.target.dataset.name;
      //             if (typeof name == 'undefined') return;
      //             handleClick(e.target.dataset.name, 'featured_sexuality_identities');
      //           }}
      //         >
      //           {config.custom.sexualityIdentities
      //             .filter(v => initialValues?.['sexuality_identities'].includes(v.key))
      //             .map(l => (
      //               <li
      //                 style={{
      //                   pointerEvents: allIdentities?.includes(l.key)
      //                     ? 'auto'
      //                     : pointerEventsNoneMaybe,
      //                 }}
      //                 key={l.key}
      //                 data-name={l.key}
      //                 className={
      //                   values?.['featured_sexuality_identities']?.includes(l.key)
      //                     ? 'selected btn greyOutlineRounded'
      //                     : ' btn greyOutlineRounded'
      //                 }
      //               >
      //                 {l.label}
      //               </li>
      //             ))}
      //         </div>
      //       </div>
      //     </div>
      //     <div className="push-top-smedium">
      //       <label className="push-bottom-small">
      //         Spirituality -{' '}
      //         <span className="grey typeLight">
      //           Select options applicable to your lived-experience
      //         </span>
      //       </label>
      //       <div className="highlighted-top">
      //         <div
      //           className="tags flex"
      //           onClick={e => {
      //             const name = e.target.dataset.name;
      //             if (typeof name == 'undefined') return;
      //             handleClick(e.target.dataset.name, 'featured_spirituality_identities');
      //           }}
      //         >
      //           {config.custom.spiritualityIdentities
      //             .filter(v => initialValues?.['spirituality_identities'].includes(v.key))
      //             .map(l => (
      //               <li
      //                 style={{
      //                   pointerEvents: allIdentities?.includes(l.key)
      //                     ? 'auto'
      //                     : pointerEventsNoneMaybe,
      //                 }}
      //                 key={l.key}
      //                 data-name={l.key}
      //                 className={
      //                   values?.['featured_spirituality_identities']?.includes(l.key)
      //                     ? 'selected btn greyOutlineRounded'
      //                     : ' btn greyOutlineRounded'
      //                 }
      //               >
      //                 {l.label}
      //               </li>
      //             ))}
      //         </div>
      //       </div>
      //     </div>

      //     <Button
      //       className={css.submitButton}
      //       type="submit"
      //       inProgress={submitInProgress}
      //       disabled={submitDisabled}
      //       ready={submitReady}
      //     >
      //       {saveActionMsg}
      //     </Button>
      //   </Form>
      // );
    }}
  />
);

EditListingFeaturedIdentitiesFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  fetchErrors: null,
  filterConfig: config.custom.filters,
};

EditListingFeaturedIdentitiesFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  name: string.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  filterConfig: propTypes.filterConfig,
};

const EditListingFeaturedIdentities = EditListingFeaturedIdentitiesFormComponent;

export default EditListingFeaturedIdentities;
