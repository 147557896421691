import React, { useEffect, useRef, useState } from 'react';
import UploadFile from './UploadFile';
import css from '../EditListingPhotosForm/EditListingPhotosForm.module.css';
import { AddImages, ValidationError } from '../../components';
import { isUploadImageOverLimitError } from '../../util/errors';
import { Field } from 'react-final-form';
import { connect } from 'react-redux';
import { isEqual } from 'lodash';

const ACCEPT_IMAGES = 'image/*';

function UploadField(props) {
  const {
    // images = [],
    onImageUpload,
    onRemoveImage,
    onUpdateImageOrder,
    intl,
    uploadImageError,
    name,
    id,
    currentListing,
    page,
    form,
    values,
  } = props;
  const [imageUploadRequested, setImageUploadRequested] = useState(false);
  const submittedImagesRef = useRef([]);

  const currentListingImages = currentListing && currentListing.images ? currentListing.images : [];

  // Images not yet connected to the listing
  const imageOrder = page.imageOrder || [];
  const unattachedImages = imageOrder.map(i => page.images[i]);

  const allImages = currentListingImages.concat(unattachedImages);
  const removedImageIds = page.removedImageIds || [];
  const images = allImages.filter(img => {
    return !removedImageIds.includes(img.id);
  });

  const onImageUploadHandler = file => {
    if (file) {
      setImageUploadRequested(true);
      onImageUpload({ id: `${file.name}_${Date.now()}`, file })
        .then(() => {
          setImageUploadRequested(false);
        })
        .catch(() => {
          setImageUploadRequested(false);
        });
    }
  };

  const uploadOverLimit = isUploadImageOverLimitError(uploadImageError);
  let uploadImageFailed = null;

  if (uploadOverLimit) {
    uploadImageFailed = (
      <p className={css.error}>
        <FormattedMessage id="EditListingPhotosForm.imageUploadFailed.uploadOverLimit" />
      </p>
    );
  } else if (uploadImageError) {
    uploadImageFailed = (
      <p className={css.error}>
        <FormattedMessage id="EditListingPhotosForm.imageUploadFailed.uploadFailed" />
      </p>
    );
  }
  const arrayOfImgIds = imgs => imgs.map(i => (typeof i.id === 'string' ? i.imageId : i.id));
  const imageIdsFromProps = arrayOfImgIds(images).filter(v => typeof v != 'undefined');

  useEffect(() => {
    if (imageIdsFromProps.length > 0) {
      const equal = isEqual(imageIdsFromProps, values['listing-photos']);
      if (!equal) form.change('listing-photos', imageIdsFromProps);
    }
  }, [imageIdsFromProps]);

  const showUploadButton = (imageIdsFromProps ?? [])?.length >= 10;

  return (
    <div>
      {!showUploadButton ? (
        <UploadFile
          id={id}
          name={name}
          onImageUploadHandler={onImageUploadHandler}
          disabled={imageUploadRequested}
        />
      ) : null}
      {images.length > 0 && (
        <div style={{ marginTop: '1rem' }}>
          <AddImages
            className={css.imagesField}
            images={images}
            thumbnailClassName={css.thumbnail}
            savedImageAltText={intl.formatMessage({
              id: 'EditListingPhotosForm.savedImageAltText',
            })}
            onRemoveImage={image => {
              const leftImages = images.filter(f => f.id != image);
              let imageIds = arrayOfImgIds(leftImages).filter(v => v => typeof v != 'undefined');
              if (image.uuid) {
                imageIds.filter(f => image.uuid != f.uuid);
              }

              // const leftOutImages = imageIdsFromProps.filter(f => image.uuid != f.uuid);
              // form.change('listing-photos', leftOutImages);
              form.change('listing-photos', imageIds);
              onRemoveImage(image);
            }}
          >
            {/* <Field
          id="addImage"
          name="addImage"
          accept={ACCEPT_IMAGES}
          form={null}
          label={'Upload'}
          type="file"
          disabled={imageUploadRequested}
        >
          {fieldprops => {
            const { accept, input, label, disabled: fieldDisabled } = fieldprops;
            const { name, type } = input;
            const onChange = e => {
              const file = e.target.files[0];
              form.change(`addImage`, file);
              form.blur(`addImage`);
              onImageUploadHandler(file);
            };
            const inputProps = { accept, id: name, name, onChange, type };
            return (
              <div className={css.addImageWrapper}>
                <div className={css.aspectRatioWrapper}>
                  {fieldDisabled ? null : <input {...inputProps} className={css.addImageInput} />}
                  <label htmlFor={name} className={css.addImage}>
                    {label}
                  </label>
                </div>
              </div>
            );
          }}
        </Field> */}

            <Field
              component={props => {
                const { input, meta } = props;
                return (
                  <div className={css.imageRequiredWrapper}>
                    <input {...input} />
                    <ValidationError fieldMeta={meta} />
                  </div>
                );
              }}
              name="images"
              type="hidden"
              // validate={composeValidators(nonEmptyArray(imageRequiredMessage))}
            />
          </AddImages>
        </div>
      )}
      {uploadImageFailed}
    </div>
  );
}

const mapStateToProps = state => {
  const page = state.EditListingPage;
  return {
    page,
  };
};

export default connect(
  mapStateToProps,
  null
)(UploadField);
