import React, { useEffect, useState } from 'react';
import {
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
  useElements,
  useStripe,
  Elements,
} from '@stripe/react-stripe-js';
import './custom.css';
import axios from 'axios';
import {
  Footer,
  LayoutSingleColumn,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  NamedRedirect,
  Page,
} from '../../components';
import { useDispatch, useSelector } from 'react-redux';
import { loadStripe } from '@stripe/stripe-js';
import css from './MembershipPage.module.css';

import TopbarContainer from '../TopbarContainer/TopbarContainer';
import { withRouter } from 'react-router-dom';
import Loading from './Loading';
import { ensureCurrentUser } from '../../util/data';
import { currentUserHasMembership } from './utils';
import { FormattedMessage, useIntl } from '../../util/reactIntl';
import { fetchCurrentUserSimple } from '../../ducks/user.duck';
import { updateMembershipDetails } from '../../ducks/stripe.duck';
import { handleFreeState } from '../EditListingPage/EditListingPage.duck';

const ELEMENT_OPTIONS = {
  style: {
    base: {
      fontSize: '18px',
      color: '#424770',
      letterSpacing: '0.025em',
      '::placeholder': {
        color: '#aab7c4',
      },
    },
    invalid: {
      color: '#9e2146',
    },
  },
};
const MembershipPaymentPageComponent = ({
  dispatch,
  successParentSet,
  setSubscriptionStripeFormOpen,
  setOwnBookingPlatform,
  isAccountSettingsPage = false,
  availabilityPlan,
  handleListingSubmit,
  ownBookingPlatform,
  isFree = false,
  setUrlMain,
  listingId,
}) => {
  const stripePage = useSelector(state => state.stripe);
  // console.log('stripePage', stripePage);
  const { updated, updateMembershipDetailsInProgress: loading } = stripePage;
  const currentUser = useSelector(state => state.user.currentUser);
  const ensuredUser = ensureCurrentUser(currentUser);
  const userLoading = useSelector(state => state.user.currentUserLoading);
  const intl = useIntl();
  const fm = ({ id, values }) =>
    values ? intl.formatMessage({ id }, values) : intl.formatMessage({ id });

  const elements = useElements();
  const stripe = useStripe();
  const [postal, setPostal] = useState('');
  const [errorMessage, setErrorMessage] = useState(null);
  const [submitting, setSubmitting] = useState(null);
  const [success, setSuccess] = useState(null);
  const [cardError, setCardError] = useState(null);
  const [expirationError, setExpirationError] = useState(null);
  const [cvcError, setCvcError] = useState(null);
  const [cardDisabled, setCardDisabled] = useState(true);
  const [expirationDisabled, setExpirationDisabled] = useState(true);
  const [cvcDisabled, setCvcDisabled] = useState(true);
  const [url, setUrl] = useState('');

  if (success) {
    return null;
  }

  if (userLoading) {
    return <Loading />;
  }

  const fullName =
    currentUser?.attributes?.profile?.firstName + ' ' + currentUser?.attributes?.profile?.lastName;

  const handleChange = (elementType, evt) => {
    switch (elementType) {
      case 'card':
        {
          if (evt.complete) {
            setCardError(null);
            setCardDisabled(false);
          } else if (evt.error) {
            setCardError(evt.error.message);
            setCardDisabled(true);
          } else if (!evt.complete) setCardDisabled(true);
          else setCardDisabled(false);
        }
        break;
      case 'expiration':
        {
          if (evt.complete) {
            setExpirationError(null);
            setExpirationDisabled(false);
          } else if (evt.error) {
            setExpirationDisabled(true);
            setExpirationError(evt.error.message);
          } else if (!evt.complete) setExpirationDisabled(true);
          else setExpirationDisabled(false);
        }
        break;
      case 'cvc':
        {
          if (evt.complete) {
            setCvcError(null);
            setCvcDisabled(false);
          } else if (evt.error) {
            setCvcDisabled(true);
            setCvcError(evt.error.message);
          } else if (!evt.complete) setCvcDisabled(true);
          else setCvcDisabled(false);
        }
        break;
    }
  };

  const submitDisabled =
    !stripe ||
    !postal ||
    submitting ||
    cvcDisabled ||
    expirationDisabled ||
    cardDisabled ||
    updated;
  const handleUpdate = async event => {
    event.preventDefault();
    const fnCreatePaymentMethod = async fnParams => {
      const cardElement = elements.getElement(CardNumberElement);
      // console.log(cardElement);
      return new Promise(async (resolve, reject) => {
        const payload = await stripe.createPaymentMethod({
          type: 'card',
          card: cardElement,
          billing_details: {
            name: fullName,
            address: {
              postal_code: postal,
            },
          },
        });
        if (payload.error) {
          message = payload.error.message;
          return reject(payload.error);
        }
        return resolve({ payload });
      });
    };
    const payLoad = await fnCreatePaymentMethod();
    // console.log('payLoad', payLoad);
    const id = payLoad?.payload?.paymentMethod?.id;
    // console.log(id);
    // console.log(data);
    const res = await dispatch(updateMembershipDetails(id));

    // handleUpdateState();
    // setLoading(false);
  };
  const handleSubmit = async event => {
    event.preventDefault();

    setSubmitting(true);
    setErrorMessage(null);

    if (submitDisabled) return;

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      setSubmitting(false);
      return;
    }

    let message = '';

    // step 1: create payment method;
    const fnCreatePaymentMethod = async fnParams => {
      const cardElement = elements.getElement(CardNumberElement);
      return new Promise(async (resolve, reject) => {
        const payload = await stripe.createPaymentMethod({
          type: 'card',
          card: cardElement,
          billing_details: {
            name: fullName,
            address: {
              postal_code: postal,
            },
          },
        });
        if (payload.error) {
          message = payload.error.message;
          return reject(payload.error);
        }
        return resolve({ payload });
      });
    };

    // step 2: subscribe;
    const fnSubscribe = async fnParams => {
      const { payload } = fnParams;
      return new Promise(async (resolve, reject) => {
        const { id } = payload.paymentMethod;
        try {
          const response = await axios.post('/confirm-stripe-payment', {
            id,
            postalcode: postal,
            listingId,
            publicData: {
              isFree: false,
              paid: true,
              saved: true,
              showFree: false,
              showPaid: true,
              cancelledByUser: false,
            },
            // metadata: {
            //   booking_platform_website: url,
            //   ownBookingPlatform,
            // },
            availabilityPlan: availabilityPlan,
          });
          return resolve(response.data);
        } catch (e) {
          const err = e?.response?.data?.message || e.message;
          message = err;
          return reject(err);
        }
      });
    };

    // step 3: handle 3d secure;
    const fnHandle3dSecure = async fnParams => {
      if (typeof fnParams == 'undefined') return Promise.reject(message);

      const { status, client_secret } = fnParams.latest_invoice.payment_intent;
      if (status === 'requires_action') {
        return new Promise(async (resolve, reject) => {
          return stripe.confirmCardPayment(client_secret).then(result => {
            if (result.error) {
              message = result.error.message;
              return reject(message);
            }
            return resolve(fnParams);
          });
        });
      } else {
        return Promise.resolve(fnParams);
      }
    };

    // step 4: complete;
    const fnComplete = async fnParams => {
      if (typeof fnParams == 'undefined') {
        setSubmitting(false);
        setSuccess(false);
        return;
      }
      {
        isAccountSettingsPage
          ? null
          : await handleListingSubmit({
              publicData: {
                isFree: false,
                paid: true,
                saved: true,
                showFree: false,
                showPaid: true,
                cancelledByUser: false,
              },
              metadata: {
                booking_platform_website: url,
                ownBookingPlatform,
              },
              availabilityPlan: availabilityPlan,
            });
      }
      dispatch(handleFreeState(false));
      // await dispatch(fetchCurrentUserSimple());
      successParentSet ? successParentSet(true) : null;
      setSubscriptionStripeFormOpen ? setSubscriptionStripeFormOpen(false) : null;
      return setSuccess(true);
    };

    const applyAsync = (acc, val) => acc.then(val).catch(err => setErrorMessage(err));

    const composeAsync = (...funcs) => x => funcs.reduce(applyAsync, Promise.resolve(x));
    const handleSubscriptionSubmit = composeAsync(
      fnCreatePaymentMethod,
      fnSubscribe,
      fnHandle3dSecure,
      fnComplete
    );
    return handleSubscriptionSubmit({});
  };

  const membership = currentUserHasMembership(ensuredUser);
  console.log('membership', membership && !isAccountSettingsPage);
  if (membership && !isAccountSettingsPage) return <NamedRedirect name="LandingPage" />;

  return (
    <form onSubmit={isAccountSettingsPage ? handleUpdate : handleSubmit} className="paymentForm">
      {isAccountSettingsPage ? null : (
        <h2>
          {intl.formatMessage({
            id: 'MembershipPage.MembershipPaymentPage.listingSubscrptionHeading',
          })}
        </h2>
      )}
      {isAccountSettingsPage ? (
        <p className={css.subHeading}>{fm({ id: 'MembershipPage.descriptionupdate' })}</p>
      ) : (
        <p className={css.subHeading}>{fm({ id: 'MembershipPage.description' })}</p>
      )}
      {updated ? (
        <div className={css.detailsUpdated}>
          {intl.formatMessage({ id: 'MembershipPage.MembershipPaymentPage.updateLabel' })}
        </div>
      ) : null}

      <label htmlFor="cardNumber">
        {intl.formatMessage({ id: 'MembershipPage.MembershipPaymentPage.CardNumberLabel' })}
      </label>
      <CardNumberElement
        id="cardNumber"
        options={ELEMENT_OPTIONS}
        className="spacer"
        onChange={e => handleChange('card', e)}
      />
      {cardError && <span className="stripeError">{cardError}</span>}
      <label htmlFor="expiry">
        {intl.formatMessage({ id: 'MembershipPage.MembershipPaymentPage.CardExpirationLabel' })}
      </label>
      <CardExpiryElement
        id="expiry"
        options={ELEMENT_OPTIONS}
        className="spacer"
        onChange={e => handleChange('expiration', e)}
      />
      {expirationError && <span className="stripeError">{expirationError}</span>}
      <label htmlFor="cvc">
        {intl.formatMessage({ id: 'MembershipPage.MembershipPaymentPage.CardCVCLabel' })}
      </label>
      <CardCvcElement
        id="cvc"
        options={ELEMENT_OPTIONS}
        className="spacer"
        onChange={e => handleChange('cvc', e)}
      />
      {cvcError && <span className="stripeError">{cvcError}</span>}
      <label htmlFor="postal">
        {intl.formatMessage({ id: 'MembershipPage.MembershipPaymentPage.PostalCodeLabel' })}
      </label>
      <input
        id="postal"
        required
        placeholder={intl.formatMessage({
          id: 'MembershipPage.MembershipPaymentPage.PostalCodePlaceholder',
        })}
        value={postal}
        onChange={e => setPostal(e.target.value)}
      />
      {isAccountSettingsPage ? null : (
        <>
          <label htmlFor="booking_platform_website">
            {intl.formatMessage({
              id: 'EditListingAvailabilityPanel.OwnBookingPlatformForm.InputLabel',
            })}
          </label>

          <input
            id="booking_platform_website"
            required
            type="url"
            placeholder="https://queerhealers.com"
            value={url}
            onChange={e => {
              setUrl(e.target.value);
              setUrlMain(e.target.value);
            }}
          />
        </>
      )}

      {errorMessage && <p className="genericStripeError">{errorMessage}</p>}
      <button type="submit" disabled={submitDisabled}>
        {isAccountSettingsPage
          ? intl.formatMessage({
              id: loading
                ? 'MembershipPage.MembershipPaymentPage.UpdateingButtonLabel'
                : 'MembershipPage.MembershipPaymentPage.UpdateButtonLabel',
            })
          : intl.formatMessage({
              id: submitting
                ? 'MembershipPage.MembershipPaymentPage.SubscribingButtonLabel'
                : isFree
                ? 'MembershipPage.MembershipPaymentPage.SubscribeButtonLabel'
                : 'MembershipPage.MembershipPaymentPage.Subscribe&ReviewButtonLabel',
            })}
      </button>
      {isAccountSettingsPage ? null : (
        <button
          className={css.cancelButton}
          type="button"
          disabled={submitting}
          onClick={() => setOwnBookingPlatform(false)}
        >
          <FormattedMessage id="MembershipPage.MembershipPaymentPage.cancelButton" />
        </button>
      )}
    </form>
  );
};

const MembershipPaymentPage = props => {
  const { location } = props;
  const dispatch = useDispatch();
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);
  return (
    // <Page contentType="website" title={'Subscription Payment'}>
    //   <LayoutSingleColumn>
    //     <LayoutWrapperTopbar>
    //       <TopbarContainer currentPage="SubscriptionPaymentPage" />
    //     </LayoutWrapperTopbar>
    //     <LayoutWrapperMain>
    <div className="root">
      <Elements stripe={stripePromise}>
        <MembershipPaymentPageComponent dispatch={dispatch} {...props} />
      </Elements>
    </div>
    //     </LayoutWrapperMain>
    //     <LayoutWrapperFooter>
    //       <Footer />
    //     </LayoutWrapperFooter>
    //   </LayoutSingleColumn>
    // </Page>
  );
};

export default withRouter(MembershipPaymentPage);
