import React from 'react';
import PropTypes from 'prop-types';

const IconLogo = props => {
  const { className, format, ...rest } = props;

  if (format === 'desktop') {
    return (
      <svg
        className={className}
        {...rest}
        width="176"
        height="23"
        viewBox="0 0 176 23"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.984619 9.62409C0.984619 14.4241 4.80062 18.2401 9.60062 18.2401C9.76862 18.2401 10.0566 18.2401 10.3446 18.2161L12.8886 22.5121C12.9606 22.6321 13.0806 22.7521 13.2726 22.7521H16.5126C16.8966 22.7521 17.0886 22.3681 16.8966 22.0321L13.8006 17.1841C16.4406 15.5761 18.2406 12.7441 18.2406 9.62409C18.2406 4.82409 14.4006 0.960088 9.60062 0.960088C4.80062 0.960088 0.984619 4.82409 0.984619 9.62409ZM4.82462 9.62409C4.82462 6.98409 6.98462 4.80009 9.60062 4.80009C12.2406 4.80009 14.4006 6.98409 14.4006 9.62409C14.4006 12.2401 12.2406 14.4001 9.60062 14.4001C6.98462 14.4001 4.82462 12.2401 4.82462 9.62409Z"
          fill="black"
        />
        <path
          d="M20.9764 11.5681C20.9764 15.2161 24.0244 18.2401 27.7924 18.2401C31.5844 18.2401 34.6564 15.2161 34.6564 11.5681V1.65609C34.6564 1.41609 34.4404 1.20009 34.2004 1.20009H31.2484C30.9844 1.20009 30.7924 1.41609 30.7924 1.65609V11.3761C30.7924 13.0561 29.5204 14.4001 27.7924 14.4001C26.0884 14.4001 24.8404 13.0561 24.8404 11.3761V1.65609C24.8404 1.41609 24.6484 1.20009 24.3844 1.20009H21.4324C21.1924 1.20009 20.9764 1.41609 20.9764 1.65609V11.5681Z"
          fill="black"
        />
        <path
          d="M38.5133 17.5441C38.5133 17.7841 38.7053 18.0001 38.9693 18.0001H48.9053C49.1693 18.0001 49.3613 17.7841 49.3613 17.5441V14.9281C49.3613 14.6881 49.1693 14.4721 48.9053 14.4721H42.2573V11.2321H47.7293C47.9693 11.2321 48.1853 11.0401 48.1853 10.7761V8.16009C48.1853 7.92009 47.9693 7.70409 47.7293 7.70409H42.2573V4.72809H48.9053C49.1693 4.72809 49.3613 4.51209 49.3613 4.27209V1.65609C49.3613 1.41609 49.1693 1.20009 48.9053 1.20009H38.9693C38.7053 1.20009 38.5133 1.41609 38.5133 1.65609V17.5441Z"
          fill="black"
        />
        <path
          d="M52.5758 17.5441C52.5758 17.7841 52.7678 18.0001 53.0318 18.0001H62.9678C63.2318 18.0001 63.4238 17.7841 63.4238 17.5441V14.9281C63.4238 14.6881 63.2318 14.4721 62.9678 14.4721H56.3198V11.2321H61.7918C62.0318 11.2321 62.2478 11.0401 62.2478 10.7761V8.16009C62.2478 7.92009 62.0318 7.70409 61.7918 7.70409H56.3198V4.72809H62.9678C63.2318 4.72809 63.4238 4.51209 63.4238 4.27209V1.65609C63.4238 1.41609 63.2318 1.20009 62.9678 1.20009H53.0318C52.7678 1.20009 52.5758 1.41609 52.5758 1.65609V17.5441Z"
          fill="black"
        />
        <path
          d="M66.6383 17.5441C66.6383 17.7841 66.8303 18.0001 67.0943 18.0001H69.9503C70.1903 18.0001 70.4063 17.7841 70.4063 17.5441V11.4241H72.0623L75.2543 17.7841C75.3023 17.8801 75.4463 18.0001 75.6383 18.0001H78.8303C79.2383 18.0001 79.4063 17.6161 79.2383 17.3041L75.9503 11.2081C78.0383 10.3681 79.5023 8.59209 79.5023 6.38409C79.5023 3.52809 77.1503 1.20009 74.2703 1.20009H67.0943C66.8303 1.20009 66.6383 1.41609 66.6383 1.65609V17.5441ZM70.4063 8.37609V4.63209H73.9583C74.9423 4.63209 75.7823 5.44809 75.7823 6.45609C75.7823 7.46409 74.9423 8.37609 73.9583 8.37609H70.4063Z"
          fill="black"
        />
        <path
          d="M82.2604 17.6881C82.2364 17.8561 82.3564 18.0001 82.5244 18.0001H83.0284C83.2204 18.0001 83.3644 17.8561 83.3884 17.6881L84.4924 9.88809H94.0204L92.9164 17.6881C92.8924 17.8561 93.0124 18.0001 93.1804 18.0001H93.7084C93.8764 18.0001 94.0204 17.8561 94.0444 17.6881L96.3244 1.51209C96.3484 1.34409 96.2524 1.20009 96.0604 1.20009H95.5324C95.3404 1.20009 95.2204 1.34409 95.1964 1.51209L94.1644 8.88009H84.6124L85.6684 1.51209C85.6924 1.34409 85.5964 1.20009 85.4044 1.20009H84.8764C84.6844 1.20009 84.5644 1.34409 84.5404 1.51209L82.2604 17.6881Z"
          fill="black"
        />
        <path
          d="M99.0651 17.6881C99.0411 17.8561 99.1611 18.0001 99.3291 18.0001H107.969C108.137 18.0001 108.281 17.8561 108.305 17.6881L108.377 17.3041C108.401 17.1361 108.281 16.9921 108.113 16.9921H100.313L101.321 9.88809H108.041C108.209 9.88809 108.377 9.74409 108.401 9.57609L108.449 9.16809C108.473 9.00009 108.353 8.85609 108.185 8.85609H101.465L102.401 2.23209H110.177C110.345 2.23209 110.513 2.08809 110.537 1.92009L110.585 1.51209C110.609 1.34409 110.489 1.20009 110.321 1.20009H101.681C101.537 1.20009 101.369 1.34409 101.345 1.51209L99.0651 17.6881Z"
          fill="black"
        />
        <path
          d="M110.986 18.0001C111.202 18.0001 111.322 17.8801 111.37 17.7841L113.674 13.3681H121.786L122.89 17.7841C122.914 17.8801 122.986 18.0001 123.202 18.0001H123.826C124.09 18.0001 124.138 17.8561 124.09 17.7121L119.842 1.00809C119.794 0.840087 119.674 0.840088 119.554 0.840088H119.482C119.29 0.840088 119.218 0.912088 119.17 1.00809L110.17 17.6641C110.074 17.8561 110.122 18.0001 110.29 18.0001H110.986ZM114.226 12.4321L119.218 2.76009C119.242 2.76009 119.242 2.76009 119.29 2.76009L121.522 12.4321H114.226Z"
          fill="black"
        />
        <path
          d="M126.792 17.6881C126.768 17.8561 126.888 18.0001 127.056 18.0001H134.664C134.832 18.0001 134.976 17.9041 135 17.7361L135.072 17.3041C135.096 17.1361 135.096 16.9921 134.808 16.9921H128.016L130.2 1.51209C130.224 1.34409 130.104 1.20009 129.96 1.20009H129.408C129.264 1.20009 129.096 1.34409 129.072 1.51209L126.792 17.6881Z"
          fill="black"
        />
        <path
          d="M137.503 17.6881C137.479 17.8561 137.599 18.0001 137.767 18.0001H146.407C146.575 18.0001 146.719 17.8561 146.743 17.6881L146.815 17.3041C146.839 17.1361 146.719 16.9921 146.551 16.9921H138.751L139.759 9.88809H146.479C146.647 9.88809 146.815 9.74409 146.839 9.57609L146.887 9.16809C146.911 9.00009 146.791 8.85609 146.623 8.85609H139.903L140.839 2.23209H148.615C148.783 2.23209 148.951 2.08809 148.975 1.92009L149.023 1.51209C149.047 1.34409 148.927 1.20009 148.759 1.20009H140.119C139.975 1.20009 139.807 1.34409 139.783 1.51209L137.503 17.6881Z"
          fill="black"
        />
        <path
          d="M150.815 17.6881C150.791 17.8561 150.911 18.0001 151.079 18.0001H151.511C151.751 18.0001 151.919 17.8561 151.943 17.6881L152.807 11.5201H156.623C156.911 11.5201 157.223 11.5201 157.511 11.4961L159.719 17.7841C159.767 17.9281 159.839 18.0001 160.007 18.0001H160.703C160.967 18.0001 161.039 17.7601 160.943 17.5681L158.711 11.3521C161.111 10.7041 162.959 8.42409 162.959 5.66409C162.959 3.14409 161.087 1.20009 158.831 1.20009H153.431C153.239 1.20009 153.119 1.34409 153.095 1.51209L150.815 17.6881ZM152.975 10.5121L154.127 2.30409H158.615C160.439 2.30409 161.759 3.84009 161.759 5.80809C161.759 8.44809 159.671 10.5121 157.463 10.5121H152.975Z"
          fill="black"
        />
        <path
          d="M164.675 16.2721C164.939 16.5601 166.404 18.2401 168.852 18.2401C171.828 18.2401 174.324 16.3201 174.324 13.4881C174.324 11.2081 172.667 10.1281 170.147 8.92809C168.395 8.08809 167.124 7.24809 167.124 5.56809C167.124 3.98409 168.419 1.99209 171.227 1.99209C172.835 1.99209 174.108 3.04809 174.18 3.09609C174.396 3.24009 174.564 3.16809 174.708 2.97609L174.923 2.68809C175.139 2.40009 174.972 2.25609 174.9 2.18409C174.9 2.18409 173.484 0.960088 171.372 0.960088C167.556 0.960088 165.995 3.50409 165.995 5.66409C165.995 7.87209 167.484 8.85609 169.572 9.91209C171.708 10.9921 173.171 11.8321 173.171 13.6081C173.171 15.6721 171.203 17.2321 168.947 17.2321C167.003 17.2321 165.659 15.6481 165.467 15.4321C165.323 15.2401 165.132 15.2641 164.964 15.4801L164.675 15.7681C164.435 16.0321 164.555 16.1521 164.675 16.2721Z"
          fill="black"
        />
      </svg>
    );
  }

  return (
    <svg
      className={className}
      {...rest}
      width="176"
      height="23"
      viewBox="0 0 176 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.984619 9.62409C0.984619 14.4241 4.80062 18.2401 9.60062 18.2401C9.76862 18.2401 10.0566 18.2401 10.3446 18.2161L12.8886 22.5121C12.9606 22.6321 13.0806 22.7521 13.2726 22.7521H16.5126C16.8966 22.7521 17.0886 22.3681 16.8966 22.0321L13.8006 17.1841C16.4406 15.5761 18.2406 12.7441 18.2406 9.62409C18.2406 4.82409 14.4006 0.960088 9.60062 0.960088C4.80062 0.960088 0.984619 4.82409 0.984619 9.62409ZM4.82462 9.62409C4.82462 6.98409 6.98462 4.80009 9.60062 4.80009C12.2406 4.80009 14.4006 6.98409 14.4006 9.62409C14.4006 12.2401 12.2406 14.4001 9.60062 14.4001C6.98462 14.4001 4.82462 12.2401 4.82462 9.62409Z"
        fill="black"
      />
      <path
        d="M20.9764 11.5681C20.9764 15.2161 24.0244 18.2401 27.7924 18.2401C31.5844 18.2401 34.6564 15.2161 34.6564 11.5681V1.65609C34.6564 1.41609 34.4404 1.20009 34.2004 1.20009H31.2484C30.9844 1.20009 30.7924 1.41609 30.7924 1.65609V11.3761C30.7924 13.0561 29.5204 14.4001 27.7924 14.4001C26.0884 14.4001 24.8404 13.0561 24.8404 11.3761V1.65609C24.8404 1.41609 24.6484 1.20009 24.3844 1.20009H21.4324C21.1924 1.20009 20.9764 1.41609 20.9764 1.65609V11.5681Z"
        fill="black"
      />
      <path
        d="M38.5133 17.5441C38.5133 17.7841 38.7053 18.0001 38.9693 18.0001H48.9053C49.1693 18.0001 49.3613 17.7841 49.3613 17.5441V14.9281C49.3613 14.6881 49.1693 14.4721 48.9053 14.4721H42.2573V11.2321H47.7293C47.9693 11.2321 48.1853 11.0401 48.1853 10.7761V8.16009C48.1853 7.92009 47.9693 7.70409 47.7293 7.70409H42.2573V4.72809H48.9053C49.1693 4.72809 49.3613 4.51209 49.3613 4.27209V1.65609C49.3613 1.41609 49.1693 1.20009 48.9053 1.20009H38.9693C38.7053 1.20009 38.5133 1.41609 38.5133 1.65609V17.5441Z"
        fill="black"
      />
      <path
        d="M52.5758 17.5441C52.5758 17.7841 52.7678 18.0001 53.0318 18.0001H62.9678C63.2318 18.0001 63.4238 17.7841 63.4238 17.5441V14.9281C63.4238 14.6881 63.2318 14.4721 62.9678 14.4721H56.3198V11.2321H61.7918C62.0318 11.2321 62.2478 11.0401 62.2478 10.7761V8.16009C62.2478 7.92009 62.0318 7.70409 61.7918 7.70409H56.3198V4.72809H62.9678C63.2318 4.72809 63.4238 4.51209 63.4238 4.27209V1.65609C63.4238 1.41609 63.2318 1.20009 62.9678 1.20009H53.0318C52.7678 1.20009 52.5758 1.41609 52.5758 1.65609V17.5441Z"
        fill="black"
      />
      <path
        d="M66.6383 17.5441C66.6383 17.7841 66.8303 18.0001 67.0943 18.0001H69.9503C70.1903 18.0001 70.4063 17.7841 70.4063 17.5441V11.4241H72.0623L75.2543 17.7841C75.3023 17.8801 75.4463 18.0001 75.6383 18.0001H78.8303C79.2383 18.0001 79.4063 17.6161 79.2383 17.3041L75.9503 11.2081C78.0383 10.3681 79.5023 8.59209 79.5023 6.38409C79.5023 3.52809 77.1503 1.20009 74.2703 1.20009H67.0943C66.8303 1.20009 66.6383 1.41609 66.6383 1.65609V17.5441ZM70.4063 8.37609V4.63209H73.9583C74.9423 4.63209 75.7823 5.44809 75.7823 6.45609C75.7823 7.46409 74.9423 8.37609 73.9583 8.37609H70.4063Z"
        fill="black"
      />
      <path
        d="M82.2604 17.6881C82.2364 17.8561 82.3564 18.0001 82.5244 18.0001H83.0284C83.2204 18.0001 83.3644 17.8561 83.3884 17.6881L84.4924 9.88809H94.0204L92.9164 17.6881C92.8924 17.8561 93.0124 18.0001 93.1804 18.0001H93.7084C93.8764 18.0001 94.0204 17.8561 94.0444 17.6881L96.3244 1.51209C96.3484 1.34409 96.2524 1.20009 96.0604 1.20009H95.5324C95.3404 1.20009 95.2204 1.34409 95.1964 1.51209L94.1644 8.88009H84.6124L85.6684 1.51209C85.6924 1.34409 85.5964 1.20009 85.4044 1.20009H84.8764C84.6844 1.20009 84.5644 1.34409 84.5404 1.51209L82.2604 17.6881Z"
        fill="black"
      />
      <path
        d="M99.0651 17.6881C99.0411 17.8561 99.1611 18.0001 99.3291 18.0001H107.969C108.137 18.0001 108.281 17.8561 108.305 17.6881L108.377 17.3041C108.401 17.1361 108.281 16.9921 108.113 16.9921H100.313L101.321 9.88809H108.041C108.209 9.88809 108.377 9.74409 108.401 9.57609L108.449 9.16809C108.473 9.00009 108.353 8.85609 108.185 8.85609H101.465L102.401 2.23209H110.177C110.345 2.23209 110.513 2.08809 110.537 1.92009L110.585 1.51209C110.609 1.34409 110.489 1.20009 110.321 1.20009H101.681C101.537 1.20009 101.369 1.34409 101.345 1.51209L99.0651 17.6881Z"
        fill="black"
      />
      <path
        d="M110.986 18.0001C111.202 18.0001 111.322 17.8801 111.37 17.7841L113.674 13.3681H121.786L122.89 17.7841C122.914 17.8801 122.986 18.0001 123.202 18.0001H123.826C124.09 18.0001 124.138 17.8561 124.09 17.7121L119.842 1.00809C119.794 0.840087 119.674 0.840088 119.554 0.840088H119.482C119.29 0.840088 119.218 0.912088 119.17 1.00809L110.17 17.6641C110.074 17.8561 110.122 18.0001 110.29 18.0001H110.986ZM114.226 12.4321L119.218 2.76009C119.242 2.76009 119.242 2.76009 119.29 2.76009L121.522 12.4321H114.226Z"
        fill="black"
      />
      <path
        d="M126.792 17.6881C126.768 17.8561 126.888 18.0001 127.056 18.0001H134.664C134.832 18.0001 134.976 17.9041 135 17.7361L135.072 17.3041C135.096 17.1361 135.096 16.9921 134.808 16.9921H128.016L130.2 1.51209C130.224 1.34409 130.104 1.20009 129.96 1.20009H129.408C129.264 1.20009 129.096 1.34409 129.072 1.51209L126.792 17.6881Z"
        fill="black"
      />
      <path
        d="M137.503 17.6881C137.479 17.8561 137.599 18.0001 137.767 18.0001H146.407C146.575 18.0001 146.719 17.8561 146.743 17.6881L146.815 17.3041C146.839 17.1361 146.719 16.9921 146.551 16.9921H138.751L139.759 9.88809H146.479C146.647 9.88809 146.815 9.74409 146.839 9.57609L146.887 9.16809C146.911 9.00009 146.791 8.85609 146.623 8.85609H139.903L140.839 2.23209H148.615C148.783 2.23209 148.951 2.08809 148.975 1.92009L149.023 1.51209C149.047 1.34409 148.927 1.20009 148.759 1.20009H140.119C139.975 1.20009 139.807 1.34409 139.783 1.51209L137.503 17.6881Z"
        fill="black"
      />
      <path
        d="M150.815 17.6881C150.791 17.8561 150.911 18.0001 151.079 18.0001H151.511C151.751 18.0001 151.919 17.8561 151.943 17.6881L152.807 11.5201H156.623C156.911 11.5201 157.223 11.5201 157.511 11.4961L159.719 17.7841C159.767 17.9281 159.839 18.0001 160.007 18.0001H160.703C160.967 18.0001 161.039 17.7601 160.943 17.5681L158.711 11.3521C161.111 10.7041 162.959 8.42409 162.959 5.66409C162.959 3.14409 161.087 1.20009 158.831 1.20009H153.431C153.239 1.20009 153.119 1.34409 153.095 1.51209L150.815 17.6881ZM152.975 10.5121L154.127 2.30409H158.615C160.439 2.30409 161.759 3.84009 161.759 5.80809C161.759 8.44809 159.671 10.5121 157.463 10.5121H152.975Z"
        fill="black"
      />
      <path
        d="M164.675 16.2721C164.939 16.5601 166.404 18.2401 168.852 18.2401C171.828 18.2401 174.324 16.3201 174.324 13.4881C174.324 11.2081 172.667 10.1281 170.147 8.92809C168.395 8.08809 167.124 7.24809 167.124 5.56809C167.124 3.98409 168.419 1.99209 171.227 1.99209C172.835 1.99209 174.108 3.04809 174.18 3.09609C174.396 3.24009 174.564 3.16809 174.708 2.97609L174.923 2.68809C175.139 2.40009 174.972 2.25609 174.9 2.18409C174.9 2.18409 173.484 0.960088 171.372 0.960088C167.556 0.960088 165.995 3.50409 165.995 5.66409C165.995 7.87209 167.484 8.85609 169.572 9.91209C171.708 10.9921 173.171 11.8321 173.171 13.6081C173.171 15.6721 171.203 17.2321 168.947 17.2321C167.003 17.2321 165.659 15.6481 165.467 15.4321C165.323 15.2401 165.132 15.2641 164.964 15.4801L164.675 15.7681C164.435 16.0321 164.555 16.1521 164.675 16.2721Z"
        fill="black"
      />
    </svg>
  );
};

const { string } = PropTypes;

IconLogo.defaultProps = {
  className: null,
};

IconLogo.propTypes = {
  className: string,
};

export default IconLogo;
