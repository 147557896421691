/*
 * Marketplace specific configuration.
 *
 * Every filter needs to have following keys:
 * - id:     Unique id of the filter.
 * - label:  The default label of the filter.
 * - type:   String that represents one of the existing filter components:
 *           BookingDateRangeFilter, KeywordFilter, PriceFilter,
 *           SelectSingleFilter, SelectMultipleFilter.
 * - group:  Is this 'primary' or 'secondary' filter?
 *           Primary filters are visible on desktop layout by default.
 *           Secondary filters are behind "More filters" button.
 *           Read more from src/containers/SearchPage/README.md
 * - queryParamNames: Describes parameters to be used with queries
 *                    (e.g. 'price' or 'pub_amenities'). Most of these are
 *                    the same between webapp URLs and API query params.
 *                    You can't change 'dates', 'price', or 'keywords'
 *                    since those filters are fixed to a specific attribute.
 * - config: Extra configuration that the filter component needs.
 *
 * Note 1: Labels could be tied to translation file
 *         by importing FormattedMessage:
 *         <FormattedMessage id="some.translation.key.here" />
 *
 * Note 2: If you need to add new custom filter components,
 *         you need to take those into use in:
 *         src/containers/SearchPage/FilterComponent.js
 *
 * Note 3: If you just want to create more enum filters
 *         (i.e. SelectSingleFilter, SelectMultipleFilter),
 *         you can just add more configurations with those filter types
 *         and tie them with correct extended data key
 *         (i.e. pub_<key> or meta_<key>).
 */
import imageOne from '../src/BannerImages/clouds-1.jpg';
import imageTwo from '../src/BannerImages/clouds-2.jpg';
import imageThree from '../src/BannerImages/nature-background-1.jpg';
import imageFour from '../src/BannerImages/nature-background-2.jpg';
import imageFive from '../src/BannerImages/nature-background-3.jpg';
import imageSix from '../src/BannerImages/nature-background-4.jpg';

export const bannerImages = [
  { image: imageOne, id: 11111 },
  { image: imageTwo, id: 11112 },
  { image: imageThree, id: 11113 },
  { image: imageFour, id: 11114 },
  { image: imageFive, id: 11115 },
  { image: imageSix, id: 11116 },
];
export const virtualSessionOptions = [{ label: 'Virtual Sessions', key: true }];
export const backgroundIdentities = [
  {
    label: 'African',
    key: 'african',
  },
  {
    label: 'Afro-Caribbean',
    key: 'afro-caribbean',
  },
  {
    label: 'Afro-Latinx',
    key: 'afro-latinx',
  },
  {
    label: 'Arab',
    key: 'arab',
  },
  {
    label: 'Asian',
    key: 'asian',
  },
  {
    label: 'Bi-Racial',
    key: 'bi-racial',
  },
  {
    label: 'Black',
    key: 'black',
  },
  {
    label: 'Central American',
    key: 'central-american',
  },
  {
    label: 'Central Asian',
    key: 'central-asian',
  },
  {
    label: 'Chicanx',
    key: 'chicanx',
  },
  {
    label: 'East Asian',
    key: 'east-asian',
  },
  {
    label: 'European',
    key: 'european',
  },
  {
    label: 'Filipinx',
    key: 'filipinx',
  },
  {
    label: 'First Generation',
    key: 'first-generation',
  },
  {
    label: 'Hispanic',
    key: 'hispanic',
  },
  {
    label: 'Immigrant',
    key: 'immigrant',
  },
  {
    label: 'Indigenous',
    key: 'indigenous',
  },
  {
    label: 'Latinx',
    key: 'latinx',
  },
  {
    label: 'Middle Eastern',
    key: 'middle-eastern',
  },
  {
    label: 'Mixed Race',
    key: 'mixed-race',
  },
  {
    label: 'Multiethnic',
    key: 'multiethnic',
  },
  {
    label: 'Multiracial',
    key: 'multiracial',
  },
  {
    label: 'Pacific Islander',
    key: 'pacific-islander',
  },
  {
    label: 'Persian',
    key: 'persian',
  },

  {
    label: 'Person of Color',
    key: 'person-of-color',
  },
  {
    label: 'Polynesian',
    key: 'polynesian',
  },
  {
    label: 'Romani',
    key: 'romani',
  },
  {
    label: 'Second Generation',
    key: 'second-generation',
  },
  {
    label: 'Sicillian',
    key: 'sicillian',
  },
  {
    label: 'South American',
    key: 'south-american',
  },

  {
    label: 'South Asian',
    key: 'south-asian',
  },
  {
    label: 'Southeast Asian',
    key: 'southeast-asian',
  },
  {
    label: 'SWANA',
    key: 'swana',
  },
  {
    label: 'Tagalog',
    key: 'tagalog',
  },
  {
    label: 'Third Culture Kid',
    key: 'third-culture-kid',
  },
  {
    label: 'White',
    key: 'white',
  },
];

export const classBackgroundIdentity = [
  {
    label: 'Generationally Wealthy',
    key: 'generationally-wealthy',
  },
  {
    label: 'Raised Middle Class',
    key: 'raised-middle-class',
  },
  {
    label: 'Raised Poor',
    key: 'raised-poor',
  },
  {
    label: 'Raised Working Class',
    key: 'raised-working-class',
  },
];

export const disabilitiesIdentity = [
  {
    label: 'Adult Child of Deaf Adult',
    key: 'adult-child-of-deaf-adult',
  },
  {
    label: 'Amputee',
    key: 'amputee',
  },
  {
    label: 'Autistic',
    key: 'autistic',
  },
  {
    label: 'Blind / Vision Impaired',
    key: 'blind--vision-impaired',
  },
  {
    label: 'Chronic Illness',
    key: 'chronic-illness',
  },
  {
    label: 'Deaf / Hard of Hearing / Hearing Impaired',
    key: 'deaf--hard-of-hearing--hearing-impaired',
  },
  {
    label: 'HIV+',
    key: 'hiv',
  },
  {
    label: 'Living with PTSD / CPTSD',
    key: 'living-with-ptsd-cptsd',
  },
  {
    label: 'Neurodivergent',
    key: 'neurodivergent',
  },
  {
    label: 'Person with Disability',
    key: 'person-with-disability',
  },
  {
    label: 'Wheelchair User',
    key: 'wheelchair-user',
  },
];

export const miscIdentities = [
  {
    label: '40+',
    key: '40+',
  },
  {
    label: 'Adopted',
    key: 'adopted',
  },
  {
    label: 'Adult Child of Alcoholic',
    key: 'adult-child-of-alcoholic',
  },
  {
    label: 'Disordered Eating',
    key: 'disordered-eating',
  },
  {
    label: 'Fat',
    key: 'fat',
  },
  {
    label: 'History of Self-harm',
    key: 'history-of-self-harm',
  },
  {
    label: 'Orphan',
    key: 'orphan',
  },
  {
    label: 'Parent',
    key: 'parent',
  },
  {
    label: 'Refugee',
    key: 'refugee',
  },
  {
    label: 'Sober / In Recovery',
    key: 'sober--in-recovery',
  },
  {
    label: 'Substance Addict',
    key: 'substance-addict',
  },
  {
    label: 'Trauma Survivor',
    key: 'trauma-survivor',
  },
  {
    label: 'Twin / Triplet / Multiple',
    key: 'twin-triplet-multiple',
  },

  {
    label: 'Vegan',
    key: 'vegan',
  },
  {
    label: 'Veteran',
    key: 'veteran',
  },
];

export const genderIdentities = [
  {
    label: 'Agender',
    key: 'agender',
  },

  {
    label: 'Cisgender',
    key: 'cisgender',
  },
  {
    label: 'Female',
    key: 'female',
  },
  {
    label: 'Femme',
    key: 'femme',
  },
  {
    label: 'Gender Non-conforming',
    key: 'gender-non-conforming',
  },
  {
    label: 'Gender-fluid',
    key: 'gender-fluid',
  },
  {
    label: 'Intersex',
    key: 'intersex',
  },
  {
    label: 'Male',
    key: 'male',
  },
  {
    label: 'Masc / Butch / Stud',
    key: 'masc--butch--stud',
  },
  {
    label: 'Non-binary',
    key: 'non-binary',
  },
  {
    label: 'Transgender',
    key: 'transgender',
  },
  {
    label: 'Two-Spirit',
    key: 'two-spirit',
  },
];

export const pronouns = [
  { value: 'él', key: 'él', label: 'él' },
  { value: 'ella', key: 'ella', label: 'ella' },
  { value: 'elle', key: 'elle', label: 'elle' },
  { value: 'he/him', key: 'he/him', label: 'he/him' },
  { value: 'she/her', key: 'she/her', label: 'she/her' },
  // { value: 'they/he', key: 'they/he', label: 'they/he' },
  // { value: 'they/she', key: 'they/she', label: 'they/she' },
  { value: 'they/them', key: 'they/them', label: 'they/them' },
  { value: 'ze/zim/zir', key: 'ze/zim/zir', label: 'ze/zim/zir' },
  // { value: 'no_pronouns', key: 'no_pronouns', label: 'No pronouns' },
  // {
  //   value: 'my_pronoun_isnt_listed',
  //   key: 'my_pronoun_isnt_listed',
  //   label: "My pronoun isn't listed",
  // },
];

export const otherPronouns = [
  { value: 'all_pronouns', key: 'all_pronouns', label: 'All pronouns' },

  { value: 'no_pronouns', key: 'no_pronouns', label: 'No pronouns' },

  {
    value: 'prefer_not_to_share_my_pronouns',
    key: 'prefer_not_to_share_my_pronouns',
    label: 'Prefer not to share my pronouns',
  },
  {
    value: 'my_pronouns_arent_available_to_select',
    key: 'my_pronouns_arent_available_to_select',
    label: "My pronouns aren't available to select",
  },
];

export const sexualityIdentities = [
  {
    label: 'Asexual',
    key: 'asexual',
  },
  {
    label: 'BDSM-oriented',
    key: 'bdsm-oriented',
  },
  {
    label: 'Bisexual',
    key: 'bisexual',
  },
  {
    label: 'Demisexual',
    key: 'demisexual',
  },
  {
    label: 'Gay',
    key: 'gay',
  },
  // {
  //   label: 'Heterosexual',
  //   key: 'heterosexual',
  // },

  {
    label: 'Lesbian',
    key: 'lesbian',
  },
  {
    label: 'Monogamous',
    key: 'monogamous',
  },
  {
    label: 'Non-monogamous',
    key: 'non-monogamous',
  },
  {
    label: 'Pansexual',
    key: 'pansexual',
  },
  {
    label: 'Polyamorous',
    key: 'polyamorous',
  },
  {
    label: 'Queer',
    key: 'queer',
  },
  {
    label: 'Sex Positive',
    key: 'sex-positive',
  },
];

export const spiritualityIdentities = [
  {
    label: 'Agnostic',
    key: 'agnostic',
  },
  {
    label: 'Animist',
    key: 'animist',
  },
  {
    label: 'Atheist',
    key: 'atheist',
  },
  {
    label: 'Buddhist',
    key: 'buddhist',
  },
  {
    label: 'Christian',
    key: 'christian',
  },
  {
    label: 'Daoist / Taoist',
    key: 'daoist--taoist',
  },
  {
    label: 'Folk Magick',
    key: 'folk-magick',
  },
  {
    label: 'Jewish',
    key: 'jewish',
  },
  {
    label: 'Hindu',
    key: 'hindu',
  },
  {
    label: 'Muslim',
    key: 'muslim',
  },
  {
    label: 'Pagan / Earth-centered Faith',
    key: 'pagan--earth-centered-faith',
  },
  {
    label: 'Santeria / Palo / Ifa',
    key: 'santeria--palo--ifa',
  },
  {
    label: 'Spiritual',
    key: 'spiritual',
  },
  {
    label: 'Thelema',
    key: 'thelema',
  },
  {
    label: 'Voodoo',
    key: 'voodoo',
  },
  {
    label: 'Wicca(n)',
    key: 'wicca(n)',
  },
  {
    label: 'Zoroastrianism',
    key: 'zoroastrianism',
  },
  {
    label: 'Witch',
    key: 'witch',
  },
];

export const sessionLocations = [
  {
    key: 'phone',
    label: 'Phone',
  },
  {
    key: 'text',
    label: 'Text',
  },
  {
    key: 'video',
    label: 'Video',
  },
  {
    key: 'physical_location',
    label: 'Physical Location',
  },
];

export const allIdentities = [
  ...backgroundIdentities,
  ...classBackgroundIdentity,
  ...disabilitiesIdentity,
  ...miscIdentities,
  ...genderIdentities,
  ...sexualityIdentities,
  ...spiritualityIdentities,
];

export const offeringSessionFormatOptions = [
  { key: 'one-on-one', value: 'one-on-one', label: 'One-on-one' },
  { key: 'couples-dyads', value: 'couples-dyads', label: 'Couples / Dyads' },
  {
    key: 'multiple-partners--polycules',
    value: 'multiple-partners--polycules',
    label: 'Multiple Partners / Polycules',
  },
  {
    key: 'families',
    value: 'families',
    label: 'Families',
  },
  {
    key: 'groups--circles--classes',
    value: 'groups--circles--classes',
    label: 'Groups / Circles / Classes',
  },
  {
    key: 'online-courses',
    value: 'online-courses',
    label: 'Online Courses',
  },
  {
    key: 'individual-time-in-communal-setting',
    value: 'individual-time-in-communal-setting',
    label: 'Individual time in communal setting',
  },
];

/* Modalities */

export const alternativeMedicineModalities = [
  {
    key: 'ayurvedic-medicine',
    label: 'Ayurvedic Medicine',
    value: 'ayurvedic-medicine',
  },
  {
    key: 'black-folk-healing--hoodoo',
    label: 'Black Folk Healing / Hoodoo',
    value: 'black-folk-healing--hoodoo',
  },
  {
    key: 'chinese-medicine',
    label: 'Chinese Medicine',
    value: 'chinese-medicine',
  },
  {
    key: 'eastern-medicine',
    label: 'Eastern Medicine',
    value: 'eastern-medicine',
  },
  {
    key: 'herbalism',
    label: 'Herbalism',
    value: 'herbalism',
  },
  {
    key: 'homeopathy',
    label: 'Homeopathy',
    value: 'homeopathy',
  },
  {
    key: 'natural-medicine',
    label: 'Natural Medicine',
    value: 'natural-medicine',
  },
  {
    key: 'naturopathy',
    label: 'Naturopathy',
    value: 'naturopathy',
  },
];

export const bodyWorkModalities = [
  {
    key: 'acupressure',
    label: 'Acupressure',
    value: 'acupressure',
  },
  {
    key: 'acupuncture',
    label: 'Acupuncture',
    value: 'acupuncture',
  },
  {
    key: 'craniosacral',
    label: 'Craniosacral',
    value: 'craniosacral',
  },
  {
    key: 'cuddle-therapy',
    label: 'Cuddle Therapy',
    value: 'cuddle-therapy',
  },
  {
    key: 'cupping',
    label: 'Cupping',
    value: 'cupping',
  },
  {
    key: 'massage',
    label: 'Massage',
    value: 'massage',
  },
  {
    key: 'movement-healing--core-integration',
    label: 'Movement Healing / Core Integration',
    value: 'movement-healing--core-integration',
  },
  {
    key: 'myofascial',
    label: 'Myofascial',
    value: 'myofascial',
  },
  {
    key: 'qigong--tai-chi',
    value: 'qigong--tai-chi',
    label: 'Qigong / Tai Chi',
  },
  {
    key: 'reflexology',
    label: 'Reflexology',
    value: 'reflexology',
  },
  {
    key: 'scarwork',
    label: 'ScarWork',
    value: 'scarwork',
  },
  {
    key: 'somatic-healing',
    label: 'Somatic Healing',
    value: 'somatic-healing',
  },
  {
    key: 'thai-massage',
    label: 'Thai Massage',
    value: 'thai-massage',
  },
  {
    key: 'yoga',
    label: 'Yoga',
    value: 'yoga',
  },
];

export const coachingModalities = [
  {
    key: 'career-coaching',
    label: 'Career Coaching',
    value: 'career-coaching',
  },
  {
    key: 'coaching',
    label: 'Coaching',
    value: 'coaching',
  },
  {
    key: 'creative-coaching',
    label: 'Creative Coaching',
    value: 'creative-coaching',
  },
  {
    key: 'life-coaching',
    label: 'Life Coaching',
    value: 'life-coaching',
  },
  {
    key: 'relationship-coaching',
    label: 'Relationship Coaching',
    value: 'relationship-coaching',
  },
];

export const doulaModalities = [
  // {
  //   key: 'abortion-doula',
  //   label: 'Abortion Doula',
  //   value: 'abortion-doula',
  // },
  {
    key: 'birth-doula',
    label: 'Birth Doula',
    value: 'birth-doula',
  },
  {
    key: 'death-doula',
    label: 'Death Doula',
    value: 'death-doula',
  },
  {
    key: 'full-spectrum-doula',
    label: 'Full-Spectrum Doula',
    value: 'full-spectrum-doula',
  },
  {
    key: 'gender-doula',
    label: 'Gender Doula',
    value: 'gender-doula',
  },
  {
    key: 'journey--ceremony-guide',
    label: 'Journey / Ceremony Guide',
    value: 'journey--ceremony-guide',
  },
  {
    key: 'out-of-hospital-birth-assistance',
    label: 'Out-of-Hospital Birth Assistance',
    value: 'out-of-hospital-birth-assistance',
  },
];

export const energyWorkModalities = [
  {
    key: 'aromatherapy',
    label: 'Aromatherapy',
    value: 'aromatherapy',
  },
  {
    key: 'bio-field',
    label: 'Bio-Field',
    value: 'bio-field',
  },
  {
    key: 'chakra-balancing',
    label: 'Chakra Balancing',
    value: 'chakra-balancing',
  },
  {
    key: 'crystal-energy-healing',
    label: 'Crystal Energy Healing',
    value: 'crystal-energy-healing',
  },
  {
    key: 'electromagnetic-therapy',
    label: 'Electromagnetic Therapy',
    value: 'electromagnetic-therapy',
  },
  {
    key: 'energy-medicine',
    label: 'Energy Medicine',
    value: 'energy-medicine',
  },
  {
    key: 'reiki',
    label: 'Reiki',
    value: 'reiki',
  },
  {
    key: 'rolfing',
    label: 'Rolfing',
    value: 'rolfing',
  },
  {
    key: 'sound-healing',
    label: 'Sound Healing',
    value: 'sound-healing',
  },
];

export const esotericAndIntuitiveModalities = [
  {
    key: '13th-octave-lahochi-healing',
    label: '13th Octave LaHoChi Healing',
    value: '13th-octave-lahochi-healing',
  },
  {
    key: 'akashic-record-consulting',
    label: 'Akashic Record Consulting',
    value: 'akashic-record-consulting',
  },
  {
    key: 'animal-communication',
    label: 'Animal Communication',
    value: 'animal-communication',
  },
  {
    key: 'astrology',
    label: 'Astrology',
    value: 'astrology',
  },
  {
    key: 'coffee-reading',
    label: 'Coffee Reading',
    value: 'coffee-reading',
  },
  {
    key: 'human-design-reading',
    label: 'Human Design Reading',
    value: 'human-design-reading',
  },
  {
    key: 'inner-child-healing',
    label: 'Inner Child Healing',
    value: 'inner-child-healing',
  },
  {
    key: 'intergalactic-soul-retrieval',
    label: 'Intergalactic Soul Retrieval',
    value: 'intergalactic-soul-retrieval',
  },
  {
    key: 'numerology',
    label: 'Numerology',
    value: 'numerology',
  },
  {
    key: 'palm-reading',
    label: 'Palm Reading',
    value: 'palm-reading',
  },
  {
    key: 'past-life-regression',
    label: 'Past-life Regression',
    value: 'past-life-regression',
  },
  {
    key: 'psychic--mediumship',
    label: 'Psychic / Mediumship',
    value: 'psychic--mediumship',
  },
  {
    key: 'tarot-reading',
    label: 'Tarot Reading',
    value: 'tarot-reading',
  },
  {
    key: 'tea-reading',
    label: 'Tea Reading',
    value: 'tea-reading',
  },
];

export const mediationModalities = [
  {
    key: 'breathwork',
    label: 'Breathwork',
    value: 'breathwork',
  },
  {
    key: 'guided-meditation',
    label: 'Guided Meditation',
    value: 'guided-meditation',
  },
  {
    key: 'loving-kindness-meditation',
    label: 'Loving Kindness Meditation',
    value: 'loving-kindness-meditation',
  },
  {
    key: 'meditation',
    label: 'Meditation',
    value: 'meditation',
  },
  {
    key: 'mindfulness-meditation',
    label: 'Mindfulness Meditation',
    value: 'mindfulness-meditation',
  },
  {
    key: 'transcendental-meditation',
    label: 'Transcendental Meditation',
    value: 'transcendental-meditation',
  },
  {
    key: 'vipassana-meditation',
    label: 'Vipassana Meditation',
    value: 'vipassana-meditation',
  },
  {
    key: 'yoga-meditation',
    label: 'Yoga Meditation',
    value: 'yoga-meditation',
  },
];

export const ritualAndSpiritualWorkModalities = [
  {
    key: 'brujeria',
    label: 'Brujeria',
    value: 'brujeria',
  },
  {
    key: 'candomblé',
    label: 'Candomblé',
    value: 'candomblé',
  },
  {
    key: 'celebrancy',
    label: 'Celebrancy',
    value: 'celebrancy',
  },
  {
    key: 'chaplaincy',
    label: 'Chaplaincy',
    value: 'chaplaincy',
  },
  {
    key: 'curandera',
    label: 'Curandera',
    value: 'curandera',
  },
  {
    key: 'ifá--babaaláwo',
    label: 'Ifá / Babaaláwo',
    value: 'ifá--babaaláwo',
  },
  {
    key: 'imam',
    label: 'Imam',
    value: 'imam',
  },
  {
    key: 'kohanot--jewish-priestess',
    label: 'Kohanot / Jewish Priestess',
    value: 'kohanot--jewish-priestess',
  },
  {
    key: 'limpia',
    label: 'Limpia',
    value: 'limpia',
  },
  {
    key: 'ministry',
    label: 'Ministry',
    value: 'ministry',
  },
  {
    key: 'orishas',
    label: 'Orishas',
    value: 'orishas',
  },
  {
    key: 'rabbi',
    label: 'Rabbi',
    value: 'rabbi',
  },
  {
    key: 'ritual-work',
    label: 'Ritual Work',
    value: 'ritual-work',
  },
  {
    key: 'shamanic',
    label: 'Shamanic',
    value: 'shamanic',
  },
  {
    key: 'shango',
    label: 'Shango',
    value: 'shango',
  },
  {
    key: 'siddha',
    label: 'Siddha',
    value: 'siddha',
  },
  {
    key: 'spirit-work',
    label: 'Spirit Work',
    value: 'spirit-work',
  },
  {
    key: 'sweat-lodges',
    label: 'Sweat Lodges',
    value: 'sweat-lodges',
  },
  {
    key: 'tattooing',
    label: 'Tattooing',
    value: 'tattooing',
  },
  {
    key: 'umbanda',
    label: 'Umbanda',
    value: 'umbanda',
  },
  {
    key: 'witchcraft--spell-work',
    label: 'Witchcraft / Spell-work',
    value: 'witchcraft--spell-work',
  },
  {
    key: 'yemaya',
    label: 'Yemaya',
    value: 'yemaya',
  },
];

export const westernMedicineModalities = [
  {
    key: 'art-therapy',
    label: 'Art Therapy',
    value: 'art-therapy',
  },
  {
    key: 'chiropractic',
    label: 'Chiropractic',
    value: 'chiropractic',
  },
  {
    key: 'emdr-therapy',
    label: 'EMDR Therapy',
    value: 'emdr-therapy',
  },
  {
    key: 'equine-therapy',
    label: 'Equine Therapy',
    value: 'equine-therapy',
  },
  {
    key: 'gynecology',
    label: 'Gynecology',
    value: 'gynecology',
  },
  {
    key: 'hypnotherapy',
    label: 'Hypnotherapy',
    value: 'hypnotherapy',
  },
  {
    key: 'medical-doctor',
    label: 'Medical Doctor',
    value: 'medical-doctor',
  },
  {
    key: 'midwifery',
    label: 'Midwifery',
    value: 'midwifery',
  },
  {
    key: 'nursing',
    label: 'Nursing',
    value: 'nursing',
  },
  {
    key: 'nutrition',
    label: 'Nutrition',
    value: 'nutrition',
  },
  {
    key: 'physical-therapy',
    label: 'Physical Therapy',
    value: 'physical-therapy',
  },
  {
    key: 'psychology',
    label: 'Psychology',
    value: 'psychology',
  },
  {
    key: 'psychotherapy-counseling',
    label: 'Psychotherapy / Counseling',
    value: 'psychotherapy-counseling',
  },
  {
    key: 'registered-nursing',
    label: 'Registered Nursing',
    value: 'registered-nursing',
  },
  {
    key: 'sex-therapy',
    label: 'Sex Therapy',
    value: 'sex-therapy',
  },
  {
    key: 'social-work',
    label: 'Social Work',
    value: 'social-work',
  },
  {
    key: 'somatic-therapy',
    label: 'Somatic Therapy',
    value: 'somatic-therapy',
  },
  // {
  //   key: 'talk-therapy',
  //   label: 'Talk Therapy',
  //   value: 'talk-therapy',
  // },
];

export const allModalities = [
  ...alternativeMedicineModalities,
  ...bodyWorkModalities,
  ...coachingModalities,
  ...doulaModalities,
  ...energyWorkModalities,
  ...esotericAndIntuitiveModalities,
  ...mediationModalities,
  ...ritualAndSpiritualWorkModalities,
  ...westernMedicineModalities,
];

const mapperToModType = (items, label) => items.map(item => ({ ...item, modType: label }));

export const filters = [
  {
    id: 'virtualSession',
    label: 'src.marketplace-custom-config.virtualSession',
    type: 'SelectSingleFilter',
    group: 'primary',
    singleFilter: true,
    queryParamNames: ['pub_virtualSession'],
    config: {
      options: [...virtualSessionOptions],
    },
  },
  {
    id: 'dates-length',
    label: 'src.marketplace-custom-config.dateFilterLabel',
    type: 'BookingDateRangeLengthFilter',
    group: 'primary',
    // Note: BookingDateRangeFilter is fixed filter,
    // you can't change "queryParamNames: ['dates'],"
    queryParamNames: ['dates', 'minDuration'],
    config: {
      // A global time zone to use in availability searches. As listings
      // can be in various time zones, we must decide what time zone we
      // use in search when looking for available listings within a
      // certain time interval.
      //
      // If you have all/most listings in a certain time zone, change this
      // config value to that.
      //
      // See: https://en.wikipedia.org/wiki/List_of_tz_database_time_zones
      searchTimeZone: 'Etc/UTC',

      // Options for the minimum duration of the booking
      options: [
        { key: '0', label: 'Any length' },
        { key: '60', label: '1 hour', shortLabel: '1h' },
        { key: '120', label: '2 hours', shortLabel: '2h' },
      ],
    },
  },
  // {
  //   id: 'price',
  //   label: 'Price',
  //   type: 'PriceFilter',
  //   group: 'primary',
  //   // Note: PriceFilter is fixed filter,
  //   // you can't change "queryParamNames: ['price'],"
  //   queryParamNames: ['price'],
  //   // Price filter configuration
  //   // Note: unlike most prices this is not handled in subunits
  //   config: {
  //     min: 0,
  //     max: 1000,
  //     step: 5,
  //   },
  // },
  // {
  //   id: 'keyword',
  //   label: 'Keyword',
  //   type: 'KeywordFilter',
  //   group: 'primary',
  //   // Note: KeywordFilter is fixed filter,
  //   // you can't change "queryParamNames: ['keywords'],"
  //   queryParamNames: ['keywords'],
  //   // NOTE: If you are ordering search results by distance
  //   // the keyword search can't be used at the same time.
  //   // You can turn on/off ordering by distance from config.js file.
  //   config: {},
  // },

  {
    id: 'identities',
    label: 'src.marketplace-custom-config.identitiesFilterLabel',
    type: 'SelectMultipleFilter',
    group: 'secondary',
    queryParamNames: ['pub_identities'],
    config: {
      searchMode: 'has_any',
      options: [
        ...mapperToModType(backgroundIdentities, 'src.marketplace-custom-config.background'),
        ...mapperToModType(
          classBackgroundIdentity,
          'src.marketplace-custom-config.classBackground'
        ),
        ...mapperToModType(disabilitiesIdentity, 'src.marketplace-custom-config.disabilities'),
        ...mapperToModType(genderIdentities, 'src.marketplace-custom-config.gender'),
        ...mapperToModType(miscIdentities, 'src.marketplace-custom-config.livedExperience'),

        ...mapperToModType(sexualityIdentities, 'src.marketplace-custom-config.sexuality'),
        ...mapperToModType(spiritualityIdentities, 'src.marketplace-custom-config.spirituality'),
      ],
    },
  },
  {
    id: 'modalities',
    label: 'src.marketplace-custom-config.modalitiesFilterLabel',
    type: 'SelectMultipleFilter',
    group: 'secondary',
    queryParamNames: ['pub_modalities'],
    config: {
      searchMode: 'has_any',
      options: [
        ...mapperToModType(
          alternativeMedicineModalities,
          'src.marketplace-custom-config.alternativeMedicine'
        ),
        ...mapperToModType(bodyWorkModalities, 'src.marketplace-custom-config.BodyWork'),
        ...mapperToModType(coachingModalities, 'src.marketplace-custom-config.Coaching'),
        ...mapperToModType(doulaModalities, 'src.marketplace-custom-config.Doula'),
        ...mapperToModType(energyWorkModalities, 'src.marketplace-custom-config.EnergyWork'),
        ...mapperToModType(
          esotericAndIntuitiveModalities,
          'src.marketplace-custom-config.Esoteric&Intuitive'
        ),
        ...mapperToModType(mediationModalities, 'src.marketplace-custom-config.Meditation'),
        ...mapperToModType(
          ritualAndSpiritualWorkModalities,
          'src.marketplace-custom-config.ritual&SpiritualWork'
        ),
        ...mapperToModType(
          westernMedicineModalities,
          'src.marketplace-custom-config.westernMedicine'
        ),
      ],
    },
  },

  // {
  //   id: 'featured_identities',
  //   label: 'Featured Identities',
  //   type: 'SelectMultipleFilter',
  //   group: 'secondary',
  //   queryParamNames: ['pub_featured_identities'],
  //   config: {
  //     searchMode: 'has_any',
  //     options: allIdentities.map(v => ({ ...v, modType: 'Featured Identities' })),
  //   },
  // },
  // {
  //   id: 'background_identities',
  //   label: 'Background Identities',
  //   type: 'SelectMultipleFilter',
  //   group: 'secondary',
  //   queryParamNames: ['pub_background_identities'],
  //   config: {
  //     searchMode: 'has_any',
  //     options: backgroundIdentities.map(v => ({ ...v, modType: 'Background Identities' })),
  //   },
  // },

  // {
  //   id: 'disability_identities',
  //   label: 'Disability Identities',
  //   type: 'SelectMultipleFilter',
  //   group: 'secondary',
  //   queryParamNames: ['pub_disability_identities'],
  //   config: {
  //     searchMode: 'has_any',
  //     options: disabilitiesIdentity.map(v => ({ ...v, modType: 'Disabilities Identities' })),
  //   },
  // },
  // {
  //   id: 'gender_identities',
  //   label: 'Gender Identities',
  //   type: 'SelectMultipleFilter',
  //   group: 'secondary',
  //   queryParamNames: ['pub_gender_identities'],
  //   config: {
  //     searchMode: 'has_any',
  //     options: genderIdentities.map(v => ({ ...v, modType: 'Gender Identities' })),
  //   },
  // },
  // {
  //   id: 'sexuality_identities',
  //   label: 'Sexuality Identities',
  //   type: 'SelectMultipleFilter',
  //   group: 'secondary',
  //   queryParamNames: ['pub_sexuality_identities'],
  //   config: {
  //     searchMode: 'has_any',
  //     options: sexualityIdentities.map(v => ({ ...v, modType: 'Sexuality Identities' })),
  //   },
  // },
  // {
  //   id: 'spirituality_identities',
  //   label: 'Spirituality Identities',
  //   type: 'SelectMultipleFilter',
  //   group: 'secondary',
  //   queryParamNames: ['pub_spirituality_identities'],
  //   config: {
  //     searchMode: 'has_any',
  //     options: spiritualityIdentities.map(v => ({ ...v, modType: 'Spirituality Identities' })),
  //   },
  // },
  // {
  //   id: 'misc_identities',
  //   label: 'Miscellaneous Identities',
  //   type: 'SelectMultipleFilter',
  //   group: 'secondary',
  //   queryParamNames: ['pub_misc_identities'],
  //   config: {
  //     searchMode: 'has_any',
  //     options: miscIdentities.map(v => ({ ...v, modType: 'Miscellaneous Identities' })),
  //   },
  // },
  // {
  //   id: 'certificate',
  //   label: 'Certificate',
  //   type: 'SelectSingleFilter',
  //   group: 'secondary',
  //   queryParamNames: ['pub_certificate'],
  //   config: {
  //     // "key" is the option you see in Flex Console.
  //     // "label" is set here for the UI only.
  //     // Note: label is not added through the translation files
  //     // to make filter customizations a bit easier.
  //     options: [
  //       { key: 'none', label: 'None', hideFromFilters: true, hideFromListingInfo: true },
  //       { key: '200h', label: 'Registered yoga teacher 200h' },
  //       { key: '500h', label: 'Registered yoga teacher 500h' },
  //     ],
  //   },
  // },
];

export const languages = [
  {
    label: 'Abkhazian',
    value: 'ab',
    key: 'ab',
  },
  {
    label: 'Afar',
    value: 'aa',
    key: 'aa',
  },
  {
    label: 'Afrikaans',
    value: 'af',
    key: 'af',
  },
  {
    label: 'Akan',
    value: 'ak',
    key: 'ak',
  },
  {
    label: 'Albanian',
    value: 'sq',
    key: 'sq',
  },
  {
    label: 'Amharic',
    value: 'am',
    key: 'am',
  },
  {
    label: 'Arabic',
    value: 'ar',
    key: 'ar',
  },
  {
    label: 'Aragonese',
    value: 'an',
    key: 'an',
  },
  {
    label: 'Armenian',
    value: 'hy',
    key: 'hy',
  },
  {
    label: 'Assamese',
    value: 'as',
    key: 'as',
  },
  {
    label: 'Avaric',
    value: 'av',
    key: 'av',
  },
  {
    label: 'Avestan',
    value: 'ae',
    key: 'ae',
  },
  {
    label: 'Aymara',
    value: 'ay',
    key: 'ay',
  },
  {
    label: 'Azerbaijani',
    value: 'az',
    key: 'az',
  },
  {
    label: 'Bambara',
    value: 'bm',
    key: 'bm',
  },
  {
    label: 'Bashkir',
    value: 'ba',
    key: 'ba',
  },
  {
    label: 'Basque',
    value: 'eu',
    key: 'eu',
  },
  {
    label: 'Belarusian',
    value: 'be',
    key: 'be',
  },
  {
    label: 'Bengali',
    value: 'bn',
    key: 'bn',
  },
  {
    label: 'Bihari languages',
    value: 'bh',
    key: 'bh',
  },
  {
    label: 'Bislama',
    value: 'bi',
    key: 'bi',
  },
  {
    label: 'Bosnian',
    value: 'bs',
    key: 'bs',
  },
  {
    label: 'Breton',
    value: 'br',
    key: 'br',
  },
  {
    label: 'Bulgarian',
    value: 'bg',
    key: 'bg',
  },
  {
    label: 'Burmese',
    value: 'my',
    key: 'my',
  },
  {
    label: 'Catalan, Valencian',
    value: 'ca',
    key: 'ca',
  },
  {
    label: 'Central Khmer',
    value: 'km',
    key: 'km',
  },
  {
    label: 'Chamorro',
    value: 'ch',
    key: 'ch',
  },
  {
    label: 'Chechen',
    value: 'ce',
    key: 'ce',
  },
  {
    label: 'Chichewa, Chewa, Nyanja',
    value: 'ny',
    key: 'ny',
  },
  {
    label: 'Chinese',
    value: 'zh',
    key: 'zh',
  },
  {
    label: 'Church Slavonic, Old Bulgarian, Old Church Slavonic',
    value: 'cu',
    key: 'cu',
  },
  {
    label: 'Chuvash',
    value: 'cv',
    key: 'cv',
  },
  {
    label: 'Cornish',
    value: 'kw',
    key: 'kw',
  },
  {
    label: 'Corsican',
    value: 'co',
    key: 'co',
  },
  {
    label: 'Cree',
    value: 'cr',
    key: 'cr',
  },
  {
    label: 'Croatian',
    value: 'hr',
    key: 'hr',
  },
  {
    label: 'Czech',
    value: 'cs',
    key: 'cs',
  },
  {
    label: 'Danish',
    value: 'da',
    key: 'da',
  },
  {
    label: 'Divehi, Dhivehi, Maldivian',
    value: 'dv',
    key: 'dv',
  },
  {
    label: 'Dutch, Flemish',
    value: 'nl',
    key: 'nl',
  },
  {
    label: 'Dzongkha',
    value: 'dz',
    key: 'dz',
  },
  {
    label: 'English',
    value: 'en',
    key: 'en',
  },
  {
    label: 'Esperanto',
    value: 'eo',
    key: 'eo',
  },
  {
    label: 'Estonian',
    value: 'et',
    key: 'et',
  },
  {
    label: 'Ewe',
    value: 'ee',
    key: 'ee',
  },
  {
    label: 'Faroese',
    value: 'fo',
    key: 'fo',
  },
  {
    label: 'Fijian',
    value: 'fj',
    key: 'fj',
  },
  {
    label: 'Finnish',
    value: 'fi',
    key: 'fi',
  },
  {
    label: 'French',
    value: 'fr',
    key: 'fr',
  },
  {
    label: 'Fulah',
    value: 'ff',
    key: 'ff',
  },
  {
    label: 'Gaelic, Scottish Gaelic',
    value: 'gd',
    key: 'gd',
  },
  {
    label: 'Galician',
    value: 'gl',
    key: 'gl',
  },
  {
    label: 'Ganda',
    value: 'lg',
    key: 'lg',
  },
  {
    label: 'Georgian',
    value: 'ka',
    key: 'ka',
  },
  {
    label: 'German',
    value: 'de',
    key: 'de',
  },
  {
    label: 'Gikuyu, Kikuyu',
    value: 'ki',
    key: 'ki',
  },
  {
    label: 'Greek (Modern)',
    value: 'el',
    key: 'el',
  },
  {
    label: 'Greenlandic, Kalaallisut',
    value: 'kl',
    key: 'kl',
  },
  {
    label: 'Guarani',
    value: 'gn',
    key: 'gn',
  },
  {
    label: 'Gujarati',
    value: 'gu',
    key: 'gu',
  },
  {
    label: 'Haitian, Haitian Creole',
    value: 'ht',
    key: 'ht',
  },
  {
    label: 'Hausa',
    value: 'ha',
    key: 'ha',
  },
  {
    label: 'Hebrew',
    value: 'he',
    key: 'he',
  },
  {
    label: 'Herero',
    value: 'hz',
    key: 'hz',
  },
  {
    label: 'Hindi',
    value: 'hi',
    key: 'hi',
  },
  {
    label: 'Hiri Motu',
    value: 'ho',
    key: 'ho',
  },
  {
    label: 'Hungarian',
    value: 'hu',
    key: 'hu',
  },
  {
    label: 'Icelandic',
    value: 'is',
    key: 'is',
  },
  {
    label: 'Ido',
    value: 'io',
    key: 'io',
  },
  {
    label: 'Igbo',
    value: 'ig',
    key: 'ig',
  },
  {
    label: 'Indonesian',
    value: 'id',
    key: 'id',
  },
  {
    label: 'Interlingua (International Auxiliary Language Association)',
    value: 'ia',
    key: 'ia',
  },
  {
    label: 'Interlingue',
    value: 'ie',
    key: 'ie',
  },
  {
    label: 'Inuktitut',
    value: 'iu',
    key: 'iu',
  },
  {
    label: 'Inupiaq',
    value: 'ik',
    key: 'ik',
  },
  {
    label: 'Irish',
    value: 'ga',
    key: 'ga',
  },
  {
    label: 'Italian',
    value: 'it',
    key: 'it',
  },
  {
    label: 'Japanese',
    value: 'ja',
    key: 'ja',
  },
  {
    label: 'Javanese',
    value: 'jv',
    key: 'jv',
  },
  {
    label: 'Kannada',
    value: 'kn',
    key: 'kn',
  },
  {
    label: 'Kanuri',
    value: 'kr',
    key: 'kr',
  },
  {
    label: 'Kashmiri',
    value: 'ks',
    key: 'ks',
  },
  {
    label: 'Kazakh',
    value: 'kk',
    key: 'kk',
  },
  {
    label: 'Kinyarwanda',
    value: 'rw',
    key: 'rw',
  },
  {
    label: 'Komi',
    value: 'kv',
    key: 'kv',
  },
  {
    label: 'Kongo',
    value: 'kg',
    key: 'kg',
  },
  {
    label: 'Korean',
    value: 'ko',
    key: 'ko',
  },
  {
    label: 'Kwanyama, Kuanyama',
    value: 'kj',
    key: 'kj',
  },
  {
    label: 'Kurdish',
    value: 'ku',
    key: 'ku',
  },
  {
    label: 'Kyrgyz',
    value: 'ky',
    key: 'ky',
  },
  {
    label: 'Lao',
    value: 'lo',
    key: 'lo',
  },
  {
    label: 'Latin',
    value: 'la',
    key: 'la',
  },
  {
    label: 'Latvian',
    value: 'lv',
    key: 'lv',
  },
  {
    label: 'Letzeburgesch, Luxembourgish',
    value: 'lb',
    key: 'lb',
  },
  {
    label: 'Limburgish, Limburgan, Limburger',
    value: 'li',
    key: 'li',
  },
  {
    label: 'Lingala',
    value: 'ln',
    key: 'ln',
  },
  {
    label: 'Lithuanian',
    value: 'lt',
    key: 'lt',
  },
  {
    label: 'Luba-Katanga',
    value: 'lu',
    key: 'lu',
  },
  {
    label: 'Macedonian',
    value: 'mk',
    key: 'mk',
  },
  {
    label: 'Malagasy',
    value: 'mg',
    key: 'mg',
  },
  {
    label: 'Malay',
    value: 'ms',
    key: 'ms',
  },
  {
    label: 'Malayalam',
    value: 'ml',
    key: 'ml',
  },
  {
    label: 'Maltese',
    value: 'mt',
    key: 'mt',
  },
  {
    label: 'Manx',
    value: 'gv',
    key: 'gv',
  },
  {
    label: 'Maori',
    value: 'mi',
    key: 'mi',
  },
  {
    label: 'Marathi',
    value: 'mr',
    key: 'mr',
  },
  {
    label: 'Marshallese',
    value: 'mh',
    key: 'mh',
  },
  {
    label: 'Moldovan, Moldavian, Romanian',
    value: 'ro',
    key: 'ro',
  },
  {
    label: 'Mongolian',
    value: 'mn',
    key: 'mn',
  },
  {
    label: 'Nauru',
    value: 'na',
    key: 'na',
  },
  {
    label: 'Navajo, Navaho',
    value: 'nv',
    key: 'nv',
  },
  {
    label: 'Northern Ndebele',
    value: 'nd',
    key: 'nd',
  },
  {
    label: 'Ndonga',
    value: 'ng',
    key: 'ng',
  },
  {
    label: 'Nepali',
    value: 'ne',
    key: 'ne',
  },
  {
    label: 'Northern Sami',
    value: 'se',
    key: 'se',
  },
  {
    label: 'Norwegian',
    value: 'no',
    key: 'no',
  },
  {
    label: 'Norwegian Bokmål',
    value: 'nb',
    key: 'nb',
  },
  {
    label: 'Norwegian Nynorsk',
    value: 'nn',
    key: 'nn',
  },
  {
    label: 'Nuosu, Sichuan Yi',
    value: 'ii',
    key: 'ii',
  },
  {
    label: 'Occitan (post 1500)',
    value: 'oc',
    key: 'oc',
  },
  {
    label: 'Ojibwa',
    value: 'oj',
    key: 'oj',
  },
  {
    label: 'Oriya',
    value: 'or',
    key: 'or',
  },
  {
    label: 'Oromo',
    value: 'om',
    key: 'om',
  },
  {
    label: 'Ossetian, Ossetic',
    value: 'os',
    key: 'os',
  },
  {
    label: 'Pali',
    value: 'pi',
    key: 'pi',
  },
  {
    label: 'Panjabi, Punjabi',
    value: 'pa',
    key: 'pa',
  },
  {
    label: 'Pashto, Pushto',
    value: 'ps',
    key: 'ps',
  },
  {
    label: 'Persian',
    value: 'fa',
    key: 'fa',
  },
  {
    label: 'Polish',
    value: 'pl',
    key: 'pl',
  },
  {
    label: 'Portuguese',
    value: 'pt',
    key: 'pt',
  },
  {
    label: 'Quechua',
    value: 'qu',
    key: 'qu',
  },
  {
    label: 'Romansh',
    value: 'rm',
    key: 'rm',
  },
  {
    label: 'Rundi',
    value: 'rn',
    key: 'rn',
  },
  {
    label: 'Russian',
    value: 'ru',
    key: 'ru',
  },
  {
    label: 'Samoan',
    value: 'sm',
    key: 'sm',
  },
  {
    label: 'Sango',
    value: 'sg',
    key: 'sg',
  },
  {
    label: 'Sanskrit',
    value: 'sa',
    key: 'sa',
  },
  {
    label: 'Sardinian',
    value: 'sc',
    key: 'sc',
  },
  {
    label: 'Serbian',
    value: 'sr',
    key: 'sr',
  },
  {
    label: 'Shona',
    value: 'sn',
    key: 'sn',
  },
  {
    label: 'Sindhi',
    value: 'sd',
    key: 'sd',
  },
  {
    label: 'Sinhala, Sinhalese',
    value: 'si',
    key: 'si',
  },
  {
    label: 'Slovak',
    value: 'sk',
    key: 'sk',
  },
  {
    label: 'Slovenian',
    value: 'sl',
    key: 'sl',
  },
  {
    label: 'Somali',
    value: 'so',
    key: 'so',
  },
  {
    label: 'Sotho, Southern',
    value: 'st',
    key: 'st',
  },
  {
    label: 'South Ndebele',
    value: 'nr',
    key: 'nr',
  },
  {
    label: 'Spanish, Castilian',
    value: 'es',
    key: 'es',
  },
  {
    label: 'Sundanese',
    value: 'su',
    key: 'su',
  },
  {
    label: 'Swahili',
    value: 'sw',
    key: 'sw',
  },
  {
    label: 'Swati',
    value: 'ss',
    key: 'ss',
  },
  {
    label: 'Swedish',
    value: 'sv',
    key: 'sv',
  },
  {
    label: 'Tagalog',
    value: 'tl',
    key: 'tl',
  },
  {
    label: 'Tahitian',
    value: 'ty',
    key: 'ty',
  },
  {
    label: 'Tajik',
    value: 'tg',
    key: 'tg',
  },
  {
    label: 'Tamil',
    value: 'ta',
    key: 'ta',
  },
  {
    label: 'Tatar',
    value: 'tt',
    key: 'tt',
  },
  {
    label: 'Telugu',
    value: 'te',
    key: 'te',
  },
  {
    label: 'Thai',
    value: 'th',
    key: 'th',
  },
  {
    label: 'Tibetan',
    value: 'bo',
    key: 'bo',
  },
  {
    label: 'Tigrinya',
    value: 'ti',
    key: 'ti',
  },
  {
    label: 'Tonga (Tonga Islands)',
    value: 'to',
    key: 'to',
  },
  {
    label: 'Tsonga',
    value: 'ts',
    key: 'ts',
  },
  {
    label: 'Tswana',
    value: 'tn',
    key: 'tn',
  },
  {
    label: 'Turkish',
    value: 'tr',
    key: 'tr',
  },
  {
    label: 'Turkmen',
    value: 'tk',
    key: 'tk',
  },
  {
    label: 'Twi',
    value: 'tw',
    key: 'tw',
  },
  {
    label: 'Uighur, Uyghur',
    value: 'ug',
    key: 'ug',
  },
  {
    label: 'Ukrainian',
    value: 'uk',
    key: 'uk',
  },
  {
    label: 'Urdu',
    value: 'ur',
    key: 'ur',
  },
  {
    label: 'Uzbek',
    value: 'uz',
    key: 'uz',
  },
  {
    label: 'Venda',
    value: 've',
    key: 've',
  },
  {
    label: 'Vietnamese',
    value: 'vi',
    key: 'vi',
  },
  {
    label: 'Volap_k',
    value: 'vo',
    key: 'vo',
  },
  {
    label: 'Walloon',
    value: 'wa',
    key: 'wa',
  },
  {
    label: 'Welsh',
    value: 'cy',
    key: 'cy',
  },
  {
    label: 'Western Frisian',
    value: 'fy',
    key: 'fy',
  },
  {
    label: 'Wolof',
    value: 'wo',
    key: 'wo',
  },
  {
    label: 'Xhosa',
    value: 'xh',
    key: 'xh',
  },
  {
    label: 'Yiddish',
    value: 'yi',
    key: 'yi',
  },
  {
    label: 'Yoruba',
    value: 'yo',
    key: 'yo',
  },
  {
    label: 'Zhuang, Chuang',
    value: 'za',
    key: 'za',
  },
  {
    label: 'Zulu',
    value: 'zu',
    key: 'zu',
  },
];

export const sortConfig = {
  // Enable/disable the sorting control in the SearchPage
  active: true,

  // Note: queryParamName 'sort' is fixed,
  // you can't change it since Flex API expects it to be named as 'sort'
  queryParamName: 'sort',

  // Internal key for the relevance option, see notes below.
  relevanceKey: 'relevance',

  // Keyword filter is sorting the results already by relevance.
  // If keyword filter is active, we need to disable sorting.
  conflictingFilters: ['keyword'],

  options: [
    { key: 'createdAt', label: 'Newest' },
    { key: '-createdAt', label: 'Oldest' },
    // { key: '-price', label: 'Lowest price' },
    { key: '-pub_minPrice', label: 'Lowest price' },
    // { key: 'price', label: 'Highest price' },
    { key: 'pub_minPrice', label: 'Highest price' },

    // The relevance is only used for keyword search, but the
    // parameter isn't sent to the Marketplace API. The key is purely
    // for handling the internal state of the sorting dropdown.
    // { key: 'relevance', label: 'Relevance', longLabel: 'Relevance (Keyword search)' },
  ],
};
