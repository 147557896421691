import React, { useState } from 'react';
import { FormattedMessage, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import { ActivityFeed, Modal } from '../../components';

import css from './TransactionPanel.module.css';
import { SecondaryButton } from '../Button/Button';
import InfoRow from './InfoRow';
import config from '../../config';
import { ensureListing } from '../../util/data';

// Functional component as a helper to build ActivityFeed section
const FeedSection = props => {
  const {
    className,
    rootClassName,
    currentTransaction,
    currentUser,
    fetchMessagesError,
    fetchMessagesInProgress,
    initialMessageFailed,
    messages,
    oldestMessagePageFetched,
    onShowMoreMessages,
    onOpenReviewModal,
    totalMessagePages,
    onManageDisableScrolling,
    intl,
    isCustomer,
  } = props;
  const [isOpen, setIsOpen] = useState(false);

  const txTransitions = currentTransaction.attributes.transitions
    ? currentTransaction.attributes.transitions
    : [];
  const hasOlderMessages = totalMessagePages > oldestMessagePageFetched;

  const showFeed =
    messages.length > 0 || txTransitions.length > 0 || initialMessageFailed || fetchMessagesError;

  const classes = classNames(rootClassName || css.feedContainer, className);

  const selectedOffering = currentTransaction?.attributes?.protectedData?.selectedOffering ?? null;
  const onOpenModal = () => setIsOpen(true);
  const onCloseModal = () => setIsOpen(false);
  // console.log(selectedOffering);
  const sessionFormatValue = Array.isArray(selectedOffering?.session_format_type)
    ? selectedOffering?.session_format_type
        ?.map(v => config.custom.offeringSessionFormatOptions.find(k => k.key == v)?.label)
        ?.join(', ')
    : selectedOffering?.session_format_type;

  const { start, end } = currentTransaction?.booking?.attributes || {};
  const loaded =
    currentTransaction &&
    currentTransaction.id &&
    currentTransaction.booking &&
    currentTransaction.booking.id;
  const listingAttributes = ensureListing(currentTransaction.listing).attributes;
  const timeZone =
    loaded && listingAttributes.availabilityPlan
      ? listingAttributes.availabilityPlan.timezone
      : 'Etc/UTC';

  const timeZoneMaybe = timeZone ? { timeZone } : {};

  const OfferingModal = (
    <Modal
      id="Offering_Details_Modal"
      name="Offering_Details_Modal"
      isOpen={isOpen}
      onClose={onCloseModal}
      onManageDisableScrolling={onManageDisableScrolling}
      usePortal
    >
      <div className={css.contentClass}>
        <h3 className={css.offeringTitle}> {selectedOffering?.offering_title} </h3>
        <p className={css.offeringDesc}>{selectedOffering?.offering_description} </p>

        <InfoRow
          heading={intl.formatMessage({ id: 'TransactionPanel.FeedSection.sessionDateLabel' })}
          value={start}
          date
          timeZoneMaybe={timeZoneMaybe}
        />
        <InfoRow
          heading={intl.formatMessage({ id: 'TransactionPanel.FeedSection.sessionStartTimeLabel' })}
          value={start}
          time
          timeZoneMaybe={timeZoneMaybe}
        />
        <InfoRow
          heading={intl.formatMessage({ id: 'TransactionPanel.FeedSection.sessionEndTimeLabel' })}
          value={end}
          time
          timeZoneMaybe={timeZoneMaybe}
        />
        <InfoRow
          heading={intl.formatMessage({ id: 'TransactionPanel.FeedSection.offeringFormatsLabel' })}
          value={sessionFormatValue}
        />

        <InfoRow
          heading={intl.formatMessage({ id: 'TransactionPanel.FeedSection.sessionOfferingsLabel' })}
          value={(selectedOffering?.offering_style ?? [])
            .map(v => config.custom.allModalities.find(k => k.key == v)?.label)
            .join(', ')}
        />

        <InfoRow
          heading={intl.formatMessage({ id: 'TransactionPanel.FeedSection.sessionLocationLabel' })}
          value={(selectedOffering.session_location ?? [])
            .map(v => config.custom.sessionLocations.find(p => p.key == v)?.label)
            .join(', ')}
        />

        <InfoRow
          heading={intl.formatMessage({
            id: 'TransactionPanel.FeedSection.sessionTimeDurationlabel',
          })}
          value={selectedOffering?.booking_time_availability + ' minutes'}
        />
        {/* {selectedOffering?.physical_location_address?.search ? (
          <InfoRow
            heading={'Physical Location Address'}
            value={selectedOffering?.physical_location_address?.search}
          />
        ) : null} */}
        <InfoRow
          heading={intl.formatMessage({
            id: 'TransactionPanel.FeedSection.sessionAccessibilityNotesLabel',
          })}
          value={selectedOffering?.offering_accessibility}
        />
      </div>
    </Modal>
  );

  return showFeed ? (
    <div className={classes}>
      {selectedOffering ? (
        <SecondaryButton type="button" onClick={onOpenModal} className={css.viewButton}>
          {intl.formatMessage({ id: 'TransactionPanel.FeedSection.sessionInformationButtonLabel' })}
        </SecondaryButton>
      ) : null}
      {OfferingModal}
      <h3 className={css.feedHeading}>
        <FormattedMessage id="TransactionPanel.activityHeading" />
      </h3>
      {initialMessageFailed ? (
        <p className={css.messageError}>
          <FormattedMessage id="TransactionPanel.initialMessageFailed" />
        </p>
      ) : null}
      {fetchMessagesError ? (
        <p className={css.messageError}>
          <FormattedMessage id="TransactionPanel.messageLoadingFailed" />
        </p>
      ) : null}
      <ActivityFeed
        className={css.feed}
        messages={messages}
        transaction={currentTransaction}
        currentUser={currentUser}
        hasOlderMessages={hasOlderMessages && !fetchMessagesInProgress}
        onOpenReviewModal={onOpenReviewModal}
        onShowOlderMessages={onShowMoreMessages}
        fetchMessagesInProgress={fetchMessagesInProgress}
        isCustomer={isCustomer}
      />
    </div>
  ) : null;
};

export default injectIntl(FeedSection);
