import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { NamedLink } from '../index';
import css from './TermsOfService.module.css';

const TermsOfService = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  // prettier-ignore
  return (
    <div className={classes}>
      <b className={css.subHeading}>Queer Healers Terms of Use</b>

      <p className={css.lastUpdated}>Last Updated: October 3, 2022</p>

      <p>
      The Queer Healers Terms of Use and any other terms or policies referenced in it (together, the “Terms”) are a legally binding contract between you and Queer Healers (“we” or “us”). By using the Queer Healers services, you agree to the Terms. If you do not agree to these Terms, please do not use the Queer Healers services.
      </p>


    <ol className={css.listContainer} >
      <li  className={css.listHeading} >
      <span style={{"marginRight":'0.8rem'}} >1.</span> Definitions

      </li>
      <li>
      <ul  className={css.subListContainer} >
      <li   className={css.subListHeading}  > <span style={{"marginRight":'0.4rem'}} >1.1.</span>Services</li>
      <p>
        The “Service” includes the Queer Healers website and any associated services, communications, and applications.
      </p>
      <li   className={css.subListHeading}  > <span style={{"marginRight":'0.4rem'}} >1.2.</span>Users</li>
      <p>
      A “User” is any person who uses the Service, regardless of whether they register for a Queer Healers account.
       </p>
      <li   className={css.subListHeading}  > <span style={{"marginRight":'0.4rem'}} >1.3.</span>Healers</li>
      <p>
      A “Healer” is any User who registers for a Queer Healers account and creates a Healer profile, regardless of whether they actually do business through the Service.
      </p>
      <li   className={css.subListHeading}  > <span style={{"marginRight":'0.4rem'}} >1.4.</span>Content</li>
      <p>
      “Content” is any form of text, images, videos, or audio transmitted through the site, whether to a specific User or to Users generally.
      </p>
      <li   className={css.subListHeading}  > <span style={{"marginRight":'0.4rem'}} >1.5.</span>Service Content
      </li>
      <p>
      “Service Content” is any Content created by Queer Healers or its licensors in connection with the Service, including but not limited to text, graphics, trademark and trade names, user interface elements, and computer code.
    </p>
      <li   className={css.subListHeading}  > <span style={{"marginRight":'0.4rem'}} >1.6.</span>User Generated Content
    </li>
      <p>
      “User Generated Content” is any Content that is created on, uploaded to, or transmitted through the Service by a User.
      </p>
      </ul>
      </li>
    </ol>

    <ol className={css.listContainer} >
      <li  className={css.listHeading} >
      <span style={{"marginRight":'0.8rem'}} >2.</span> Using the Service
      </li>
      <li>
      <ul  className={css.subListContainer} >
      <li   className={css.subListHeading}  > <span style={{"marginRight":'0.4rem'}} >2.1.</span>Overview</li>
      <p>
      Queer Healers operates the Service, which has been established to facilitate connections between Users and Healers for a wide range of services. The Service allows Users to find Healers that align with their identities, preferences, and needs. The Service does not, and is not intended to, provide medical advice. </p>
      <p>
      Claims by Healers have not been evaluated by the United States Food and Drug Administration or any other body. Use of the Service is not a substitute for professional medical advice.
</p>
      <p>
      By agreeing to these Terms, you understand and agree that Healers are not employees, agents, or contractors of Queer Healers and that we do not supervise or control the provision of services by Healers.
 </p> 
 <p>
 These Terms govern the use of the Service and the facilitation of connections between Users and Healers. Except as specifically provided, these Terms do not govern business between a User and a Healer. Such business may be subject to its own legal and contractual provisions.

 </p>
      <li   className={css.subListHeading}  > <span style={{"marginRight":'0.4rem'}} >2.2.</span>Age Requirement</li>
      <p>
      You must be at least 18 years old to use the Service, either as a User or a Healer. If you are not 18 years old, please do not use Queer Healers.
      </p>

      <li   className={css.subListHeading}  > <span style={{"marginRight":'0.4rem'}} >2.3.</span>Conditions of Use</li>
      <p>
      By using the Service, you represent that:
      </p>
      <ul>
            <NamedLink name="AboutPage"></NamedLink>
        <li style={{listStyleType:"square"}} >You have read and understand the Queer Healers <NamedLink name="PrivacyPolicyPage">Privacy Policy</NamedLink> .
</li>
        <li style={{listStyleType:"square"}} >You have read and understand the Queer Healers  <NamedLink name="AboutPage">Community Guidelines</NamedLink> .
</li>
        <li style={{listStyleType:"square"}} >You are legally able to enter into a contract with Queer Healers.
</li>
        <li style={{listStyleType:"square"}} >You have not previously had your account terminated on the Service or otherwise been prohibited from using the Service.
</li>
        <li style={{listStyleType:"square"}} >You will comply with all applicable local, state, national, and international laws while using the Service.
</li>

      </ul>
      <p>By using the Service, you also understand and agree that:
</p>  
<ul>
<li style={{listStyleType:"square"}} >Queer Healers may make changes to the Service that add or remove features.

</li>
<li style={{listStyleType:"square"}} >The Service may be interrupted or unavailable without notice or liability.

</li>
<li style={{listStyleType:"square"}} >Queer Healers does not control and is not liable for the availability of third-party services linked to by the Service, including Healer-specific services and payment processing services.

</li>
</ul>
      <li   className={css.subListHeading}  > <span style={{"marginRight":'0.4rem'}} >2.4.</span>Prohibited Uses of the Service
</li>
      <p>
      The purpose of the Service is to allow Users to find, communicate with, book appointments with, and send payments to Healers. Uses of the Service that are not related to this purpose are prohibited.
</p>
<p>
In addition, you agree not to:
 
</p>
<ul>
<li style={{listStyleType:"square"}} >Post or transmit any Content that violates our <NamedLink name="AboutPage">Community Guidelines</NamedLink> 
</li>
<li style={{listStyleType:"square"}} >Violate any User's or third party's legal rights, including their privacy and intellectual property rights.

</li>
<li style={{listStyleType:"square"}} >Solicit personal information from another User for any purpose other than providing services or payments for services.

</li>
<li style={{listStyleType:"square"}} >Impersonate any other person, including another User, Healers, or member of the Queer Healers team.

</li>
<li style={{listStyleType:"square"}} >Copy, modify, create derivative works of, or reverse engineer the Service or any Content provided through the Service (other than your own Content), except where specifically permitted by law.

</li>
<li style={{listStyleType:"square"}} >Embed, frame, reimplement, or otherwise redistribute the Service, including by removing Queer Healers’ trademarks or copyright notices from the Service.

</li> 
<li style={{listStyleType:"square"}} >Interfere with the proper working of the Service with denial-of-service attacks, viruses, Trojan horses, worms, or other harmful or malicious techniques.

</li> 
<li style={{listStyleType:"square"}} >Attempt to gain unauthorized access to any parts of the Service or any server, computer, or database connected to the Service.

</li> 
<li style={{listStyleType:"square"}} >Encourage or aid another person in violating these Terms.

</li> 
</ul>
      </ul>
      </li>
    </ol>
    <ol className={css.listContainer} >
      <li  className={css.listHeading} >
      <span style={{"marginRight":'0.8rem'}} >3.</span> Accounts
      </li>
      <li>
      <ul  className={css.subListContainer} >
      <li   className={css.subListHeading}  > <span style={{"marginRight":'0.4rem'}} >3.1.</span>Creating an Account
</li>
      <p>As part of using the Service, you may create an account. An account is required to access some parts of the Service, such as booking, payments, and reviews.
 </p>
      <p>
      Creating an account requires you to choose a password. Please keep your password confidential and do not share it with others. Queer Healers will never ask you for your password.
</p>
      <p>
      By creating an account, you agree to receive communications from Queer Healers about the Service. You can opt out of non-essential communications, including all promotional emails, through your email preferences.
</p> 

      <li   className={css.subListHeading}  > <span style={{"marginRight":'0.4rem'}} >3.2.</span>Account Termination</li>
      <p>
      You may terminate your account at any time by contacting Queer Healers via  <a target='_' href='mailto:help@queerhealers.com' >help@queerhealers.com</a> .</p>
      <p>We may terminate your account at any time for any reason, including but not limited to violations of these Terms.
</p>
      <li   className={css.subListHeading}  > <span style={{"marginRight":'0.4rem'}} >3.3.</span>Disclosure of Account Information
</li>
      <p>
      You account information is protected by national and international law and by the Queer Healers  <NamedLink name="PrivacyPolicyPage">Privacy Policy</NamedLink> .

      </p>
      
      

     

      </ul>
      </li>
    </ol>


    <ol className={css.listContainer} >
      <li  className={css.listHeading} >
      <span style={{"marginRight":'0.8rem'}} >4.</span> Content
      </li>
      <li>
      <ul  className={css.subListContainer} >
      <li   className={css.subListHeading}  > <span style={{"marginRight":'0.4rem'}} >4.1.</span>Service Content
</li>
      <p>
      All Service Content is owned or licensed by Queer Healers and is protected by national and international copyright, trademark, trade secret, and other intellectual property law.
 </p>
      <p>
      Pursuant to these Terms, we grant you a worldwide, royalty-free, non-exclusive, non-transferrable, revocable license to use the Service Content in connection with the Service. Except as permitted under these Terms, you may not reproduce, make available, perform, display, translate, modify, create derivative works from, distribute, and otherwise use Service Content.
</p>
     
      <li   className={css.subListHeading}  > <span style={{"marginRight":'0.4rem'}} >4.2.</span>User Generated Content
</li>
      <p>
      You are responsible for and retain ownership of any User-Generated Content you create, upload, or transmit using the Service. By creating, uploading, or transmitted User-Generated Content, you:
</p>
<ul>
        <li style={{listStyleType:"square"}} >Grant us a worldwide, royalty-free, non-exclusive, transferrable, irrevocable license to reproduce, make available, perform, display, translate, modify, create derivative works from, distribute, and otherwise use any such User-Generated Content.

</li>
        <li style={{listStyleType:"square"}} >Assume all risks related to such User-Generated Content, including the risk of liability if your content is defamatory or otherwise unlawful.

</li>
        <li style={{listStyleType:"square"}} >Represent that you have the necessary rights to grant the above license and waiver.

</li>


      </ul>
      <li   className={css.subListHeading}  > <span style={{"marginRight":'0.4rem'}} >4.3.</span>Content Moderation
</li>
      <p>
      We reserve the right to moderate any Content on the Service. This means may remove or limit access to your Content at any time for any reason, including but not limited to violation of these Terms or our <NamedLink name="AboutPage">Community Guidelines</NamedLink> .

      </p>
      
      <p>While we do our best to remove harmful User Generated Content, we cannot guarantee that we will be able to remove all offensive material.
</p>  

      <li   className={css.subListHeading}  > <span style={{"marginRight":'0.4rem'}} >4.4.</span>Copyright Infringement

</li>
      <p>
      If someone has posted User Generated Content that infringes on your copyright, please let us know by following the procedures in our <NamedLink name="DMCAPrivacyPolicyPage" >DMCA Notice and Takedown Policy</NamedLink> .
</p>


      </ul>
      </li>
    </ol>

    <ol className={css.listContainer} >
      <li  className={css.listHeading} >
      <span style={{"marginRight":'0.8rem'}} >5.</span> Booking and Payments

      </li>
      <li>
      <ul  className={css.subListContainer} >
      <li   className={css.subListHeading}  > <span style={{"marginRight":'0.4rem'}} >5.1.</span>Booking Process

</li>
      <p>
      The Service allows Users to request sessions directly with Practitioners. Using the Service to request a session creates a booking request. The Practitioner may then choose to accept or reject the request. If the Practitioner accepts the request, the User’s payment is processed in accordance with these Terms.
</p>
      <p>
      Except as provided in these Terms, all transactions and services between a User and a Practitioner are governed by the agreement, if any, in place between the User and the Practitioner at the time the booking is accepted. However, nothing in any such agreement will be understood to supersede these Terms.
</p>
     
      <li   className={css.subListHeading}  > <span style={{"marginRight":'0.4rem'}} >5.2.</span>Payment Processing

</li>
      <p>
      The Service allows for payment processing through Stripe, Inc. By using the Service, you represent that you have read and understand Stripe’s Privacy Policy.
</p>  <p>All payments for services performed in connection with the Service must be processed through Stripe. By using the Service, you agree not to offer or accept payment through any other means.
</p>
<p>Queer Healers makes no representation that payments for services performed in connection with the Service will be covered by any form of insurance. We will not file claims on your behalf or otherwise obtain reimbursement for payments made through the Service.
</p>

      <li   className={css.subListHeading}  > <span style={{"marginRight":'0.4rem'}} >5.3.</span>Cancellations, No Shows, and Refunds

</li>
      <p>
      All cancellations and no-shows are governed by the following policies, unless the User and the Practitioner agree to a different resolution:

      </p>
      <ul>
        <li style={{listStyleType:"square"}} >A User may cancel a booking request through the Service at any time before the request is accepted by the Practitioner. No refund is made in this case because no payment is processed until the request is accepted.

</li>
        <li style={{listStyleType:"square"}} >A User may cancel an accepted booking by contacting <a target='_' href='mailto:help@queerhealers.com' >help@queerhealers.com</a> any time more than 24 hours before the start of the booked service. The User’s payment, minus any applicable bank or processing fees, will be refunded and no payment will be made to the Practitioner for the canceled booking.

</li>
        <li style={{listStyleType:"square"}} >A User may cancel an accepted booking by contacting <a target='_' href='mailto:help@queerhealers.com' >help@queerhealers.com</a> less than 24 hours before the start of the booked service. The User’s payment will not be refunded and a standard payout will be made to the Practitioner for the canceled booking.

</li>
<li style={{listStyleType:"square"}} >A Practitioner may cancel an accepted booking by contacting <a target='_' href='mailto:help@queerhealers.com' >help@queerhealers.com</a> at any time. The User’s payment will be refunded in full and no payout will be made to the Practitioner for the canceled booking.

</li>
<li style={{listStyleType:"square"}} >If a Practitioner does not attend a session within 30 minutes of the start time, the User should attempt to contact the Practitioner via the Queer Healers messaging system, and may then report the no-show to <a target='_' href='mailto:help@queerhealers.com' >help@queerhealers.com</a> within 24 hours of the appointment time. If the Practitioner confirms that the service was not provided, the User’s payment will be refunded in full and no payout will be made to the Practitioner. If the no-show is not reported within 24 hours, the Queer Healers team will review communications and the User’s payment will be processed as if they had attended the session. A payout will be made to the Practitioner for the session.

</li>
<li style={{listStyleType:"square"}} >If a User does not attend a session within 30 minutes of the start time, the Practitioner should attempt to contact the User via the Queer Healers messaging system, and may report the no-show to <a target='_' href='mailto:help@queerhealers.com' >help@queerhealers.com</a>. The Queer Healers team will review communications, and the User’s payment will be processed as if they had attended the session, and a payout will be made to the Practitioner for the session.

</li>


      </ul>



      </ul>
      </li>
    </ol>
    <ol className={css.listContainer} >
      <li  className={css.listHeading} >
      <span style={{"marginRight":'0.8rem'}} >6.</span> Other Legal Provisions


      </li>
      <li>
      <ul  className={css.subListContainer} >
      <li   className={css.subListHeading}  > <span style={{"marginRight":'0.4rem'}} >6.1.</span>Relationship Between Parties


</li>
      <p>
      Using the Service does not create an employer-employee, principal-agent, joint venture, or partnership relationship between you and Queer Healers. Except as provided in these Terms, no party has the right to incur any right, responsibility, or liability on behalf of the other.</p>
    
     
      <li   className={css.subListHeading}  > <span style={{"marginRight":'0.4rem'}} >6.2.</span>Disclaimers

</li>
      <p>
      THE SERVICE IS PROVIDED TO YOU "AS IS" AND WITHOUT WARRANTY OF ANY KIND. TO THE EXTENT PERMITTED BY LAW, QUEER HEALERS DISCLAIMS ALL WARRANTIES, WHETHER EXPRESS, IMPLIED, STATUTORY, OR OTHERWISE, WITH RESPECT TO THE SERVICE, INCLUDING ALL IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE AND NON-INFRINGEMENT, AND WARRANTIES THAT MAY ARISE OUT OF COURSE OF DEALING, COURSE OF PERFORMANCE, USAGE OR TRADE PRACTICE.
</p>  
<p>
QUEER HEALERS MAKES NO REPRESENTATION OF ANY KIND THAT THE SERVICE WILL MEET YOUR REQUIREMENTS, ACHIEVE ANY INTENDED RESULTS, BE COMPATIBLE OR WORK WITH ANY OTHER SOFTWARE, APPLICATIONS, SYSTEMS OR SERVICES, OPERATE WITHOUT INTERRUPTION, MEET ANY PERFORMANCE OR RELIABILITY STANDARDS OR BE ERROR FREE OR THAT ANY ERRORS OR DEFECTS CAN OR WILL BE CORRECTED.

</p>


      <li   className={css.subListHeading}  > <span style={{"marginRight":'0.4rem'}} >6.3.</span>Limitation of Liability


</li>
      <p>
      THE LIABILITY OF QUEER HEALERS, ITS AFFILIATES, LICENSORS, AND SERVICE PROVIDERS, AND ITS AND THEIR RESPECTIVE OFFICERS, DIRECTORS, EMPLOYEES, CONTRACTORS, AGENTS, LICENSORS, SUPPLIERS, SUCCESSORS, AND ASSIGNS UNDER ANY PROVISION OF THESE TERMS SHALL BE LIMITED TO ACTUAL LOSSES. TO THE EXTENT PERMITTED BY LAW, NO PARTY SHALL BE LIABLE FOR ANY SPECIAL, INCIDENTAL, INDIRECT, OR CONSEQUENTIAL DAMAGES WHATSOEVER, EVEN IF THE OTHER PARTY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.

      </p>
    
    <p>
The foregoing limitation of liability does not apply to liability resulting from gross negligence or wilful misconduct and does not affect any other liability that cannot be limited or excluded under applicable law.</p>


<li   className={css.subListHeading}  > <span style={{"marginRight":'0.4rem'}} >6.4.</span>Indemnification


</li>
      <p>
      TO THE EXTENT PERMITTED BY LAW, YOU AGREE TO INDEMNIFY AND HOLD HARMLESS QUEER HEALERS, ITS AFFILIATES, LICENSORS, AND SERVICE PROVIDERS, AND ITS AND THEIR RESPECTIVE OFFICERS, DIRECTORS, EMPLOYEES, CONTRACTORS, AGENTS, LICENSORS, SUPPLIERS, SUCCESSORS, AND ASSIGNS FROM AND AGAINST ANY CLAIMS, LIABILITIES, DAMAGES, JUDGMENTS, AWARDS, LOSSES, COSTS, EXPENSES, OR FEES ARISING OUT OF OR RELATING TO THE SERVICE, YOUR CONTENT, OR YOUR VIOLATION OF THESE TERMS.
      </p>
    

      <li   className={css.subListHeading}  > <span style={{"marginRight":'0.4rem'}} >6.5.</span>Dispute Resolution



</li>
      <p>
      Should any dispute arise between the parties related to the Service, the parties agree to submit the dispute to a mutually agreed mediation service. The parties agree to cooperate to choose a neutral mediation service and mediator. Any fees and expenses related to mediation will be shared equally between the parties. </p>
      <p>
      If the parties cannot resolve the dispute through mediation, either party may file suit in a court of competent jurisdiction in accordance with Section 6.6 of these Terms.
</p>
    

      <li   className={css.subListHeading}  > <span style={{"marginRight":'0.4rem'}} >6.6.</span>Choice of Law and Venue




    </li>
      <p>
      These Terms, and all matters arising out of these Terms, are governed by the laws of the State of New York of the United States of America. All claims arising under these Terms must be brought in the United States District Court for the Southern District of New York or a state court of New York in New York County. Each party consents to the personal jurisdiction of these courts for the purpose of any claim arising under these Terms.
       </p>


<li   className={css.subListHeading}  > <span style={{"marginRight":'0.4rem'}} >6.7.</span>Waiver and Severability

</li>
  <p>
  No failure by a party to assert any right set out in these Terms shall be deemed a continuing waiver of such right or a waiver of any other term or condition.

   </p>
   <p>
   If any provision of these Terms is held by a court or other tribunal of competent jurisdiction to be invalid, illegal, or unenforceable for any reason, such provision shall be eliminated or limited to the minimum extent such that the remaining provisions of the Terms will continue in full force and effect.

   </p>
 
   <li   className={css.subListHeading}  > <span style={{"marginRight":'0.4rem'}} >6.8.</span>Entire Agreement

</li>
  <p>
  These Terms, including the Queer Healers  <NamedLink name="PrivacyPolicyPage">Privacy Policy</NamedLink> and the Queer Healers <NamedLink name="AboutPage">Community Guidelines</NamedLink> , constitute the entire agreement between you and Queer Healers regarding the Service and supersede all prior and contemporaneous agreements.
 </p>
   <li   className={css.subListHeading}  > <span style={{"marginRight":'0.4rem'}} >6.9.</span>Changes and Updates

</li>
  <p>
  We may make changes to these Terms, including the Queer Healers Privacy Policy or the Queer Healers <NamedLink name="AboutPage">Community Guidelines</NamedLink> . Continued use of the Service constitutes acceptance of any changes made to these Terms.
 </p>
   <p>
   The latest version of the Terms can always be found at <NamedLink name="TermsOfServicePage">https://queerhealers.com/terms-of-service</NamedLink>   .
   </p>
 
    



      </ul>
      </li>
    </ol>

    </div>
  );
};

TermsOfService.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

TermsOfService.propTypes = {
  rootClassName: string,
  className: string,
};

export default TermsOfService;
