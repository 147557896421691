import classNames from 'classnames';
import React from 'react';
import css from './InfoRow.module.css';
import { FormattedMessage, FormattedDate } from '../../util/reactIntl';

function InfoRow(props) {
  const {
    heading,
    headingOnly,
    contentOnly,
    value,
    date,
    time,
    timeFormatOptions,
    timeZoneMaybe,
    dateFormatOptions,
  } = props;

  if (time)
    return (
      <div className={css.root}>
        <h3 className={css.heading}>{heading}</h3>
        <p className={css.value}>
          <FormattedDate value={value} {...timeFormatOptions} {...timeZoneMaybe} />
        </p>
      </div>
    );

  if (date)
    return (
      <div className={css.root}>
        <h3 className={css.heading}>{heading}</h3>
        <p className={css.value}>
          <FormattedDate value={value} {...dateFormatOptions} {...timeZoneMaybe} />
        </p>
      </div>
    );

  return (
    <div className={css.root}>
      {headingOnly ? (
        <h3 className={classNames(css.centered, css.heading)}>{value}</h3>
      ) : contentOnly ? (
        <p className={css.value}>{value}</p>
      ) : (
        <>
          <h3 className={css.heading}>{heading}</h3>
          <p className={css.value}>{value}</p>
        </>
      )}
    </div>
  );
}

InfoRow.defaultProps = {
  timeFormatOptions: {
    hour: 'numeric',
    minute: 'numeric',
  },
  dateFormatOptions: {
    month: 'short',
    day: 'numeric',
  },
  timeZoneMaybe: {},
  time: false,
  date: false,
  contentOnly: false,
  headingOnly: false,
};

export default InfoRow;
