import React, { useState } from 'react';
import { array, bool, func, object, string } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { EditListingPhotosForm } from '../../forms';
import { ensureOwnListing } from '../../util/data';
import { ListingLink } from '../../components';

import css from './EditListingPhotosPanel.module.css';
import config from '../../config';

const EditListingPhotosPanel = props => {
  const {
    className,
    rootClassName,
    errors,
    disabled,
    ready,
    images,
    listing,
    onImageUpload,
    onUpdateImageOrder,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    onManageDisableScrolling,
    onChange,
    onSubmit,
    onRemoveImage,
  } = props;
  const [initialProps, setInitialProps] = useState({});
  const [primaryImage, setPrimaryImage] = useState(null);
  const [bannerImage, setBannerImage] = useState(null);
  const [customBannerImage, setCustomBannerImage] = useState(null);
  const rootClass = rootClassName || css.root;
  const classes = classNames(rootClass, className);
  const currentListing = ensureOwnListing(listing);
  const { publicData } = currentListing?.attributes ?? {};
  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
  const panelTitle = isPublished ? (
    <FormattedMessage
      id="EditListingPhotosPanel.title"
      values={{
        listingTitle: (
          // <ListingLink listing={listing}>
          <FormattedMessage id="EditListingPhotosPanel.listingTitle" />
          // </ListingLink>
        ),
      }}
    />
  ) : (
    <FormattedMessage id="EditListingPhotosPanel.createListingTitle" />
  );

  const initialValues = React.useMemo(() => {
    if (!initialProps)
      return {
        // images,
        bannerImage: publicData?.bannerImage,
        primaryImage: publicData?.primaryImage,
        customBanner: publicData?.customBanner
          ? config.custom.bannerImages?.find(i => i.id === publicData.customBanner)
          : null,
      };
    return {
      // images,
      bannerImage: bannerImage ? bannerImage : publicData?.bannerImage,
      primaryImage: primaryImage ? primaryImage : publicData?.primaryImage,
      customBanner: customBannerImage
        ? customBannerImage
        : publicData?.customBanner
        ? config.custom.bannerImages?.find(i => i.id === publicData.customBanner)
        : null,
      ...initialProps,
    };
  }, [initialProps, bannerImage, primaryImage, customBannerImage]);

  return (
    <div className={classes}>
      <div className="hWalkthroughForm">
        <h1 className={css.panelTitle}>{panelTitle}</h1>
        <EditListingPhotosForm
          className={css.form}
          disabled={disabled}
          ready={ready}
          fetchErrors={errors}
          initialValues={initialValues}
          images={images}
          onImageUpload={onImageUpload}
          onSubmit={values => {
            const { addImage, customBanner, primaryImage, bannerImage, ...updateValues } = values;
            const updatedValues = {
              ...updateValues,
              publicData: { customBanner: customBanner?.id, primaryImage, bannerImage },
            };
            setInitialProps({
              ...updatedValues,
              customBanner,
              primaryImage,
              bannerImage,
            });
            onSubmit(updatedValues);
          }}
          onChange={onChange}
          onUpdateImageOrder={onUpdateImageOrder}
          onRemoveImage={onRemoveImage}
          saveActionMsg={submitButtonText}
          updated={panelUpdated}
          updateInProgress={updateInProgress}
          currentListing={currentListing}
          onManageDisableScrolling={onManageDisableScrolling}
          setBannerImage={setBannerImage}
          setPrimaryImage={setPrimaryImage}
          setCustomBannerImage={setCustomBannerImage}
          customBannerImage={customBannerImage}
        />
      </div>
    </div>
  );
};

EditListingPhotosPanel.defaultProps = {
  className: null,
  rootClassName: null,
  errors: null,
  images: [],
  listing: null,
};

EditListingPhotosPanel.propTypes = {
  className: string,
  rootClassName: string,
  errors: object,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  images: array,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  onImageUpload: func.isRequired,
  onUpdateImageOrder: func.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  onRemoveImage: func.isRequired,
};

export default EditListingPhotosPanel;
