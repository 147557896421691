import React from 'react';
import { useIntl } from '../../util/reactIntl';
import { IconSpinner } from '../../components';

export default () => {
  const intl = useIntl();
  return (
    <div className="flex w-[100px] items-center justify-between">
      <span className="text-sm">{intl.formatMessage({ id: 'MembershipPage.Loading' })}</span>
      <IconSpinner className="w-4 h-4" />
    </div>
  );
};
