import React from 'react';
import classNames from 'classnames';
import config from '../../config';
import { DATE_TYPE_DATETIME } from '../../util/types';
import { ensureListing } from '../../util/data';
import { BookingBreakdown } from '../../components';
import css from './TransactionPanel.module.css';
import { atcb_action, atcb_init } from 'add-to-calendar-button';
import { FaRegCalendarPlus } from 'react-icons/fa';
import moment from 'moment';
import { _ } from 'core-js';
import { getDefaultTimeZoneOnBrowser } from '../../util/dates';
// Functional component as a helper to build BookingBreakdown
const BreakdownMaybe = props => {
  const {
    className,
    rootClassName,
    breakdownClassName,
    transaction,
    transactionRole,
    isTransactionPanel,
    selectedOffering,
    isAccepted,
  } = props;
  const loaded = transaction && transaction.id && transaction.booking && transaction.booking.id;
  const listingAttributes = ensureListing(transaction.listing).attributes;
  const timeZone =
    loaded && listingAttributes.availabilityPlan
      ? listingAttributes.availabilityPlan.timezone
      : 'Etc/UTC';
  const classes = classNames(rootClassName || css.breakdownMaybe, className);
  const breakdownClasses = classNames(breakdownClassName || css.breakdown);
  const startDateFormat = moment(new Date(transaction?.booking?.attributes?.start)).format(
    'YYYY-MM-DD'
  );
  const endDateFormat = moment(new Date(transaction?.booking?.attributes?.end)).format(
    'YYYY-MM-DD'
  );
  const fullName = transaction?.listing?.attributes?.title;
  const startHour = moment(new Date(transaction?.booking?.attributes?.start)).format('HH:mm');
  const endHour = moment(new Date(transaction?.booking?.attributes?.end)).format('HH:mm');
  // const desc = transaction?.listing?.attributes?.description;
  const tz = typeof window !== 'undefined' ? getDefaultTimeZoneOnBrowser() : null;
  const compareTz = tz === timeZone ? timeZone : tz;

  function isInThePast(date) {
    let timeStamp = new Date(date).getTime();
    return timeStamp < Date.now();
  }

  const isExpired = isInThePast(new Date(transaction?.booking?.attributes?.end));
  const mailBtn = (
    <form
      onSubmit={e => {
        e.preventDefault();
        atcb_action({
          name: `Queer Healers session: ${fullName}`,
          description: '',
          startDate: startDateFormat,
          endDate: endDateFormat,
          startTime: startHour,
          endTime: endHour,
          location: 'To be confirmed by practitioner.',
          timeZone: compareTz,
          options: ['Google', 'Apple', 'Outlook.com', 'Yahoo'],
          iCalFileName: 'Queerhealers-Event',
        });
      }}
    >
      <button
        type="submit"
        value="Add to calendar"
        style={{
          border: '1px solid #E7E7E7',
          padding: '10px',
          borderRadius: '3px',
          width: '100%',
          margin: '16px auto',
        }}
      >
        <FaRegCalendarPlus style={{ marginRight: '10px' }} />
        Add to calendar
      </button>
    </form>
  );
  return loaded ? (
    <div className={classes}>
      <BookingBreakdown
        className={breakdownClasses}
        userRole={transactionRole}
        unitType={config.bookingUnitType}
        transaction={transaction}
        isExpired={isExpired}
        isAccepted={isAccepted}
        mailBtn={isTransactionPanel ? mailBtn : null}
        booking={transaction.booking}
        dateType={DATE_TYPE_DATETIME}
        timeZone={timeZone}
      />
    </div>
  ) : null;
};

export default BreakdownMaybe;
