import axios from 'axios';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { connect } from 'react-redux';
import { Button, Modal, SecondaryButton } from '../../components';
import { manageDisableScrolling } from '../../ducks/UI.duck';
import { fetchCurrentUser } from '../../ducks/user.duck';
import css from './CancellationModal.module.css';

const CancellationModal = props => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const intl = useIntl();
  const dispatch = useDispatch();

  const onCancelSubscription = e => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    axios
      .post(
        '/cancel-subscription',
        {},
        {
          withCredentials: true,
        }
      )
      .then(res => {
        dispatch(fetchCurrentUser());
        props.onClose();
      })
      .catch(err => {
        const error = err?.response?.data?.message ?? err?.message;
        setError(error);
      })
      .finally(() => setLoading(false));
  };

  return (
    <Modal {...props} usePortal id="cancellation-modal">
      <h1>{intl.formatMessage({ id: 'EditListingAvailabilityPanel.CancellationModal.title' })}</h1>
      <p className={css.modalText}>
        {intl.formatMessage({ id: 'EditListingAvailabilityPanel.CancellationModal.body' })}
      </p>
      <Button
        type="button"
        className="mb-2"
        onClick={onCancelSubscription}
        inProgress={loading}
        disabled={loading}
      >
        {intl.formatMessage({
          id: 'EditListingAvailabilityPanel.CancellationModal.continue',
        })}
      </Button>
      {error && <p className="text-red-500 text-sm">{error}</p>}

      <SecondaryButton type="button" onClick={props.onClose} disabled={loading}>
        {intl.formatMessage({
          id: 'EditListingAvailabilityPanel.CancellationModal.cancel',
        })}
      </SecondaryButton>
    </Modal>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    onManageDisableScrolling: (componenId, scrollingDisabled) =>
      dispatch(manageDisableScrolling(componenId, scrollingDisabled)),
  };
};

export default connect(
  undefined,
  mapDispatchToProps
)(CancellationModal);
