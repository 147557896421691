import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { NamedLink } from '../index';

import css from './PrivacyPolicy.module.css';

const PrivacyPolicy = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  // prettier-ignore
  return (
    <div className={classes}>
      <b className={css.subHeading}>Queer Healers Privacy Policy
</b>

      <p className={css.lastUpdated}>Last Updated: October 3, 2022</p>

      <p>
      This Privacy Policy explains how and why Queer Healers (“we” or “us”) collects personal information, and our practices for using, protecting, and disclosing that information. By using the Queer Healers website and any associated services, communications, and applications (the “Service”), you consent to the practices described in this Privacy Policy. If you do not consent, please do not use the Service.
 </p>


    <ol className={css.listContainer} >
      <li  className={css.listHeading} >
      <span style={{"marginRight":'0.8rem'}} >1.</span> What Information We Collect
      </li>
      
       <li>
      <ul  className={css.subListContainer} >
      <li><p>During the course of using the Service, we collect information about you and your use of the Service. This may include information that is related to you personally, such as your name, date of birth, social media accounts or websites, mailing address, email address, and telephone number (“Personal Information”). It may also include information that is about you but does not directly identify you, such as your general geographic location, pronouns, race, ethnicity, religion, sexual orientation, and gender identity (“Demographic Information”).</p>
     </li>
     <li><p>
Information about you is collected in three ways: (1) directly from you when you provide it to us; (2) automatically as you navigate through the site; and (3) from third parties.
</p></li>
      <li   className={css.subListHeading}  > 
      <span style={{"marginRight":'0.4rem'}} >1.1.</span>Information You Provide
</li>
      <p>
      Information we may ask you to provide while using the Service includes:

      </p>
      <ul>
        <li style={{listStyleType:"square"}} > <b>Account Information.</b>  When you create an account on the Service, we will ask you to provide Personal Information, including your preferred name and email address. You also have the option of providing Demographic Information.
 .
</li>
        <li style={{listStyleType:"square"}} ><b>Healer Information. </b>If you apply to be a Healer, we will ask you for additional Personal Information, including your legal name, date of birth, and biographical information, and Demographic Information. We will also ask you for information about your practice, including qualifications, business name and location, services offered, and a brief description.

</li>
        <li style={{listStyleType:"square"}} ><b>User Generated Content.</b>While using the Service, you will have the opportunity to upload or create content such as photos of your business, descriptions of services you offer, and reviews of services you have obtained.

</li>
        <li style={{listStyleType:"square"}} ><b>Communications</b>The Service allows you send and receive messages to other Users and to the Queer Healers team. These communications are transmitted through and stored on the Service. We may also collect information about which communications you have read or deleted.

</li>
        

      </ul>



      <li   className={css.subListHeading}  > 
      <span style={{"marginRight":'0.4rem'}} >1.2.</span>Information About Your Use of the Service
</li>
      <p>
      We collect and store certain information about your use of the Service. Information we automatically collect about your usages includes:


      </p>
      <ul>
        <li style={{listStyleType:"square"}} > <b>Bookings and Payment Information. </b>If you book services with a Healer through the Service, we collect and store information about the booking, including the time and location of the service. We also collect information about whether the payment was fulfilled, but we do not collect information about the payment method.
</li>
        <li style={{listStyleType:"square"}} ><b>Browsing Information. </b>As you use the Service, we may collect information about your device, including your IP address, operating system, and browser type, and which pages you visit.

</li>
        <li style={{listStyleType:"square"}} ><b>Cookies. </b>When you use the Service, we may place cookies on your computer. A cookie is a text file with a small amount of data sent to your browser from a website and stored on your device. Cookies may contain information that uniquely identifies you. You can set your browser to reject all cookies, but doing so may make parts of the Service inoperable.


</li>
        <li style={{listStyleType:"square"}} ><b>Tracking Information. </b> In addition to cookies, we may use web beacons, digital fingerprints, advertising identifiers, or other similar technologies to identify your usage over time.


</li>
<li style={{listStyleType:"square"}} ><b>Analytics. </b>Queer Healers uses Google Analytics to track website usage. You can opt out of Google Analytics by installing the <a target='_' href='https://tools.google.com/dlpage/gaoptout' >Google Analytics Opt-Out Browser Add-On</a> .


</li>
        

      </ul>

      <li   className={css.subListHeading}  > 
      <span style={{"marginRight":'0.4rem'}} >1.3.</span>Information From Third Parties

</li>
      <p>
      If you arrive at the Service from a link or advertisement on a promotional email, third party website, or other service, we may collect information about how you found us.


      </p>
      <li   className={css.subListHeading}  > 
      <span style={{"marginRight":'0.4rem'}} >1.4.</span>Information About Minors

</li>
      <p>
      We do not knowingly collect personal information from children under 18. If you are under 18, do not provide any information through the Service or any of its features. If we learn we have collected or received personal information from a child under 18, we will delete that information.



      </p>
     
      </ul>
      </li>
    </ol>

    <ol className={css.listContainer} >
      <li  className={css.listHeading} >
      <span style={{"marginRight":'0.8rem'}} >2.</span> How We Use Your Information

      </li>
      
       <li>
      <ul  className={css.subListContainer} >
      <li><p>We use your information to operate the Service and provide you with information, products, or services that you request from us. We may use your information for any of the purposes listed below and for any other purpose you consent to during your use of the Service.
</p>
     </li>
    
      <li   className={css.subListHeading}  > 
      <span style={{"marginRight":'0.4rem'}} >2.1.</span>Directory Listings
</li>
      <p>
      The primary use of information we collect is to connect Users with Healers. If you are a Healer, your information will be displayed in our directory, allowing the public to browse your profile and services. If you are a User, any reviews you post will be visible to the public, along with the preferred name on your account.

      </p>
    



      <li   className={css.subListHeading}  > 
      <span style={{"marginRight":'0.4rem'}} >2.2.</span>Communications
</li>
      <p>
      The Service allows you to communicate with other Users. To enable this feature, we transmit and store your communications. We may review your communications if there is a dispute between you and another User, if we receive an Abuse Report about a User, if we believe there has been a violation of the Terms of Use, or if we are legally obligated to do so.


      </p>
      <p>We may also use your information to communicate with you directly, including notifying you about changes to the Service or governing Terms.
</p>
      

      <li   className={css.subListHeading}  > 
      <span style={{"marginRight":'0.4rem'}} >2.3.</span>Booking and Payment


</li>
      <p>
      If you use the Service to book a session with a Healer, the first name and last initial on your account will be shared with the Healer. If you are a Healer and you accept an in-person session request from a User, the physical location you confirmed for that session will be shared with the User. We also use your information to facilitate payment for services, but we do not store your payment information.


      </p>
      <li   className={css.subListHeading}  > 
      <span style={{"marginRight":'0.4rem'}} >2.4.</span>Marketing and Personalization


</li>
      <p>
      We may use information about you for marketing purposes. We may also use information to recognize when you return to the Service and personalize your experience. For example, we may email you about new specific Healers based on your past use of the site.
      </p>
      <li   className={css.subListHeading}  > 
      <span style={{"marginRight":'0.4rem'}} >2.5.</span>Analytics and Quality Improvement



</li>
      <p>
      We may use information about you, individually or in aggregate, to analyze how Users interact with the Service, conduct internal research, and improve the functionality of the Service.
      </p>
      <li   className={css.subListHeading}  > 
      <span style={{"marginRight":'0.4rem'}} >2.6.</span>Technical Support and Security



</li>
      <p>
      We may use your information to provide customer support, back up and recover data, allow for disaster recovery, and enhance the security of the Service.
      </p>
      <li   className={css.subListHeading}  > 
      <span style={{"marginRight":'0.4rem'}} >2.7.</span>Legal Rights



</li>
      <p>
      We may use your information to investigate potential misconduct, misuse of the Service, or breaches of the governing Terms. In the event of misconduct, we may use your information to enforce our rights or the rights of any other person.
      </p>
     
      </ul>
      </li>
    </ol>



    <ol className={css.listContainer} >
      <li  className={css.listHeading} >
      <span style={{"marginRight":'0.8rem'}} >3.</span> How We Share Your Information


      </li>
      
       <li>
      <ul  className={css.subListContainer} >
      <li><p>We may share some of your information, including Personal Information, with third parties. We may share your information for any of the purposes listed below and for any other purpose you consent to during your use of the Service.
</p>
     </li>
    
      <li   className={css.subListHeading}  > 
      <span style={{"marginRight":'0.4rem'}} >3.1.</span>Payment Processing
</li>
      <p>
      Payments for services arranged through the Service are processed by third-party providers as described in the Terms of Use. In order to enable payment processing, we may share information about you with these third parties.

      </p>
    



      <li   className={css.subListHeading}  > 
      <span style={{"marginRight":'0.4rem'}} >3.2.</span>Service Providers

</li>
      <p>
      We may engage third-party service providers for content hosting, security and fraud prevention, technical support, or other services. To the extent necessary to enable these services, we may share information about you with these third parties.


      </p>
      
      

      <li   className={css.subListHeading}  > 
      <span style={{"marginRight":'0.4rem'}} >3.3.</span>Business Transactions


</li>
      <p>
      In the event of a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of Queer Healers’ assets, we may disclose your information to the party receiving the assets. The receiving party will assume all rights and obligations under this Privacy Policy.


      </p>
      <li   className={css.subListHeading}  > 
      <span style={{"marginRight":'0.4rem'}} >3.4.</span>Legal Obligations


</li>
      <p>
      If we receive a subpoena, warrant, or other judicial order, we may be legally obligated to disclose your information.
</p>
      <li   className={css.subListHeading}  > 
      <span style={{"marginRight":'0.4rem'}} >3.5.</span>Safety



</li>
      <p>
      We may disclose your information if we believe disclosure is necessary or appropriate to protect the rights or safety of Queer Healers, our Users, or a third party.
 </p>
      
    
      </ul>
      </li>
    </ol>




    <ol className={css.listContainer} >
      <li  className={css.listHeading} >
      <span style={{"marginRight":'0.8rem'}} >4.</span> Controlling Your Information


      </li>
      
       <li>
      <ul  className={css.subListContainer} >
      <li><p>Depending on the state or country in with you live, you may have certain legal rights regarding Queer Healers’ collection, use, and sharing of your personal information. Regardless of where you live, Queer Healers:
</p>
     </li>
    

<p>
<b style={{"paddingRight":'0.4rem'}} >4.1</b>   Will not sell your personal information or disclose it in exchange for anything of value, except as provided in Section 3.3 above.

      </p>
    




      <p>
      <b style={{"paddingRight":'0.4rem'}} >4.2</b>   Will not share your personal information with third parties for direct marketing purposes.

      </p>
      <p> <b style={{"paddingRight":'0.4rem'}} >4.3</b>  Will, on request from you or an authorized agent, disclose to you any personal information it has collected about you.</p>
      


      <p>
      <b style={{"paddingRight":'0.4rem'}} >4.4</b>  Will, on request from you or an authorized agent, delete or correct any personal information it has collected about you.

      </p>
      <li><p>
      To exercise these options, or any other rights secured to you under the law, please email <a target='_' href='mailto:help@queerhealers.com' >help@queerhealers.com</a> . We will not discriminate against you in any way for invoking your privacy rights.
</p></li> 
</ul>


     

     
     
      </li>
    </ol>

    <ol className={css.listContainer} >
      <li  className={css.listHeading} >
      <span style={{"marginRight":'0.8rem'}} >5.</span> Information Security


      </li>
      
       <li>
      <ul  className={css.subListContainer} >
      <li><p>We use measures designed to secure your information from accidental loss and unauthorized access, use, alteration, and disclosure. Unfortunately, the transmission of information via the internet is not completely secure. Although we do our best to protect your information, we cannot guarantee the security of your Personal Information transmitted through or uploaded to the Service.
</p>
     </li>
    



     
      </ul>
      </li>
    </ol>
    <ol className={css.listContainer} >
      <li  className={css.listHeading} >
      <span style={{"marginRight":'0.8rem'}} >6.</span> Changes and Updates


      </li>
      
       <li>
      <ul  className={css.subListContainer} >
      <li><p>We may make changes to this Privacy Policy from time to time. Continued use of the Service constitutes acceptance of any changes made to this Policy.

</p>
     </li>
     <li>

     <p>
     The latest version of this Privacy Policy can always be found at <a target='_' href='https://queerhealers.com/privacy-policy' >https://queerhealers.com/privacy-policy</a> .
     </p>
     </li>
    
    



     
      </ul>
      </li>
    </ol>



    </div>
  );
};

PrivacyPolicy.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

PrivacyPolicy.propTypes = {
  rootClassName: string,
  className: string,
};

export default PrivacyPolicy;
