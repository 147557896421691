import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';

import { LISTING_STATE_DRAFT } from '../../util/types';
import { ensureListing } from '../../util/data';
import { EditListingFeaturedIdentitiesForm } from '../../forms';
import { ListingLink } from '../../components';

import css from './EditListingFeaturedIdentitiesPanel.module.css';
import config from '../../config';

const EditListingFeaturedIdentitiesPanel = props => {
  const {
    rootClassName,
    className,
    listing,
    disabled,
    ready,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
  } = props;
  const [updateValues, setUpdateValues] = useState(undefined);
  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureListing(listing);
  const { publicData } = currentListing.attributes;

  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
  const panelTitle = isPublished ? (
    <FormattedMessage
      id="EditListingFeaturedIdentitiesPanel.title"
      values={{
        listingTitle: (
          <ListingLink listing={listing}>
            <FormattedMessage id="EditListingFeaturesPanel.listingTitle" />
          </ListingLink>
        ),
      }}
    />
  ) : (
    <FormattedMessage id="EditListingFeaturedIdentitiesPanel.createListingTitle" />
  );

  let {
    featured_identities = [],
    spirituality_identities = [],
    sexuality_identities = [],
    disability_identities = [],
    background_identities = [],
    gender_identities = [],
    misc_identities = [],
  } = publicData || {};

  const featuredIdentityOptions = useMemo(() => {
    if (typeof updateValues == 'undefined') {
      return featured_identities;
    }
  }, [featured_identities, updateValues]);

  background_identities = config.custom.backgroundIdentities.filter(v =>
    background_identities.includes(v.key)
  );

  disability_identities = config.custom.disabilitiesIdentity.filter(v =>
    disability_identities.includes(v.key)
  );

  sexuality_identities = config.custom.sexualityIdentities.filter(v =>
    sexuality_identities.includes(v.key)
  );

  spirituality_identities = config.custom.spiritualityIdentities.filter(v =>
    spirituality_identities.includes(v.key)
  );
  gender_identities = config.custom.genderIdentities.filter(v => gender_identities.includes(v.key));
  misc_identities = config.custom.miscIdentities.filter(v => misc_identities.includes(v.key));

  const initialValues = {};

  return (
    <div className={classes}>
      <div className="hWalkthroughForm">
        {/* <h1 className={css.title}>{panelTitle}</h1> */}
        <h6>{panelTitle}</h6>
        <h1 className={css.panelTitle}>Select up to three identities to feature</h1>

        <EditListingFeaturedIdentitiesForm
          className={css.form}
          name={'featured-identities'}
          backgroundIdentities={background_identities}
          disabilityIdentities={disability_identities}
          sexualityIdentities={sexuality_identities}
          spiritualityIdentities={spirituality_identities}
          featuredIdentities={featured_identities}
          miscIdentities={misc_identities}
          genderIdentities={gender_identities}
          initialValues={initialValues}
          onSubmit={values => {
            const { featuredIdentities } = values;
            const options = featuredIdentities ?? featured_identities;
            const updatedValues = {
              publicData: {
                featured_identities: options,
              },
            };
            setUpdateValues(options);

            onSubmit(updatedValues);
          }}
          onChange={onChange}
          saveActionMsg={submitButtonText}
          disabled={disabled}
          ready={ready}
          updated={panelUpdated}
          updateInProgress={updateInProgress}
          fetchErrors={errors}
        />
      </div>
    </div>
  );
};

EditListingFeaturedIdentitiesPanel.defaultProps = {
  rootClassName: null,
  className: null,
  listing: null,
};

const { bool, func, object, string } = PropTypes;

EditListingFeaturedIdentitiesPanel.propTypes = {
  rootClassName: string,
  className: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EditListingFeaturedIdentitiesPanel;
