import React, { useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage, injectIntl } from '../../util/reactIntl';

import { LISTING_STATE_DRAFT } from '../../util/types';
import { ensureCurrentUser, ensureListing } from '../../util/data';

import { ListingLink } from '../../components';

import css from './EditListingStripePanel.module.css';
import { FaPlusCircle } from 'react-icons/fa';
import { getStripeAccountData, hasRequirements } from '../EditListingWizard/EditListingWizard';
import { connect } from 'react-redux';
import Button from '../Button/Button';
import StripeConnectStatusArea from './StripeConnectStatusArea';
import GoBackButton from '../GoBackButton/GoBackButton';
import { compose } from 'redux';

const EditListingStripePanel = props => {
  const {
    rootClassName,
    className,
    listing,
    disabled,
    ready,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
    onManageDisableScrolling,
    onShowPayoutModal,
    fetchStripeAccountInProgress,
    currentUser,
    stripeAccount,
    currentUserLoading,
    goBackLink,
    intl,
    currentListingHasAvailabilityPlan,
    membership,
    isPaid,
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureListing(listing);
  const { publicData } = currentListing.attributes;

  const [loading, setLoading] = useState(true);
  const timerRef = useRef(null);

  useEffect(() => {
    timerRef.current = setTimeout(() => setLoading(false), 2000);

    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, []);

  const ensuredCurrentUser = ensureCurrentUser(currentUser);

  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
  // console.log('isPublished', isPublished);
  const panelTitle = isPublished ? (
    <FormattedMessage id="EditListingStripePanel.title" />
  ) : (
    <FormattedMessage id="EditListingStripePanel.createListingTitle" />
  );
  const mainTitle = isPublished ? (
    <FormattedMessage id="EditListingStripePanel.mainTitle" />
  ) : (
    <FormattedMessage id="EditListingStripePanel.createListingMainTitle" />
  );

  const stripeConnected =
    ensuredCurrentUser && ensuredCurrentUser.stripeAccount && !!ensuredCurrentUser.stripeAccount.id;

  const stripeAccountData = stripeConnected ? getStripeAccountData(stripeAccount) : null;

  const requirementsMissing =
    stripeAccount &&
    (hasRequirements(stripeAccountData, 'past_due') ||
      hasRequirements(stripeAccountData, 'currently_due'));

  const connected = stripeConnected && !requirementsMissing;

  const onPublishListing = () => {
    console.log('onPublishListing');
    if (updateInProgress || !connected) return;
    onSubmit({
      publicData: {
        stripe_connected: connected,
      },
      metadata: {
        ownBookingPlatform: false,
      },
    });
  };

  // <div className={classes}>
  {
    /* <div className="hWalkthroughForm"> */
  }
  return (
    <>
      {isPublished ? null : <h6>{panelTitle}</h6>}
      <h1 className={css.panelTitle}>{mainTitle}</h1>

      <p className={css.panelDescription}>
        {intl.formatMessage({ id: 'EditListingStripePanel.tabDescriptiontext' })}
      </p>

      {currentUserLoading ? null : fetchStripeAccountInProgress || loading ? (
        <p>{intl.formatMessage({ id: 'EditListingStripePanel.loadingText' })}</p>
      ) : connected ? (
        <StripeConnectStatusArea />
      ) : (
        <div className="push-top-smedium">
          <button className="btn-large-outline red" type="button" onClick={onShowPayoutModal}>
            <FaPlusCircle />{' '}
            <strong>
              {intl.formatMessage({ id: 'EditListingStripePanel.addBankAccountButtonLabel' })}
            </strong>
          </button>
        </div>
      )}
      {membership ? (
        <p className={css.panelDescription}>
          {intl.formatMessage({ id: 'EditListingStripePanel.EditListingStripePanel.note' })}
        </p>
      ) : null}
      <Button
        inProgress={updateInProgress}
        disabled={
          // isPaid
          //   ? false
          //   : 
            updateInProgress || !connected || loading || !currentListingHasAvailabilityPlan
        }
        onClick={onPublishListing}
      >
        {submitButtonText}
      </Button>

      <div className={css.goBackContainer}>
        <GoBackButton url={goBackLink} />
      </div>
      {/* </div>
     </div> */}
    </>
  );
};

EditListingStripePanel.defaultProps = {
  rootClassName: null,
  className: null,
  listing: null,
};

const { bool, func, object, string } = PropTypes;

EditListingStripePanel.propTypes = {
  rootClassName: string,
  className: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

const mapStateToProps = state => {
  const {
    stripeAccount,
    stripeAccountFetched,
    fetchStripeAccountInProgress,
  } = state.stripeConnectAccount;

  console.log({ fetchStripeAccountInProgress });

  const { currentUser, currentUserLoading } = state.user;

  return {
    stripeAccount,
    stripeAccountFetched,
    fetchStripeAccountInProgress,
    currentUser,
    currentUserLoading,
  };
};

export default compose(injectIntl, connect(mapStateToProps))(EditListingStripePanel);
