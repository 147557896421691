import React, { useState, useCallback, useEffect } from 'react';
import { FieldFileUpload, IconSpinner, Modal, Button } from '../../components';
import { FormattedMessage } from '../../util/reactIntl';
import axios from 'axios';
import config from '../../config';
import css from './EditListingPhotosForm.module.css';
import Cropper from 'react-easy-crop';
import getCroppedImg from './CropImage';

const PrimaryImage = props => {
  const {
    values,
    form,
    chooseImageText,
    currentListing,
    onManageDisableScrolling,
    setPrimaryImage,
  } = props;
  const [loading, setLoading] = useState(false);
  const [uploadError, setUploadError] = useState(null);
  const [isSelectionModalOpen, setIsSelectionModalOpen] = useState(false);
  const [defaultImage, setDefaultImage] = useState(null);
  const [croppedPixels, setCroppedPixels] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedPixels(croppedAreaPixels);
  }, []);
  const toDataURL = url =>
    fetch(url)
      .then(response => response.blob())
      .then(
        blob =>
          new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.onerror = reject;
            reader.readAsDataURL(blob);
          })
      );
  function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }
  const showCroppedImage = async () => {
    const croppedImage = await getCroppedImg(defaultImage, croppedPixels);
    return new Promise((resolve, reject) => {
      toDataURL(croppedImage).then(dataUrl => {
        var fileData = dataURLtoFile(dataUrl, 'imageName.jpg');
        return resolve(fileData);
      });
    });
  };

  const imgSrc =
    values?.primaryImage && !loading
      ? typeof values?.primaryImage == 'string'
        ? values?.primaryImage
        : undefined
      : undefined;
  // console.log({ imgSrc, loading });
  const uploadFile = async (file, form) => {
    const formData = new FormData();
    formData.append('primaryImage', file);
    formData.append('listingId', currentListing.id.uuid);
    setUploadError(null);
    setLoading(true);

    try {
      const res = await axios.post('/upload-primary-image', formData);
      const { link } = res.data;
      form.change('primaryImage', link);
      setPrimaryImage(link);
      setUploadError(null);
    } catch (e) {
      console.log(e);
      form.change('primaryImage', '');
      setUploadError('Failed to upload the Image. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className={css.containerPrimary}>
        <label>
          <FormattedMessage id="EditListingPhotosForm.primaryImageTitle" />
          <span className="red"> *</span>
        </label>

        <div className={css.addImageWrapper}>
          <div className={css.aspectRatioWrapperPrimary}>
            <FieldFileUpload
              name="primaryImage"
              id="primaryImage"
              label={
                <div className={`${css.addImage}`}>
                  {imgSrc ? (
                    <img src={imgSrc} className="object-cover block w-full" />
                  ) : (
                    <div className="flex items-center flex-col">
                      {loading ? <IconSpinner /> : chooseImageText}
                    </div>
                  )}
                </div>
              }
              showUploading={false}
              acceptFiles="images"
              uploading={loading}
              // onSelectFile={file => uploadFile(file, form)}
              onSelectFile={
                file => {
                  setDefaultImage(URL.createObjectURL(file));
                  setIsSelectionModalOpen(true);
                }
                // uploadFile(file, form)
              }
            />
          </div>
        </div>
      </div>
      <div>
        {uploadError && (
          <p className="text-red-500 text-sm font-semibold text-center">{uploadError}</p>
        )}
      </div>
      <Modal
        id="EditListingPhotosForm.selectImageAreaA"
        containerClassName={css.selectImageAreaModalContent}
        isOpen={isSelectionModalOpen}
        onClose={() => setIsSelectionModalOpen(false)}
        usePortal
        onManageDisableScrolling={onManageDisableScrolling}
      >
        <div className={css.addImageWrapperBanner}>
          <div className={css.aspectRatioWrapper}>
            <div className={css.App}>
              <div className={css.cropcontainer}>
                <Cropper
                  image={defaultImage}
                  crop={crop}
                  zoom={zoom}
                  aspect={4 / 4}
                  onCropChange={setCrop}
                  onCropComplete={onCropComplete}
                  onZoomChange={setZoom}
                />
              </div>
              <div className={css.controls}>
                <input
                  type="range"
                  value={zoom}
                  min={1}
                  max={3}
                  step={0.1}
                  aria-labelledby="Zoom"
                  onChange={e => {
                    setZoom(e.target.value);
                  }}
                  className={css.zoomrange}
                />
              </div>
            </div>
          </div>
        </div>
        <Button
          className={css.submitImageButton}
          type="button"
          onClick={async e => {
            e.preventDefault();
            try {
              const res = await showCroppedImage();
              await setIsSelectionModalOpen(false);
              // await form.change('primaryImage', undefined);
              await uploadFile(res, form);
            } catch (e) {
              console.log(e);
            }
          }}
        >
          <FormattedMessage id="EditListingPhotosForm.saveChanges" />
        </Button>
      </Modal>
    </>
  );
};

export default PrimaryImage;
