import React from 'react';
import { useHistory } from 'react-router-dom';
import css from './GoBackButton.module.css';
import { Button } from '..';
import { SecondaryButton } from '../Button/Button';
import { injectIntl } from '../../util/reactIntl';

function GoBackButton({ url, text, intl }) {
  const buttonLabel = text ?? intl.formatMessage({ id: 'GoBackButtonLabel' });
  const history = useHistory();
  const onGoBack = () => history.replace(url);

  if (!url) return null;

  return (
    <SecondaryButton type="button" className={css.goBack} onClick={onGoBack}>
      {buttonLabel}
    </SecondaryButton>
  );
}

export default injectIntl(GoBackButton);
