import React from 'react';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { IconEdit, IconSuccess, InlineTextButton } from '../../components';
import css from './StripeConnectStatusArea.module.css';
import { useHistory } from 'react-router-dom';

function StripeConnectStatusArea() {
  const history = useHistory();
  return (
    <div>
      <div className={classNames(css.verificiationBox, css.verficiationSuccessBox)}>
        <div
          className={classNames(
            css.verificiationBoxTextWrapper,
            css.verificationBoxSuccessTextWrapper
          )}
        >
          <div className={css.verificationBoxTitle}>
            <IconSuccess className={css.icon} />
            <FormattedMessage id="EditListingStripePanel.stripeConnected" />
          </div>
        </div>

        <InlineTextButton
          className={css.editVerificationButton}
          spinnerClassName={css.spinner}
          type="button"
          onClick={() => history.push('/account/payments')}

          // inProgress={inProgress}
          // disabled={disabled}
          // onClick={onGetStripeConnectAccountLink}
        >
          <IconEdit className={css.icon} pencilClassName={css.iconEditPencil} />
          <FormattedMessage id="EditListingStripePanel.editAccountButton" />
        </InlineTextButton>
      </div>
    </div>
  );
}

export default StripeConnectStatusArea;
