import React, { useState } from 'react';
import FieldTextInput from '../FieldTextInput/FieldTextInput';
import Form from '../Form/Form';
import { Form as FinalForm } from 'react-final-form';
import Button from '../Button/Button';
import { validateURL } from '../../util/validators';
import css from './OwnBookingPlatformForm.module.css';
import { useIntl } from '../../util/reactIntl';
import IconSuccess from '../IconSuccess/IconSuccess';
import { ToastContainer } from 'react-toastify';
import { showToast } from '../../util/toast';
export default props => {
  return (
    <FinalForm
      {...props}
      render={formRenderProps => {
        const {
          handleSubmit,
          subscriptionStripeFormOpen,
          subscriptionSuccess,
          updateInProgress,
          submitButtonText,
          invalid,
          pristine,
          showMembership,
        } = formRenderProps;
        const intl = useIntl();
        const submitDisabled = updateInProgress || invalid || pristine;
        const [show, setShow] = useState(false);
        // if (subscriptionSuccess && !subscriptionStripeFormOpen && !show) {
        //   showToast('You are subscribed!', 'success');
        //   setShow(true);
        // }
        return (
          <Form onSubmit={handleSubmit} className={css.root}>
            {/* {subscriptionSuccess && !subscriptionStripeFormOpen ? ( */}
              <div className={css.subscriptionSuccess}>
                You are subscribed! <IconSuccess />
              </div>
            {/* ) : null} */}
            <FieldTextInput
              name="booking_platform_website"
              id="booking_platform_website"
              label={intl.formatMessage({
                id: 'EditListingAvailabilityPanel.OwnBookingPlatformForm.InputLabel',
              })}
              type="url"
              placeholder="https://queerhealers.com"
              validate={validateURL(
                intl.formatMessage({
                  id: 'EditListingAvailabilityPanel.OwnBookingPlatformForm.InputValidationMessage',
                })
              )}
            />
            <ToastContainer />
            {!subscriptionStripeFormOpen && (
              <Button
                inProgress={updateInProgress}
                disabled={submitDisabled}
                className={css.submitButton}
              >
                {submitButtonText}
              </Button>
            )}
          </Form>
        );
      }}
    />
  );
};
