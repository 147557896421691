import React from 'react';
import { bool, func, node, number, string } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';

import css from './SearchFiltersPrimary.module.css';

const SearchFiltersPrimaryComponent = props => {
  const {
    rootClassName,
    className,
    children,
    sortByComponent,
    listingsAreLoaded,
    resultsCount,
    searchInProgress,
    isSecondaryFiltersOpen,
    toggleSecondaryFiltersOpen,
    selectedSecondaryFiltersCount,
    selectedFilters,
    resetFilter,
  } = props;

  const hasNoResult = listingsAreLoaded && resultsCount === 0;
  const classes = classNames(rootClassName || css.root, className);

  const toggleSecondaryFiltersOpenButtonClasses =
    isSecondaryFiltersOpen || selectedSecondaryFiltersCount > 0
      ? css.searchFiltersPanelOpen
      : css.searchFiltersPanelClosed;
  const toggleSecondaryFiltersOpenButton = toggleSecondaryFiltersOpen ? (
    <button
      className={toggleSecondaryFiltersOpenButtonClasses}
      onClick={() => {
        toggleSecondaryFiltersOpen(!isSecondaryFiltersOpen);
      }}
    >
      <FormattedMessage
        id="SearchFiltersPrimary.moreFiltersButton"
        values={{ count: selectedSecondaryFiltersCount }}
      />
    </button>
  ) : null;

  let filterSelected = selectedFilters
    ? Object.keys({ ...selectedFilters }).map(l => {
        if (l.split('_').length > 1) {
          return l.split('_')[1];
        } else {
          return l;
        }
      })
    : [];

  const selected = [selectedFilters].map(f => {
    let dates, identities, virtualSession, modalities;
    if (f.dates) {
      dates = 'Dates: ' + f.dates;
    }
    if (f.pub_identities) {
      identities = f.pub_identities?.split('has_any:')[1];
    }
    if (f.pub_virtualSession) {
      virtualSession = 'Virtual session';
    }
    if (f.pub_modalities) {
      modalities = f.pub_modalities?.split('has_any:')[1];
    }
    return { dates, identities, virtualSession, modalities };
  });

  const another = Object.entries(selected[0]).map(([k, v]) => {
    let keyVals = {};
    if (k === 'dates') {
      keyVals = { k, val: [v] };
    } else {
      keyVals = { k, val: v?.split(',') };
    }
    return keyVals;
  });

  function filterReset(c, l) {
    if (resetFilter) {
      resetFilter(c, l);
    }
  }
  return (
    <div className={classes}>
      <div className={css.searchOptions}>
        {/* {listingsAreLoaded ? (
          <div className={css.searchResultSummary}>
            <span className={css.resultsFound}>
              <FormattedMessage
                id="SearchFiltersPrimary.foundResults"
                values={{ count: resultsCount }}
              />
            </span>
          </div>
        ) : null}
        {sortByComponent} */}
      </div>

      <div className={css.filters}>
        {listingsAreLoaded ? (
          <div className={css.searchResultSummary}>
            <span className={css.resultsFound}>
              <FormattedMessage
                id="SearchFiltersPrimary.foundResults"
                values={{ count: resultsCount }}
              />
            </span>
          </div>
        ) : null}
        {sortByComponent}
        {children}
        {toggleSecondaryFiltersOpenButton}
      </div>
      <div style={{ display: 'flex', marginTop: '12px', flexWrap: 'wrap' }}>
        {another?.length > 0
          ? another?.map(l => {
              if (l.val && l.k != 'keywords' && l.val[0] !== undefined)
                return l.val?.map(c => (
                  <div className={css.activeTags}>
                    <span style={{ marginLeft: '6px', fontSize: '15px' }}>{c}</span>
                    <button
                      onClick={() => filterReset(c, l.k)}
                      style={{ border: 'none', cursor: 'pointer' }}
                    >
                      <b style={{ color: '#E6C200' }}>X</b>
                    </button>
                  </div>
                ));
            })
          : null}
        {/* {selectedOptions?.length > 0
          ? selectedOptions?.map(l => {
              if (l && l != 'keywords')
                return (
                  <div className={css.activeTags}>
                    <span style={{ marginLeft: '6px', fontSize: '15px' }}>{l}</span>

                    <button onClick={() => filterReset('male')} style={{ border: 'none' }}>
                      <b>X</b>
                    </button>
                  </div>
                );
            })
          : null} */}
      </div>
      {hasNoResult ? (
        <div className={css.noSearchResults}>
          <FormattedMessage id="SearchFiltersPrimary.noResults" />
        </div>
      ) : null}

      {searchInProgress ? (
        <div className={css.loadingResults}>
          <FormattedMessage id="SearchFiltersPrimary.loadingResults" />
        </div>
      ) : null}
    </div>
  );
};

SearchFiltersPrimaryComponent.defaultProps = {
  rootClassName: null,
  className: null,
  resultsCount: null,
  searchInProgress: false,
  isSecondaryFiltersOpen: false,
  toggleSecondaryFiltersOpen: null,
  selectedSecondaryFiltersCount: 0,
  sortByComponent: null,
};

SearchFiltersPrimaryComponent.propTypes = {
  rootClassName: string,
  className: string,
  listingsAreLoaded: bool.isRequired,
  resultsCount: number,
  searchInProgress: bool,
  isSecondaryFiltersOpen: bool,
  toggleSecondaryFiltersOpen: func,
  selectedSecondaryFiltersCount: number,
  sortByComponent: node,
};

const SearchFiltersPrimary = SearchFiltersPrimaryComponent;

export default SearchFiltersPrimary;
