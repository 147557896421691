import React from 'react';
import CheckboxGroup from '../../components/CustomGroupCheckbox/CustomGroupCheckbox';
import config from '../../config';
import css from '../../components/EditListingFeaturesPanel/EditListingFeaturesPanel.module.css';
import { Button } from '../../components';
import { injectIntl } from '../../util/reactIntl';
function FeaturedIdentities(props) {
  const {
    form,
    values,
    submitButtonClass,
    submitButtonType,
    submitButtonInProgress,
    submitButtonDisabled,
    submitButtonReady,
    isNewListingFlow,
    intl,
  } = props;

  let { saveActionMsg } = props;

  const isAnyIdentitySelected =
    (values && values.background_identities.length > 0) ||
    values.disability_identities.length > 0 ||
    values.featuredIdentities.length > 0 ||
    values.gender_identities.length > 0 ||
    values.misc_identities.length > 0 ||
    values.sexuality_identities.length > 0 ||
    values.spirituality_identities.length > 0 ||
    values?.class_background_identities?.length > 0;
  if (isAnyIdentitySelected && isNewListingFlow)
    saveActionMsg = intl.formatMessage({
      id: 'EditListingFeaturesForm.featuredIdentitiesSave&GotoNextStepButtonLabel',
    });

  const {
    background_identities = [],
    disability_identities = [],
    sexuality_identities = [],
    spirituality_identities = [],
    featured_identities = [],
    gender_identities = [],
    misc_identities = [],
    class_background_identities = [],
  } = values;

  const backgroundIdentities = config.custom.backgroundIdentities.filter(v =>
    background_identities.includes(v.key)
  );
  const classBackgroundIdentities = config.custom.classBackgroundIdentity.filter(v =>
    class_background_identities.includes(v.key)
  );

  const disabilityIdentities = config.custom.disabilitiesIdentity.filter(v =>
    disability_identities.includes(v.key)
  );

  const sexualityIdentities = config.custom.sexualityIdentities.filter(v =>
    sexuality_identities.includes(v.key)
  );

  const spiritualityIdentities = config.custom.spiritualityIdentities.filter(v =>
    spirituality_identities.includes(v.key)
  );
  const genderIdentities = config.custom.genderIdentities.filter(v =>
    gender_identities.includes(v.key)
  );
  const miscIdentities = config.custom.miscIdentities.filter(v => misc_identities.includes(v.key));

  const setSelected = value => form.change('featuredIdentities', value);
  const selected = values?.featuredIdentities ?? featured_identities ?? [];

  const allThreeSelected = selected.length == 3;

  const anyOptionSelectedFromIdentities = [
    ...backgroundIdentities,
    ...classBackgroundIdentities,
    ...disabilityIdentities,
    ...sexualityIdentities,
    ...spiritualityIdentities,
    ...genderIdentities,
    ...miscIdentities,
  ].length;

  // const isDisabledOnSelect = anyOptionSelectedFromIdentities.length > 0 && !allThreeSelected;
  // const isOptional = anyOptionSelectedFromIdentities.length == 0;

  // const pass =
  //   anyOptionSelectedFromIdentities.length > 0
  //     ? allThreeSelected
  //     : anyOptionSelectedFromIdentities.length == 0
  //     ? true
  //     : false;

  // let pass;

  // if (anyOptionSelectedFromIdentities == 0) {
  //   pass = true;
  // } else {
  //   if (allThreeSelected) {
  //     pass = true;
  //   } else {
  //     pass = false;
  //   }
  // }

  // let pass = anyOptionSelectedFromIdentities == 0 ? true : allThreeSelected ? true : false;
  let pass = anyOptionSelectedFromIdentities == 0 ? true : false;

  const submitDisabled = submitButtonDisabled;
  // || !pass;

  // console.log({ submitButtonDisabled, pass: !pass });

  const allOptions = [
    ...backgroundIdentities,
    ...classBackgroundIdentities,
    ...disabilityIdentities,
    ...genderIdentities,
    ...sexualityIdentities,
    ...spiritualityIdentities,
    ...miscIdentities,
  ];

  return (
    <div>
      {[
        ...backgroundIdentities,
        ...classBackgroundIdentities,
        ...disabilityIdentities,
        ...genderIdentities,
        ...sexualityIdentities,
        ...spiritualityIdentities,
        ...miscIdentities,
      ].length > 0 ? (
        <h1 className={css.panelTitle}>
          {intl.formatMessage({ id: 'EditListingFeaturesForm.FeaturedIdentitiesTitle' })}
        </h1>
      ) : null}
      {/* Background */}
      {allOptions.length > 0 ? (
        <div className="push-top-smedium">
          <label className="push-bottom-small">
            {/* Background{' '} */}
            <span className="grey typeLight">
              {intl.formatMessage({ id: 'EditListingFeaturesForm.FeaturedIdentitiesSubTitle' })}
            </span>
          </label>

          <div className="highlighted-top">
            <div className="tags flex">
              <CheckboxGroup name="Background" value={selected} onChange={setSelected}>
                {Checkbox => (
                  <>
                    {allOptions.map(v => (
                      <label
                        data-disabled={
                          selected.includes(v.key) ? 'false' : allThreeSelected ? 'true' : 'false'
                        }
                        className={` ${
                          selected.includes(v.key)
                            ? 'selected btn greyOutlineRounded'
                            : 'btn greyOutlineRounded'
                        }`}
                      >
                        <div style={{ display: 'none' }}>
                          <Checkbox
                            disabled={selected.includes(v.key) ? false : allThreeSelected}
                            value={v.key}
                          />
                        </div>
                        {v.label}
                      </label>
                    ))}
                  </>
                )}
              </CheckboxGroup>
            </div>
          </div>
        </div>
      ) : null}
      {/* {backgroundIdentities.length > 0 ? (
        <div className="push-top-smedium">
          <label className="push-bottom-small">
            Background{' '}
            <span className="grey typeLight">
              If you elect to feature up to 3 identities in this section, they will display at the
              top of your public Queer Healers profile and in search results. Note that ALL identity
              options that appear in this section will display on another area of your public
              profile and will be used to determine if you should appear in identity-specific search
              results.
            </span>
          </label>

          <div className="highlighted-top">
            <div className="tags flex">
              <CheckboxGroup name="Background" value={selected} onChange={setSelected}>
                {Checkbox => (
                  <>
                    {backgroundIdentities.map(v => (
                      <label
                        data-disabled={
                          selected.includes(v.key) ? 'false' : allThreeSelected ? 'true' : 'false'
                        }
                        className={` ${
                          selected.includes(v.key)
                            ? 'selected btn greyOutlineRounded'
                            : 'btn greyOutlineRounded'
                        }`}
                      >
                        <div style={{ display: 'none' }}>
                          <Checkbox
                            disabled={selected.includes(v.key) ? false : allThreeSelected}
                            value={v.key}
                          />
                        </div>
                        {v.label}
                      </label>
                    ))}
                  </>
                )}
              </CheckboxGroup>
            </div>
          </div>
        </div>
      ) : null} */}
      {/* Disability */}
      {/* {disabilityIdentities.length > 0 ? (
        <div className="push-top-smedium">
          <label className="push-bottom-small">
            Disability{' '}
            <span className="grey typeLight">
              If you elect to feature up to 3 identities in this section, they will display at the
              top of your public Queer Healers profile and in search results. Note that ALL identity
              options that appear in this section will display on another area of your public
              profile and will be used to determine if you should appear in identity-specific search
              results.
            </span>
          </label>

          <div className="highlighted-top">
            <div className="tags flex">
              <CheckboxGroup name="Disability" value={selected} onChange={setSelected}>
                {Checkbox => (
                  <>
                    {disabilityIdentities.map(v => (
                      <label
                        data-disabled={
                          selected.includes(v.key) ? 'false' : allThreeSelected ? 'true' : 'false'
                        }
                        className={` ${
                          selected.includes(v.key)
                            ? 'selected btn greyOutlineRounded'
                            : 'btn greyOutlineRounded'
                        }`}
                      >
                        <div style={{ display: 'none' }}>
                          <Checkbox
                            disabled={selected.includes(v.key) ? false : allThreeSelected}
                            value={v.key}
                          />
                        </div>
                        {v.label}
                      </label>
                    ))}
                  </>
                )}
              </CheckboxGroup>
            </div>
          </div>
        </div>
      ) : null} */}
      {/* Gender */}
      {/* {genderIdentities.length > 0 ? (
        <div className="push-top-smedium">
          <label className="push-bottom-small">
            Gender{' '}
            <span className="grey typeLight">
              If you elect to feature up to 3 identities in this section, they will display at the
              top of your public Queer Healers profile and in search results. Note that ALL identity
              options that appear in this section will display on another area of your public
              profile and will be used to determine if you should appear in identity-specific search
              results.
            </span>
          </label>

          <div className="highlighted-top">
            <div className="tags flex">
              <CheckboxGroup name="Gender" value={selected} onChange={setSelected}>
                {Checkbox => (
                  <>
                    {genderIdentities.map(v => (
                      <label
                        data-disabled={
                          selected.includes(v.key) ? 'false' : allThreeSelected ? 'true' : 'false'
                        }
                        className={` ${
                          selected.includes(v.key)
                            ? 'selected btn greyOutlineRounded'
                            : 'btn greyOutlineRounded'
                        }`}
                      >
                        <div style={{ display: 'none' }}>
                          <Checkbox
                            disabled={selected.includes(v.key) ? false : allThreeSelected}
                            value={v.key}
                          />
                        </div>
                        {v.label}
                      </label>
                    ))}
                  </>
                )}
              </CheckboxGroup>
            </div>
          </div>
        </div>
      ) : null} */}
      {/* Sexuality */}
      {/* {sexualityIdentities.length > 0 ? (
        <div className="push-top-smedium">
          <label className="push-bottom-small">
            Sexuality{' '}
            <span className="grey typeLight">
              If you elect to feature up to 3 identities in this section, they will display at the
              top of your public Queer Healers profile and in search results. Note that ALL identity
              options that appear in this section will display on another area of your public
              profile and will be used to determine if you should appear in identity-specific search
              results.
            </span>
          </label>

          <div className="highlighted-top">
            <div className="tags flex">
              <CheckboxGroup name="Sexuality" value={selected} onChange={setSelected}>
                {Checkbox => (
                  <>
                    {sexualityIdentities.map(v => (
                      <label
                        data-disabled={
                          selected.includes(v.key) ? 'false' : allThreeSelected ? 'true' : 'false'
                        }
                        className={` ${
                          selected.includes(v.key)
                            ? 'selected btn greyOutlineRounded'
                            : 'btn greyOutlineRounded'
                        }`}
                      >
                        <div style={{ display: 'none' }}>
                          <Checkbox
                            disabled={selected.includes(v.key) ? false : allThreeSelected}
                            value={v.key}
                          />
                        </div>
                        {v.label}
                      </label>
                    ))}
                  </>
                )}
              </CheckboxGroup>
            </div>
          </div>
        </div>
      ) : null} */}
      {/* Spirituality */}
      {/* {spiritualityIdentities.length > 0 ? (
        <div className="push-top-smedium">
          <label className="push-bottom-small">
            Spirituality{' '}
            <span className="grey typeLight">
              If you elect to feature up to 3 identities in this section, they will display at the
              top of your public Queer Healers profile and in search results. Note that ALL identity
              options that appear in this section will display on another area of your public
              profile and will be used to determine if you should appear in identity-specific search
              results.
            </span>
          </label>

          <div className="highlighted-top">
            <div className="tags flex">
              <CheckboxGroup name="Spirituality" value={selected} onChange={setSelected}>
                {Checkbox => (
                  <>
                    {spiritualityIdentities.map(v => (
                      <label
                        data-disabled={
                          selected.includes(v.key) ? 'false' : allThreeSelected ? 'true' : 'false'
                        }
                        className={` ${
                          selected.includes(v.key)
                            ? 'selected btn greyOutlineRounded'
                            : 'btn greyOutlineRounded'
                        }`}
                      >
                        <div style={{ display: 'none' }}>
                          <Checkbox
                            disabled={selected.includes(v.key) ? false : allThreeSelected}
                            value={v.key}
                          />
                        </div>
                        {v.label}
                      </label>
                    ))}
                  </>
                )}
              </CheckboxGroup>
            </div>
          </div>
        </div>
      ) : null} */}

      {/* Misc identities */}
      {/* {miscIdentities.length > 0 ? (
        <div className="push-top-smedium">
          <label className="push-bottom-small">
            Other{' '}
            <span className="grey typeLight">
              If you elect to feature up to 3 identities in this section, they will display at the
              top of your public Queer Healers profile and in search results. Note that ALL identity
              options that appear in this section will display on another area of your public
              profile and will be used to determine if you should appear in identity-specific search
              results.
            </span>
          </label>

          <div className="highlighted-top">
            <div className="tags flex">
              <CheckboxGroup name="Misc" value={selected} onChange={setSelected}>
                {Checkbox => (
                  <>
                    {miscIdentities.map(v => (
                      <label
                        data-disabled={
                          selected.includes(v.key) ? 'false' : allThreeSelected ? 'true' : 'false'
                        }
                        className={` ${
                          selected.includes(v.key)
                            ? 'selected btn greyOutlineRounded'
                            : 'btn greyOutlineRounded'
                        }`}
                      >
                        <div style={{ display: 'none' }}>
                          <Checkbox
                            disabled={selected.includes(v.key) ? false : allThreeSelected}
                            value={v.key}
                          />
                        </div>
                        {v.label}
                      </label>
                    ))}
                  </>
                )}
              </CheckboxGroup>
            </div>
          </div>
        </div>
      ) : null} */}

      <Button
        className={submitButtonClass}
        type={submitButtonType}
        inProgress={submitButtonInProgress}
        disabled={submitDisabled}
        ready={submitButtonReady}
      >
        {saveActionMsg}
      </Button>
    </div>
  );
}

export default injectIntl(FeaturedIdentities);
