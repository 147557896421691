import React, { useEffect, useState } from 'react';
import { bool, func, shape, string } from 'prop-types';
import classNames from 'classnames';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { FormattedMessage } from '../../util/reactIntl';
import { findOptionsForSelectFilter } from '../../util/search';
import { propTypes } from '../../util/types';
import config from '../../config';
import {
  Button,
  FieldCheckboxGroup,
  FieldCheckbox,
  Form,
  IconEdit,
  GoBackButton,
} from '../../components';

import css from './EditListingFeaturesForm.module.css';
import { FaPlusCircle } from 'react-icons/fa';
import { Modal } from 'react-responsive-modal';
import FieldCheckboxGroupCustom from '../../components/FieldCheckboxGroupCustom/FieldCheckboxGroupCustom';
import FeaturedIdentities from './FeaturedIdentities';

const EditListingFeaturesFormComponent = props => (
  <FinalForm
    {...props}
    mutators={{ ...arrayMutators }}
    render={formRenderProps => {
      const {
        disabled,
        ready,
        rootClassName,
        className,
        name,
        handleSubmit,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
        filterConfig,
        form,
        values,
        goBackLink,
        isNewListingFlow,
        initialValues,
        intl,
      } = formRenderProps;

      const [open, setOpen] = useState(false);
      const [identityType, setIdentityType] = useState('');

      const classes = classNames(rootClassName || css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = disabled || submitInProgress;
      const { updateListingError, showListingsError } = fetchErrors || {};
      const errorMessage = updateListingError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingFeaturesForm.updateFailed" />
        </p>
      ) : null;

      const errorMessageShowListing = showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingFeaturesForm.showListingFailed" />
        </p>
      ) : null;

      const options = findOptionsForSelectFilter('healerStyles', filterConfig);

      const [modalitiesOpened, setModalitiesOpened] = React.useState({});

      //deduped mod types
      const modTypes = options
        .map((option, i) => option.modType)
        .filter((modType, i, arr) => arr.indexOf(modType) === i);

      // iterate through all modTypes create Object
      const modObjects = modTypes.map(modType => {
        const optionsForModType = options
          // filter through ALL options for only ones relevant to this current mod type
          .filter(option => option.modType === modType);
        return {
          modType,
          optionsForModType,
        };
      });

      function onModalityClick(modType) {
        return setModalitiesOpened({ ...modalitiesOpened, [modType]: !modalitiesOpened[modType] });
      }

      const onCloseModal = () => setOpen(false);

      const handleClick = (value, key) => {
        const oldValues = values?.[key] || [];
        form.change(
          key,
          oldValues.includes(value) ? oldValues.filter(v => v != value) : [...oldValues, value]
        );
      };

      let content;
      switch (identityType) {
        case 'background_identities':
          content = (
            <div className="walkthroughModal">
              <h3>
                {intl.formatMessage({ id: 'EditListingFeaturesForm.backgroundIdentitiesTitle' })}
              </h3>
              <div className="push-top-smedium"></div>
              {/* <ul
                className="tags"
                onClick={e => handleClick(e.target.dataset.name, 'background_identities')}
              >
                {config.custom.backgroundIdentities.map(l => (
                  <li
                    key={l.key}
                    data-name={l.key}
                    className={values?.['background_identities']?.includes(l.key) ? 'selected' : ''}
                  >
                    {l.label}
                  </li>
                ))}
              </ul> */}
              <FieldCheckboxGroupCustom
                options={config.custom.backgroundIdentities}
                name={'background_identities'}
                id={'background_identities'}
                label={intl.formatMessage({
                  id: 'EditListingFeaturesForm.backgroundIdentitiesLabel',
                })}
                values={values?.['background_identities']}
              />
            </div>
          );
          break;
        case 'class_background_identities':
          content = (
            <div className="walkthroughModal">
              <h3>
                {intl.formatMessage({
                  id: 'EditListingFeaturesForm.classBackgroundIdentitiesTitle',
                })}
              </h3>
              <div className="push-top-smedium"></div>
              {/* <ul
                className="tags"
                onClick={e => handleClick(e.target.dataset.name, 'background_identities')}
              >
                {config.custom.backgroundIdentities.map(l => (
                  <li
                    key={l.key}
                    data-name={l.key}
                    className={values?.['background_identities']?.includes(l.key) ? 'selected' : ''}
                  >
                    {l.label}
                  </li>
                ))}
              </ul> */}
              <FieldCheckboxGroupCustom
                options={config.custom.classBackgroundIdentity}
                name={'class_background_identities'}
                id={'class_background_identities'}
                label={intl.formatMessage({
                  id: 'EditListingFeaturesForm.classBackgroundIdentitiesLabel',
                })}
                values={values?.['class_background_identities']}
              />
            </div>
          );
          break;
        case 'disability_identities':
          content = (
            <div className="walkthroughModal">
              <h3>
                {intl.formatMessage({ id: 'EditListingFeaturesForm.disabilityIdentitiesTitle' })}
              </h3>
              <div className="push-top-smedium"></div>
              {/* <ul
                className="tags"
                onClick={e => handleClick(e.target.dataset.name, 'disability_identities')}
              >
                {config.custom.disabilitiesIdentity.map(l => (
                  <li
                    key={l.key}
                    data-name={l.key}
                    className={values?.['disability_identities']?.includes(l.key) ? 'selected' : ''}
                  >
                    {l.label}
                  </li>
                ))}
              </ul> */}
              <FieldCheckboxGroupCustom
                options={config.custom.disabilitiesIdentity}
                name={'disability_identities'}
                id={'disability_identities'}
                label={intl.formatMessage({
                  id: 'EditListingFeaturesForm.disabilityIdentitiesLabel',
                })}
                values={values?.['disability_identities']}
              />
            </div>
          );
          break;
        case 'sexuality_identities':
          content = (
            <div className="walkthroughModal">
              <h3>{intl.formatMessage({ id: 'EditListingFeaturesForm.sexualIdentitiesTitle' })}</h3>
              <div className="push-top-smedium"></div>
              {/* <ul
                className="tags"
                onClick={e => handleClick(e.target.dataset.name, 'sexuality_identities')}
              >
                {config.custom.sexualityIdentities.map(l => (
                  <li
                    key={l.key}
                    data-name={l.key}
                    className={values?.['sexuality_identities']?.includes(l.key) ? 'selected' : ''}
                  >
                    {l.label}
                  </li>
                ))}
              </ul> */}
              <FieldCheckboxGroupCustom
                options={config.custom.sexualityIdentities}
                name={'sexuality_identities'}
                id={'sexuality_identities'}
                label={intl.formatMessage({
                  id: 'EditListingFeaturesForm.sexualityIdentitiesLabel',
                })}
                values={values?.['sexuality_identities']}
              />
            </div>
          );
          break;
        case 'spirituality_identities':
          content = (
            <div className="walkthroughModal">
              <h3>
                {intl.formatMessage({ id: 'EditListingFeaturesForm.spiritualityIdentitiesTitle' })}
              </h3>
              <div className="push-top-smedium"></div>
              {/* <ul
                className="tags"
                onClick={e => handleClick(e.target.dataset.name, 'spirituality_identities')}
              >
                {config.custom.spiritualityIdentities.map(l => (
                  <li
                    key={l.key}
                    data-name={l.key}
                    className={
                      values?.['spirituality_identities']?.includes(l.key) ? 'selected' : ''
                    }
                  >
                    {l.label}
                  </li>
                ))}
              </ul> */}
              <FieldCheckboxGroupCustom
                options={config.custom.spiritualityIdentities}
                name={'spirituality_identities'}
                id={'spirituality_identities'}
                label={intl.formatMessage({
                  id: 'EditListingFeaturesForm.spiritualityIdentitiesLabel',
                })}
                values={values?.['spirituality_identities']}
              />
            </div>
          );
          break;
        case 'misc_identities':
          content = (
            <div className="walkthroughModal">
              <h3>
                {intl.formatMessage({
                  id: 'EditListingFeaturesForm.livedExperienceIdentitiesTitle',
                })}
              </h3>
              <div className="push-top-smedium"></div>
              {/* <ul
                className="tags"
                onClick={e => handleClick(e.target.dataset.name, 'misc_identities')}
              >
                {config.custom.miscIdentities.map(l => (
                  <li
                    key={l.key}
                    data-name={l.key}
                    className={values?.['misc_identities']?.includes(l.key) ? 'selected' : ''}
                  >
                    {l.label}
                  </li>
                ))}
              </ul> */}
              <FieldCheckboxGroupCustom
                options={config.custom.miscIdentities}
                name={'misc_identities'}
                id={'misc_identities'}
                label={intl.formatMessage({ id: 'EditListingFeaturesForm.miscIdentitiesLabel' })}
                values={values?.['misc_identities']}
              />
            </div>
          );
          break;
        case 'gender_identities':
          content = (
            <div className="walkthroughModal">
              <h3>{intl.formatMessage({ id: 'EditListingFeaturesForm.genderIdentitiesTitle' })}</h3>
              <div className="push-top-smedium"></div>
              {/* <ul
                className="tags"
                onClick={e => handleClick(e.target.dataset.name, 'gender_identities')}
              >
                {config.custom.miscIdentities.map(l => (
                  <li
                    key={l.key}
                    data-name={l.key}
                    className={values?.['gender_identities']?.includes(l.key) ? 'selected' : ''}
                  >
                    {l.label}
                  </li>
                ))}
              </ul> */}
              <FieldCheckboxGroupCustom
                options={config.custom.genderIdentities}
                name={'gender_identities'}
                id={'gender_identities'}
                label={intl.formatMessage({ id: 'EditListingFeaturesForm.genderIdentitiesLabel' })}
                values={values?.['gender_identities']}
              />
            </div>
          );
          break;
      }

      const modal = (
        <Modal open={open} onClose={onCloseModal} center>
          {content}
        </Modal>
      );

      useEffect(() => {
        const featuredIdentities = values?.featuredIdentities ?? [];

        if (featuredIdentities.length > 0) {
          const allVals = [
            ...(values?.background_identities ?? []),
            ...(values?.class_background_identities ?? []),
            ...(values?.disability_identities ?? []),
            ...(values?.gender_identities ?? []),
            ...(values?.sexuality_identities ?? []),
            ...(values?.spirituality_identities ?? []),
            ...(values?.misc_identities ?? []),
          ];

          const filtered = featuredIdentities.filter(f => allVals.includes(f));
          form.change('featuredIdentities', filtered);
        }
      }, [
        values?.background_identities,
        values?.class_background_identities,
        values?.disability_identities,
        values?.gender_identities,
        values?.sexuality_identities,
        values?.spirituality_identities,
        values?.misc_identities,
      ]);

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          {errorMessage}
          {errorMessageShowListing}

          {modal}

          <div className="push-top-smedium">
            <label className="push-bottom-small">
              {intl.formatMessage({ id: 'EditListingFeaturesForm.backgroundIdentitiesHeader' })}{' '}
              <span className="grey typeLight">
                {intl.formatMessage({ id: 'EditListingFeaturesForm.backgroundIdentitiesSubText' })}
              </span>
            </label>

            <div className="tagsContainer">
              <div className="tags">
                {config.custom.backgroundIdentities
                  .filter(f => (values?.['background_identities'] || []).includes(f.key))
                  .map(l => (
                    <p key={l.key} data-list-item>
                      {l.label}
                    </p>
                  ))}
              </div>
            </div>

            <button
              type="button"
              className="btn-large-outline"
              onClick={() => {
                setIdentityType('background_identities');
                setOpen(true);
              }}
            >
              <IconEdit className={css.icon} pencilClassName={css.iconEditPencil} />{' '}
              {intl.formatMessage({ id: 'EditListingFeaturesForm.addEditButtonPlaceholder' })}
            </button>
          </div>
          <div className="push-top-smedium">
            <label className="push-bottom-small">
              {intl.formatMessage({ id: 'EditListingFeaturesForm.classIdentitesHeading' })}{' '}
              <span className="grey typeLight">
                {intl.formatMessage({ id: 'EditListingFeaturesForm.classIdentitiesSubText' })}
              </span>
            </label>

            <div className="tagsContainer">
              <div className="tags">
                {config.custom.classBackgroundIdentity
                  .filter(f => (values?.['class_background_identities'] || []).includes(f.key))
                  .map(l => (
                    <p key={l.key} data-list-item>
                      {l.label}
                    </p>
                  ))}
              </div>
            </div>

            <button
              type="button"
              className="btn-large-outline"
              onClick={() => {
                setIdentityType('class_background_identities');
                setOpen(true);
              }}
            >
              <IconEdit className={css.icon} pencilClassName={css.iconEditPencil} />{' '}
              {intl.formatMessage({ id: 'EditListingFeaturesForm.addEditButtonPlaceholder' })}
            </button>
          </div>
          <div className="push-top-smedium">
            <label className="push-bottom-small">
              {intl.formatMessage({ id: 'EditListingFeaturesForm.disabilityIdentitiesHeading' })}{' '}
              <span className="grey typeLight">
                {intl.formatMessage({ id: 'EditListingFeaturesForm.disabilityIdentitiesSubText' })}
              </span>
            </label>
            <div className="tagsContainer">
              <div className="tags">
                {config.custom.disabilitiesIdentity
                  .filter(f => (values?.['disability_identities'] || []).includes(f.key))
                  .map(l => (
                    <p key={l.key} data-list-item>
                      {l.label}
                    </p>
                  ))}
              </div>
            </div>
            <button
              type="button"
              className="btn-large-outline"
              onClick={() => {
                setIdentityType('disability_identities');
                setOpen(true);
              }}
            >
              <IconEdit className={css.icon} pencilClassName={css.iconEditPencil} />{' '}
              {intl.formatMessage({ id: 'EditListingFeaturesForm.addEditButtonPlaceholder' })}
            </button>
          </div>

          <div className="push-top-smedium">
            <label className="push-bottom-small">
              {intl.formatMessage({ id: 'EditListingFeaturesForm.genderIdentitiesHeading' })}{' '}
              <span className="grey typeLight">
                {intl.formatMessage({ id: 'EditListingFeaturesForm.genderIdentitiesSubText' })}
              </span>
            </label>
            <div className="tagsContainer">
              <div className="tags">
                {config.custom.genderIdentities
                  .filter(f => (values?.['gender_identities'] || []).includes(f.key))
                  .map(l => (
                    <p key={l.key} data-list-item>
                      {l.label}
                    </p>
                  ))}
              </div>
            </div>
            <button
              type="button"
              className="btn-large-outline"
              onClick={() => {
                setIdentityType('gender_identities');
                setOpen(true);
              }}
            >
              <IconEdit className={css.icon} pencilClassName={css.iconEditPencil} />{' '}
              {intl.formatMessage({ id: 'EditListingFeaturesForm.addEditButtonPlaceholder' })}
            </button>
          </div>

          <div className="push-top-smedium">
            <label className="push-bottom-small">
              {intl.formatMessage({ id: 'EditListingFeaturesForm.livedExperienceHeading' })}{' '}
              <span className="grey typeLight">
                {intl.formatMessage({ id: 'EditListingFeaturesForm.livedExperienceSubText' })}
              </span>
            </label>

            <div className="tagsContainer">
              <div className="tags">
                {config.custom.miscIdentities
                  .filter(f => (values?.['misc_identities'] || []).includes(f.key))
                  .map(l => (
                    <p key={l.key} data-list-item>
                      {l.label}
                    </p>
                  ))}
              </div>
            </div>

            <button
              type="button"
              className="btn-large-outline"
              onClick={() => {
                setIdentityType('misc_identities');
                setOpen(true);
              }}
            >
              <IconEdit className={css.icon} pencilClassName={css.iconEditPencil} />{' '}
              {intl.formatMessage({ id: 'EditListingFeaturesForm.addEditButtonPlaceholder' })}
            </button>
          </div>
          <div className="push-top-smedium">
            <label className="push-bottom-small">
              {intl.formatMessage({ id: 'EditListingFeaturesForm.sexualityIdentitiesHeading' })}{' '}
              <span className="grey typeLight">
                {intl.formatMessage({ id: 'EditListingFeaturesForm.sexualityIdentitiesSubText' })}
              </span>
            </label>
            <div className="tagsContainer">
              <div className="tags">
                {config.custom.sexualityIdentities
                  .filter(f => (values?.['sexuality_identities'] || []).includes(f.key))
                  .map(l => (
                    <p key={l.key} data-list-item>
                      {l.label}
                    </p>
                  ))}
              </div>
            </div>
            <button
              type="button"
              className="btn-large-outline"
              onClick={() => {
                setIdentityType('sexuality_identities');
                setOpen(true);
              }}
            >
              <IconEdit className={css.icon} pencilClassName={css.iconEditPencil} />{' '}
              {intl.formatMessage({ id: 'EditListingFeaturesForm.addEditButtonPlaceholder' })}
            </button>
          </div>
          <div className="push-top-smedium">
            <label className="push-bottom-small">
              {intl.formatMessage({ id: 'EditListingFeaturesForm.spiritualityIdentitiesHeading' })}{' '}
              <span className="grey typeLight">
                {intl.formatMessage({
                  id: 'EditListingFeaturesForm.spiritualityIdentitiesSubText',
                })}
              </span>
            </label>
            <div className="tagsContainer">
              <div className="tags">
                {config.custom.spiritualityIdentities
                  .filter(f => (values?.['spirituality_identities'] || []).includes(f.key))
                  .map(l => (
                    <p key={l.key} data-list-item>
                      {l.label}
                    </p>
                  ))}
              </div>
            </div>
            <button
              type="button"
              className="btn-large-outline"
              onClick={() => {
                setIdentityType('spirituality_identities');
                setOpen(true);
              }}
            >
              <IconEdit className={css.icon} pencilClassName={css.iconEditPencil} />{' '}
              {intl.formatMessage({ id: 'EditListingFeaturesForm.addEditButtonPlaceholder' })}
            </button>
          </div>

          <FeaturedIdentities
            values={values}
            form={form}
            submitButtonClass={css.submitButton}
            submitButtonType="submit"
            submitButtonInProgress={submitInProgress}
            submitButtonDisabled={submitDisabled}
            submitButtonReady={submitReady}
            saveActionMsg={saveActionMsg}
            isNewListingFlow={isNewListingFlow}
          />
          <GoBackButton url={goBackLink} />

          {/* <Button
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
            ready={submitReady}
          >
            {saveActionMsg}
          </Button> */}
        </Form>
      );
    }}
  />
);

EditListingFeaturesFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  fetchErrors: null,
  filterConfig: config.custom.filters,
};

EditListingFeaturesFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  name: string.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  filterConfig: propTypes.filterConfig,
};

const EditListingFeaturesForm = EditListingFeaturesFormComponent;

export default EditListingFeaturesForm;
