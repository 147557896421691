import React, { useMemo, useState } from 'react';
import { bool, func, object, string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { ensureOwnListing } from '../../util/data';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { ListingLink } from '../../components';
import { EditListingDescriptionForm } from '../../forms';

import css from './EditListingDescriptionPanel.module.css';
import config from '../../config';
import { useSelector } from 'react-redux';

const EditListingDescriptionPanel = props => {
  const {
    className,
    rootClassName,
    listing,
    disabled,
    ready,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
    images,
    onImageUpload,
    onRemoveImage,
    onUpdateImageOrder,
  } = props;

  const [updatedValues, setUpdatedValues] = useState(undefined);
  const [listingTitleSlug, setListingTitleSlug] = useState(null);
  const slug = useMemo(() => {
    return listingTitleSlug;
  }, [listingTitleSlug]);
  const classes = classNames(rootClassName || css.root, className);
  const state = useSelector(state => state.EditListingPage);
  const { updateListingTitleInProgress, updateListingTitleError } = state;
  const currentListing = ensureOwnListing(listing);
  const { description, title, publicData, geolocation, metadata } = currentListing.attributes;

  const {
    display_name,
    legal_first_name,
    legal_last_name,
    preferred_first_name,
    business_name,
    location,
    legal_middle_name,
    post_nominal_letters,
    pronouns,
    date_of_birth,
    languages_spoken,
    accrediations,
    past_customer_review_1,
    past_customer_review_2,
    country_of_origin,
    other_pronouns,
    pronouns_option,
  } = publicData || {};

  // Only render current search if full place object is available in the URL params
  // TODO bounds are missing - those need to be queried directly from Google Places
  const locationFieldsPresent =
    publicData && publicData.location && publicData.location.address && geolocation;
  const locationValue = publicData && publicData.location ? publicData.location : {};
  const { address, building } = locationValue;

  const locationInitial = {
    building,
    location: locationFieldsPresent
      ? {
          search: address,
          selectedPlace: { address, origin: geolocation },
        }
      : null,
  };

  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
  const panelTitle = isPublished ? (
    <FormattedMessage
      id="EditListingDescriptionPanel.title"
      values={{
        listingTitle: (
          <ListingLink listing={listing}>
            <FormattedMessage id="EditListingDescriptionPanel.listingTitle" />
          </ListingLink>
        ),
      }}
    />
  ) : (
    <FormattedMessage id="EditListingDescriptionPanel.createListingTitle" />
  );

  // const initialValues = {
  //   // title,
  //   description,
  //   display_name: title,
  //   legal_first_name,
  //   legal_last_name,
  //   preferred_first_name,
  //   business_name,
  //   location,
  //   legal_middle_name,
  //   post_nominal_letters,
  //   pronouns,
  //   date_of_birth,
  //   languages_spoken,
  //   accrediations,
  //   past_customer_reviews,
  //   ...locationInitial,
  //   images,
  // };

  const initialValues = useMemo(() => {
    if (typeof updatedValues == 'undefined')
      return {
        // title,
        description,
        display_name: title,
        // legal_first_name,
        // legal_last_name,
        preferred_first_name,
        business_name,
        location,
        country_of_origin,
        // legal_middle_name,
        post_nominal_letters,
        pronouns:
          typeof pronouns == 'undefined'
            ? []
            : (pronouns ?? []).map(p => config.custom.pronouns.find(pn => pn.key == p)),
        other_pronouns:
          typeof other_pronouns == 'undefined' ? 'prefer_not_to_share_my_pronouns' : other_pronouns,
        pronouns_option:
          typeof pronouns_option == 'undefined' ? 'display_my_pronouns' : pronouns_option,
        date_of_birth,
        languages_spoken:
          languages_spoken && Array.isArray(languages_spoken)
            ? languages_spoken.map(v => config.custom.languages.find(l => l.key == v))
            : [],
        accrediations,
        past_customer_review_1,
        past_customer_review_2,
        ...locationInitial,
        images,
        listingTitle: slug ?? metadata?.listingTitle,
      };

    return {
      ...updatedValues,
    };
  }, [updatedValues, listingTitleSlug]);

  return (
    <div className={classes}>
      <div className="hWalkthroughForm">
        {/* <h6>Let's Get Started</h6> */}
        <h1 className={css.panelTitle}>{panelTitle}</h1>
        <EditListingDescriptionForm
          className={css.form}
          initialValues={{ ...initialValues, listingTitle: slug ?? metadata?.listingTitle }}
          saveActionMsg={submitButtonText}
          // images={images}
          currentListing={currentListing}
          onImageUpload={onImageUpload}
          onRemoveImage={onRemoveImage}
          onUpdateImageOrder={onUpdateImageOrder}
          onSubmit={values => {
            const {
              display_name,
              title,
              description,
              // legal_first_name,
              // legal_last_name,
              preferred_first_name,
              business_name,
              location,
              // legal_middle_name,
              post_nominal_letters,
              pronouns = [],
              date_of_birth,
              languages_spoken = [],
              accrediations,
              past_customer_review_1,
              past_customer_review_2,
              'listing-photos': uploadImages = [],
              country_of_origin,
              other_pronouns,
              pronouns_option,
              listingTitle,
            } = values;
            setListingTitleSlug(listingTitle);
            const languagesSpokenValues = languages_spoken.map(v => v.key);
            const languages_spoken_keyword = languages_spoken.map(v => v.label).toString();
            const {
              selectedPlace: { origin, address },
            } = location;

            let updateValues = {
              title: display_name.trim(),
              description,
              geolocation: origin,
              publicData: {
                // legal_middle_name,
                post_nominal_letters,
                pronouns: pronouns.map(p => p.key),
                other_pronouns,
                pronouns_option,

                date_of_birth: new Date(date_of_birth).toISOString(),
                languages_spoken: languagesSpokenValues,
                languages_spoken_keyword: languages_spoken_keyword,
                accrediations,
                past_customer_review_1,
                past_customer_review_2,
                // legal_first_name,
                // legal_last_name,
                preferred_first_name,
                business_name,
                country_of_origin,
                tempSlug: listingTitle,
                location: {
                  address,
                  building: '',
                },
              },
            };

            if (uploadImages.length > 0) {
              updateValues = {
                ...updateValues,
                images: uploadImages.map(i => ({ ...i, imageId: i.uuid })),
              };
            } else {
              updateValues = {
                ...updateValues,
                images: [],
              };
            }

            setUpdatedValues({
              ...updateValues,
              ...updateValues.publicData,
              languages_spoken,
              display_name: display_name.trim(),
              pronouns,
              other_pronouns,
              pronouns_option,

              location: {
                search: address,
                selectedPlace: { address, origin },
              },
            });

            // return console.log({ newInitialValues: initialValues, updateValues });

            // console.log(updateValues);
            // return console.log(values);

            onSubmit(updateValues);
          }}
          onChange={onChange}
          disabled={disabled}
          ready={ready}
          updated={panelUpdated}
          updateInProgress={updateInProgress}
          fetchErrors={errors}
          updateListingTitleError={updateListingTitleError}
          updateListingTitleInProgress={updateListingTitleInProgress}
          showtickMarkState={metadata?.listingTitle ? true : false}
        />
      </div>
    </div>
  );
};

EditListingDescriptionPanel.defaultProps = {
  className: null,
  rootClassName: null,
  errors: null,
  listing: null,
};

EditListingDescriptionPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EditListingDescriptionPanel;
