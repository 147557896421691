import React, { useState, useMemo, useEffect } from 'react';
import { arrayOf, bool, func, object, string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage, useIntl } from '../../util/reactIntl';
import { ensureOwnListing } from '../../util/data';
import { getDefaultTimeZoneOnBrowser, timestampToDate } from '../../util/dates';
import { LISTING_STATE_DRAFT, DATE_TYPE_DATETIME, propTypes } from '../../util/types';
import {
  Button,
  EditListingStripePanel,
  IconClose,
  IconEdit,
  IconSpinner,
  IconSuccess,
  InlineTextButton,
  ListingLink,
  Modal,
  TimeRange,
} from '../../components';
// import { ToastContainer } from 'material-react-toastify';
import { EditListingAvailabilityPlanForm, EditListingAvailabilityExceptionForm } from '../../forms';
import { MdOutlineKeyboardArrowDown, MdOutlineKeyboardArrowUp } from 'react-icons/md';
import css from './EditListingAvailabilityPanel.module.css';
import GoBackButton from '../GoBackButton/GoBackButton';
import Toggle from 'react-toggle';
import OwnBookingPlatformForm from './OwnBookingPlatformForm';
import { currentUserHasMembership } from '../../containers/MembershipPage/utils';
import { useDispatch, useSelector } from 'react-redux';
import OwnBookingPlatformModal from './OwnBookingPlatformModal';
import MembershipPaymentPage from '../../containers/MembershipPage/MembershipPaymentPage';
import {
  handleFreeState,
  handleUpdateListing,
} from '../../containers/EditListingPage/EditListingPage.duck';
import { showToast } from '../../util/toast';
import config from '../../config';
import { updateSelectedOption } from '../../ducks/user.duck';

const WEEKDAYS = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];

// We want to sort exceptions on the client-side, maximum pagination page size is 100,
// so we need to restrict the amount of exceptions to that.
const MAX_EXCEPTIONS_COUNT = 100;

const defaultTimeZone = () =>
  typeof window !== 'undefined' ? getDefaultTimeZoneOnBrowser() : 'Etc/UTC';

/////////////
// Weekday //
/////////////
const findEntry = (availabilityPlan, dayOfWeek) =>
  availabilityPlan.entries.find(d => d.dayOfWeek === dayOfWeek);

const getEntries = (availabilityPlan, dayOfWeek) =>
  availabilityPlan.entries.filter(d => d.dayOfWeek === dayOfWeek);

const Weekday = props => {
  const { availabilityPlan, dayOfWeek, openEditModal } = props;
  const hasEntry = findEntry(availabilityPlan, dayOfWeek);

  return (
    <div
      className={classNames(css.weekDay, { [css.blockedWeekDay]: !hasEntry })}
      onClick={() => openEditModal(true)}
      role="button"
    >
      <div className={css.dayOfWeek}>
        <FormattedMessage id={`EditListingAvailabilityPanel.dayOfWeek.${dayOfWeek}`} />
      </div>
      <div className={css.entries}>
        {availabilityPlan && hasEntry
          ? getEntries(availabilityPlan, dayOfWeek).map(e => {
              return (
                <span className={css.entry} key={`${e.dayOfWeek}${e.startTime}`}>{`${
                  e.startTime
                } - ${e.endTime === '00:00' ? '24:00' : e.endTime}`}</span>
              );
            })
          : null}
      </div>
    </div>
  );
};

///////////////////////////////////////////////////
// EditListingAvailabilityExceptionPanel - utils //
///////////////////////////////////////////////////

// Create initial entry mapping for form's initial values
const createEntryDayGroups = (entries = {}) =>
  entries.reduce((groupedEntries, entry) => {
    const { startTime, endTime: endHour, dayOfWeek } = entry;
    const dayGroup = groupedEntries[dayOfWeek] || [];
    return {
      ...groupedEntries,
      [dayOfWeek]: [
        ...dayGroup,
        {
          startTime,
          endTime: endHour === '00:00' ? '24:00' : endHour,
        },
      ],
    };
  }, {});

// Create initial values
const createInitialValues = availabilityPlan => {
  const { timezone, entries } = availabilityPlan || {};
  const tz = timezone || defaultTimeZone();
  return {
    timezone: tz,
    ...createEntryDayGroups(entries),
  };
};

// Create entries from submit values
const createEntriesFromSubmitValues = values =>
  WEEKDAYS.reduce((allEntries, dayOfWeek) => {
    const dayValues = values[dayOfWeek] || [];
    const dayEntries = dayValues.map(dayValue => {
      const { startTime, endTime } = dayValue;
      // Note: This template doesn't support seats yet.
      return startTime && endTime
        ? {
            dayOfWeek,
            seats: 1,
            startTime,
            endTime: endTime === '24:00' ? '00:00' : endTime,
          }
        : null;
    });

    return allEntries.concat(dayEntries.filter(e => !!e));
  }, []);

// Create availabilityPlan from submit values
const createAvailabilityPlan = values => ({
  availabilityPlan: {
    type: 'availability-plan/time',
    timezone: values.timezone,
    entries: createEntriesFromSubmitValues(values),
  },
  publicData: {
    showFree: true,
    showPaid: false,
  },
});

// Ensure that the AvailabilityExceptions are in sensible order.
//
// Note: if you allow fetching more than 100 exception,
// pagination kicks in and that makes client-side sorting impossible.
const sortExceptionsByStartTime = (a, b) => {
  return a.attributes.start.getTime() - b.attributes.start.getTime();
};

//////////////////////////////////
// EditListingAvailabilityPanel //
//////////////////////////////////
const EditListingAvailabilityPanel = props => {
  const {
    className,
    rootClassName,
    listing,
    availabilityExceptions,
    fetchExceptionsInProgress,
    onAddAvailabilityException,
    onDeleteAvailabilityException,
    disabled,
    ready,
    onSubmit: onTabSubmit,
    onManageDisableScrolling,
    onNextTab,
    submitButtonText,
    updateInProgress,
    errors,
    goBackLink,
    onShowPayoutModal,
    pageURLParams,
    selectedOption,
  } = props;
  const { publicData } = listing.attributes || {};
  const { isFree, paid, saved = false, showFree, showPaid } = publicData || {};

  const dispatch = useDispatch();
  const state = useSelector(state => state.EditListingPage);
  const { freeState } = state;
  // console.log('freeState', freeState);
  const free = showFree ? true : false;
  const isPaid = showPaid ? true : false;
  const onSubmit = onTabSubmit();
  // Hooks
  const [showFirstText, setShowFirstText] = useState(false);
  const [showSecondText, setShowSecondText] = useState(false);
  const intl = useIntl();
  const currentUser = useSelector(state => state.user.currentUser);
  const [isEditPlanModalOpen, setIsEditPlanModalOpen] = useState(false);
  const [isEditExceptionsModalOpen, setIsEditExceptionsModalOpen] = useState(false);
  const [valuesFromLastSubmit, setValuesFromLastSubmit] = useState(null);
  const [clicked, setClicked] = useState(false);

  const [showAvailabilityPlan, setShowAvailabilityPlan] = useState(
    selectedOption === config.availabilityState
  );
  const [showMembership, setShowMembership] = useState(selectedOption === config.membershipState);
  useEffect(() => {
    setShowAvailabilityPlan(selectedOption === config.availabilityState);
    setShowMembership(selectedOption === config.membershipState);
  }, [clicked]);
  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureOwnListing(listing);
  const isNextButtonDisabled = !currentListing.attributes.availabilityPlan;
  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
  const defaultAvailabilityPlan = {
    type: 'availability-plan/time',
    timezone: defaultTimeZone(),
    entries: [
      // { dayOfWeek: 'mon', startTime: '09:00', endTime: '17:00', seats: 1 },
      // { dayOfWeek: 'tue', startTime: '09:00', endTime: '17:00', seats: 1 },
      // { dayOfWeek: 'wed', startTime: '09:00', endTime: '17:00', seats: 1 },
      // { dayOfWeek: 'thu', startTime: '09:00', endTime: '17:00', seats: 1 },
      // { dayOfWeek: 'fri', startTime: '09:00', endTime: '17:00', seats: 1 },
      // { dayOfWeek: 'sat', startTime: '09:00', endTime: '17:00', seats: 1 },
      // { dayOfWeek: 'sun', startTime: '09:00', endTime: '17:00', seats: 1 },
    ],
  };

  const defaultPlanForOwnPlatFormBooking = {
    type: 'availability-plan/time',
    timezone: defaultTimeZone(),
    entries: [
      { dayOfWeek: 'mon', startTime: '00:00', endTime: '00:00', seats: 1 },
      { dayOfWeek: 'tue', startTime: '00:00', endTime: '00:00', seats: 1 },
      { dayOfWeek: 'wed', startTime: '00:00', endTime: '00:00', seats: 1 },
      { dayOfWeek: 'thu', startTime: '00:00', endTime: '00:00', seats: 1 },
      { dayOfWeek: 'fri', startTime: '00:00', endTime: '00:00', seats: 1 },
      { dayOfWeek: 'sat', startTime: '00:00', endTime: '00:00', seats: 1 },
      { dayOfWeek: 'sun', startTime: '00:00', endTime: '00:00', seats: 1 },
    ],
  };

  const availabilityPlan = currentListing.attributes.availabilityPlan || defaultAvailabilityPlan;
  const initialValues = valuesFromLastSubmit
    ? valuesFromLastSubmit
    : createInitialValues(availabilityPlan);

  const handleSubmit = values => {
    setValuesFromLastSubmit(values);

    dispatch(updateSelectedOption(config.availabilityState));
    // Final Form can wait for Promises to return.
    return onSubmit(createAvailabilityPlan(values), true)
      .then(() => {
        setIsEditPlanModalOpen(false);
        // setShowMembership(false);
        // setShowAvailabilityPlan(true);
      })
      .catch(e => {
        // Don't close modal if there was an error
      });
  };

  const exceptionCount = availabilityExceptions ? availabilityExceptions.length : 0;
  const sortedAvailabilityExceptions = availabilityExceptions.sort(sortExceptionsByStartTime);

  // Save exception click handler
  const saveException = values => {
    const { availability, exceptionStartTime, exceptionEndTime } = values;

    // TODO: add proper seat handling
    const seats = availability === 'available' ? 1 : 0;

    return onAddAvailabilityException({
      listingId: listing.id,
      seats,
      start: timestampToDate(exceptionStartTime),
      end: timestampToDate(exceptionEndTime),
    })
      .then(() => {
        setIsEditExceptionsModalOpen(false);
      })
      .catch(e => {
        // Don't close modal if there was an error
      });
  };
  const OBPM =
    currentListing?.attributes?.metadata?.ownBookingPlatform &&
    currentUser?.attributes?.profile?.metadata?.membership;

  const [ownBookingPlatform, setOwnBookingPlatform] = useState(OBPM);
  // const [showAvailabilityPlan, setShowAvailabilityPlan] = useState(free);
  // const [showMembership, setShowMembership] = useState(isPaid);

  const [subscriptionModalOpen, setSubscriptionModalOpen] = useState(false);
  const [subscriptionStripeFormOpen, setSubscriptionStripeFormOpen] = useState(false);
  const [subscriptionSuccess, successParentSet] = useState(false);
  const [url, setUrl] = useState(null);
  const membership = currentUserHasMembership(currentUser) || subscriptionSuccess;

  const currentListingHasAvailabilityPlan = !!currentListing?.attributes?.availabilityPlan;

  return (
    <main className={classes}>
      <h1 className={css.panelTitle}>
        {isPublished
          ? intl.formatMessage({
              id: 'EditListingAvailabilityPanel.EditListingAvailabilityPanel.editTitle',
            })
          : intl.formatMessage({
              id: 'EditListingAvailabilityPanel.EditListingAvailabilityPanel.newListingTitle',
            })}
      </h1>
      <div
        className={showAvailabilityPlan ? css.showContainer : css.eachSubtitleContainer}
        // className={showAvailabilityPlan ? css.showContainer : css.eachSubtitleContainer}
        onClick={e => {
          // setShowAvailabilityPlan(true);
          // setShowMembership(false);
          setOwnBookingPlatform(false);
          setClicked(!clicked);
          dispatch(updateSelectedOption(config.availabilityState));
          // dispatch(handleFreeState(true));
          // dispatch(
          //   handleUpdateListing({
          //     listingId: listing.id,
          //     showFree: true,
          //     showPaid: false,
          //   })
          // );
        }}
      >
        <h2 className={css.containerHeading}>
          {intl.formatMessage({
            id: 'EditListingAvailabilityPanel.EditListingAvailabilityPanel.firstHeading',
          })}
          y
        </h2>
        <p className={css.text}>
          {intl.formatMessage({
            id: 'EditListingAvailabilityPanel.EditListingAvailabilityPanel.subHeadingOne',
          })}
        </p>
        <p className={css.text}>
          {intl.formatMessage({
            id: 'EditListingAvailabilityPanel.EditListingAvailabilityPanel.subHeadingTwo',
          })}
        </p>
        <p
          onClick={() => {
            setShowFirstText(!showFirstText);
          }}
          className={css.moreDetails}
        >
          {showFirstText ? (
            <MdOutlineKeyboardArrowUp className={css.moreIcon} />
          ) : (
            <MdOutlineKeyboardArrowDown className={css.moreIcon} />
          )}{' '}
          <span>
            {intl.formatMessage({
              id:
                'EditListingAvailabilityPanel.EditListingAvailabilityPanel.firstMoreDetailsButton',
            })}
          </span>
        </p>
        {showFirstText ? (
          <p className={css.text}>
            {intl.formatMessage({
              id: 'EditListingAvailabilityPanel.EditListingAvailabilityPanel.secondHeading',
            })}
          </p>
        ) : null}
        {/* <p className={css.panelSubtitle}>
          <strong>
            <FormattedMessage id="EditListingAvailabilityPanel.EditListingAvailabilityPanel.transactionModelHeading" />
          </strong>
          <FormattedMessage id="EditListingAvailabilityPanel.EditListingAvailabilityPanel.transactionModelParagraph" />
        </p>
        <p className={css.panelSubtitle}>
          <FormattedMessage id="EditListingAvailabilityPanel.EditListingAvailabilityPanel.transactionModelParagraph2" />
        </p> */}
      </div>
      <div
        className={showMembership ? css.showContainer : css.eachSubtitleContainer}
        onClick={e => {
          // setShowAvailabilityPlan(false);
          // setShowMembership(true);
          setOwnBookingPlatform(true);
          setClicked(!clicked);
          dispatch(updateSelectedOption(config.membershipState));
          // dispatch(handleFreeState(false));
          // dispatch(
          //   handleUpdateListing({
          //     listingId: listing.id,
          //     showFree: false,
          //     showPaid: true,
          //   })
          // );
        }}
      >
        <h2 className={css.containerHeading}>
          {intl.formatMessage({
            id: 'EditListingAvailabilityPanel.EditListingAvailabilityPanel.secondMainHeadingLabel',
          })}
        </h2>
        <p className={css.text}>
          {intl.formatMessage({
            id: 'EditListingAvailabilityPanel.EditListingAvailabilityPanel.secondSubHeadingOne',
          })}
        </p>
        <p className={css.text}>
          {intl.formatMessage({
            id: 'EditListingAvailabilityPanel.EditListingAvailabilityPanel.secondSubHeadingTwo',
          })}
        </p>
        <p
          onClick={() => {
            // console.log('clicked');
            setShowSecondText(!showSecondText);
          }}
          className={css.moreDetails}
        >
          {showSecondText ? (
            <MdOutlineKeyboardArrowUp className={css.moreIcon} />
          ) : (
            <MdOutlineKeyboardArrowDown className={css.moreIcon} />
          )}{' '}
          <span>
            {intl.formatMessage({
              id: 'EditListingAvailabilityPanel.EditListingAvailabilityPanel.secondMoreDetails',
            })}
          </span>
        </p>
        {showSecondText ? (
          <p className={css.text}>
            {intl.formatMessage({
              id: 'EditListingAvailabilityPanel.EditListingAvailabilityPanel.secondSubTitleThree',
            })}
          </p>
        ) : null}
        {/* <p className={css.panelSubtitle}>
          <strong>
            <FormattedMessage id="EditListingAvailabilityPanel.EditListingAvailabilityPanel.subscriptionModelHeading" />
          </strong>
          <FormattedMessage id="EditListingAvailabilityPanel.EditListingAvailabilityPanel.subscriptionModelParagraph" />
        </p>
        <p className={css.panelSubtitle}>
          <FormattedMessage id="EditListingAvailabilityPanel.EditListingAvailabilityPanel.subscriptionModelParagraph2" />
        </p> */}
      </div>

      {/* <div className="flex gap-2 mt-8 items-center">
        <Toggle
          id="own_booking_platform"
          name="own_booking_platform"
          onChange={e => setOwnBookingPlatform(e.target.checked)}
          checked={ownBookingPlatform}
        />
        <label htmlFor="own_booking_platform">
          {intl.formatMessage({
            id:
              'EditListingAvailabilityPanel.EditListingAvailabilityPanel.OwnBookingPlatformSwitchLabel',
          })}
        </label>
      </div> */}

      {ownBookingPlatform && showMembership ? (
        membership ? (
          <OwnBookingPlatformForm
            initialValues={{
              booking_platform_website: OBPM
                ? currentListing?.attributes?.metadata?.booking_platform_website
                : url,
            }}
            membership={membership}
            submitButtonText={submitButtonText}
            updateInProgress={updateInProgress}
            subscriptionStripeFormOpen={subscriptionStripeFormOpen}
            subscriptionSuccess={subscriptionSuccess}
            showMembership={showMembership}
            onSubmit={values => {
              const membership = currentUserHasMembership(currentUser) || subscriptionSuccess;
              const { booking_platform_website } = values;
              dispatch(updateSelectedOption(config.membershipState));

              if (membership) {
                return onSubmit({
                  publicData: {
                    isFree: false,
                    paid: true,
                    saved: true,
                  },
                  metadata: {
                    booking_platform_website,
                    ownBookingPlatform,
                  },
                  availabilityPlan: defaultPlanForOwnPlatFormBooking,
                });
              } else {
                return setSubscriptionStripeFormOpen(true);
              }
            }}
          />
        ) : (
          <MembershipPaymentPage
            setSubscriptionStripeFormOpen={setSubscriptionStripeFormOpen}
            setOwnBookingPlatform={setOwnBookingPlatform}
            successParentSet={successParentSet}
            availabilityPlan={defaultPlanForOwnPlatFormBooking}
            handleListingSubmit={onSubmit}
            ownBookingPlatform={ownBookingPlatform}
            isFree={saved}
            setUrlMain={setUrl}
            listingId={listing.id?.uuid}
          />
        )
      ) : (
        showAvailabilityPlan && (
          <>
            <h1 className={css.panelTitle}>
              {isPublished ? (
                <FormattedMessage id="EditListingAvailabilityPanel.title" />
              ) : (
                <FormattedMessage id="EditListingAvailabilityPanel.createListingTitle" />
              )}
            </h1>

            <section className={css.section}>
              <header className={css.sectionHeader}>
                <h2 className={css.sectionTitle}>
                  <FormattedMessage id="EditListingAvailabilityPanel.defaultScheduleTitle" />
                </h2>
                <InlineTextButton
                  className={css.editPlanButton}
                  onClick={() => setIsEditPlanModalOpen(true)}
                >
                  <IconEdit className={css.editPlanIcon} />{' '}
                  <FormattedMessage id="EditListingAvailabilityPanel.edit" />
                </InlineTextButton>
              </header>
              <div className={css.week}>
                {WEEKDAYS.map(w => (
                  <Weekday
                    dayOfWeek={w}
                    key={w}
                    availabilityPlan={availabilityPlan}
                    openEditModal={setIsEditPlanModalOpen}
                  />
                ))}
              </div>
            </section>
            <section className={css.section}>
              <header className={css.sectionHeader}>
                <h2 className={css.sectionTitle}>
                  {fetchExceptionsInProgress ? (
                    <FormattedMessage id="EditListingAvailabilityPanel.availabilityExceptionsTitleNoCount" />
                  ) : (
                    <FormattedMessage
                      id="EditListingAvailabilityPanel.availabilityExceptionsTitle"
                      values={{ count: exceptionCount }}
                    />
                  )}
                </h2>
              </header>
              {fetchExceptionsInProgress ? (
                <div className={css.exceptionsLoading}>
                  <IconSpinner />
                </div>
              ) : exceptionCount === 0 ? (
                <div className={css.noExceptions}>
                  <FormattedMessage id="EditListingAvailabilityPanel.noExceptions" />
                </div>
              ) : (
                <div className={css.exceptions}>
                  {sortedAvailabilityExceptions.map(availabilityException => {
                    const { start, end, seats } = availabilityException.attributes;
                    return (
                      <div key={availabilityException.id.uuid} className={css.exception}>
                        <div className={css.exceptionHeader}>
                          <div className={css.exceptionAvailability}>
                            <div
                              className={classNames(css.exceptionAvailabilityDot, {
                                [css.isAvailable]: seats > 0,
                              })}
                            />
                            <div className={css.exceptionAvailabilityStatus}>
                              {seats > 0 ? (
                                <FormattedMessage id="EditListingAvailabilityPanel.exceptionAvailable" />
                              ) : (
                                <FormattedMessage id="EditListingAvailabilityPanel.exceptionNotAvailable" />
                              )}
                            </div>
                          </div>
                          <button
                            className={css.removeExceptionButton}
                            onClick={() =>
                              onDeleteAvailabilityException({ id: availabilityException.id })
                            }
                          >
                            <IconClose size="normal" className={css.removeIcon} />
                          </button>
                        </div>
                        <TimeRange
                          className={css.timeRange}
                          startDate={start}
                          endDate={end}
                          dateType={DATE_TYPE_DATETIME}
                          timeZone={availabilityPlan.timezone}
                        />
                      </div>
                    );
                  })}
                </div>
              )}
              {exceptionCount <= MAX_EXCEPTIONS_COUNT ? (
                <InlineTextButton
                  className={css.addExceptionButton}
                  onClick={() => setIsEditExceptionsModalOpen(true)}
                  disabled={disabled}
                  ready={ready}
                >
                  <FormattedMessage id="EditListingAvailabilityPanel.addException" />
                </InlineTextButton>
              ) : null}
            </section>

            {errors.showListingsError ? (
              <p className={css.error}>
                <FormattedMessage id="EditListingAvailabilityPanel.showListingFailed" />
              </p>
            ) : null}

            <EditListingStripePanel
              submitButtonText={submitButtonText}
              onSubmit={values => {
                dispatch(updateSelectedOption(config.availabilityState));
                onSubmit({
                  ...values,
                  publicData: {
                    ...values?.publicData,
                    isFree: true,
                    paid: false,
                  },
                  availabilityPlan,
                });
              }}
              listing={currentListing}
              onShowPayoutModal={onShowPayoutModal}
              updateInProgress={updateInProgress}
              currentListingHasAvailabilityPlan={currentListingHasAvailabilityPlan}
              membership={membership}
              isPaid={isPaid}
            />

            {/* {!isPublished ? (
            <Button
              className={css.goToNextTabButton}
              onClick={onNextTab}
              disabled={isNextButtonDisabled}
            >
              {submitButtonText}
            </Button>
          ) : null} */}
            {onManageDisableScrolling ? (
              <Modal
                id="EditAvailabilityPlan"
                isOpen={isEditPlanModalOpen}
                onClose={() => setIsEditPlanModalOpen(false)}
                onManageDisableScrolling={onManageDisableScrolling}
                containerClassName={css.modalContainer}
                usePortal
              >
                <EditListingAvailabilityPlanForm
                  formId="EditListingAvailabilityPlanForm"
                  listingTitle={currentListing.attributes.title}
                  availabilityPlan={availabilityPlan}
                  weekdays={WEEKDAYS}
                  onSubmit={handleSubmit}
                  initialValues={initialValues}
                  inProgress={updateInProgress}
                  fetchErrors={errors}
                />
              </Modal>
            ) : null}
            {onManageDisableScrolling ? (
              <Modal
                id="EditAvailabilityExceptions"
                isOpen={isEditExceptionsModalOpen}
                onClose={() => setIsEditExceptionsModalOpen(false)}
                onManageDisableScrolling={onManageDisableScrolling}
                containerClassName={css.modalContainer}
                usePortal
              >
                <EditListingAvailabilityExceptionForm
                  formId="EditListingAvailabilityExceptionForm"
                  onSubmit={saveException}
                  timeZone={availabilityPlan.timezone}
                  availabilityExceptions={sortedAvailabilityExceptions}
                  updateInProgress={updateInProgress}
                  fetchErrors={errors}
                />
              </Modal>
            ) : null}
          </>
        )
      )}
      {/* {onManageDisableScrolling ? (
        <OwnBookingPlatformModal
          onManageDisableScrolling={onManageDisableScrolling}
          isOpen={subscriptionModalOpen}
          onClose={() => setSubscriptionModalOpen(false)}
        />
      ) : null} */}
      {/* <ToastContainer/> */}
      <GoBackButton url={goBackLink} />
    </main>
  );
};

EditListingAvailabilityPanel.defaultProps = {
  className: null,
  rootClassName: null,
  listing: null,
  availabilityExceptions: [],
};

EditListingAvailabilityPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  availabilityExceptions: arrayOf(propTypes.availabilityException),
  fetchExceptionsInProgress: bool.isRequired,
  onAddAvailabilityException: func.isRequired,
  onDeleteAvailabilityException: func.isRequired,
  onSubmit: func.isRequired,
  // onManageDisableScrolling: func.isRequired,
  onNextTab: func.isRequired,
  submitButtonText: string.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EditListingAvailabilityPanel;
