import React from 'react';
import { Field } from 'react-final-form';

const ACCEPT_FILES = 'image/*';

function UploadFile({ name, disabled, onImageUploadHandler, ...restProps }) {
  return (
    <div>
      <label htmlFor={name} className="btn" data-disabled={disabled} style={{ maxWidth: '160px' }}>
        Add Photos
      </label>
      <Field name={name} accept={ACCEPT_FILES}>
        {({ input: { value, onChange, onBlur, ...rest } }) => {
          const handleChange = event => {
            const file = event.target.files[0];
            onChange(file);
            if (onImageUploadHandler) {
              onImageUploadHandler(file);
            }
            onBlur();
          };
          return (
            <input
              {...rest}
              type="file"
              disabled={disabled}
              onChange={handleChange}
              accept={ACCEPT_FILES}
              {...restProps}
              style={{ display: 'none' }}
            />
          );
        }}
      </Field>
    </div>
  );
}

export default UploadFile;
