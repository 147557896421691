import React, { useMemo } from 'react';
import {
  Modal,
  Form,
  FieldCheckboxGroup,
  FieldTextInput,
  FieldCurrencyInput,
  FieldSelectModern,
  Button,
  LocationAutocompleteInputField,
} from '../../components';

import { Form as FinalForm } from 'react-final-form';
import {
  autocompletePlaceSelected,
  autocompleteSearchRequired,
  composeValidators,
  decimalNotAllowed,
  maxLength,
  moneySubUnitAmountAtLeast,
  multipleOf5,
  required,
  requiredFieldArrayCheckbox,
} from '../../util/validators';
import config from '../../config';
import arrayMutators from 'final-form-arrays';

import { types as sdkTypes } from '../../util/sdkLoader';
import { injectIntl } from '../../util/reactIntl';
import css from './EditListingOfferingsForm.module.css';
import { formatMoney } from '../../util/currency';
import locationCss from '../EditListingLocationForm/EditListingLocationForm.module.css';

const identity = v => v;

const { Money } = sdkTypes;
const COMMISSION = +process.env.REACT_APP_CUSTOMER_COMMISSION_PERCENTAGE;
const PROVIDER_COMMISSION = +process.env.REACT_APP_PROVIDER_COMMISSION_PERCENTAGE;

function EditOfferingModal({ isOpen, onClose, onManageDisableScrolling, ...rest }) {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      onManageDisableScrolling={onManageDisableScrolling}
      usePortal
      id="Edit_Offering_Modal"
      name="Edit_Offering_Modal"
    >
      <FinalForm
        {...rest}
        mutators={{ ...arrayMutators }}
        // onSubmit={value => alert(JSON.stringify(value))}
        render={formRenderProps => {
          const { handleSubmit, formId, values, errors, initialValues, intl } = formRenderProps;
          const errorMessages = Object.keys(errors);

          const getMoney = () => breakdownAmount + (COMMISSION * breakdownAmount) / 100;

          const breakdownAmount = useMemo(() => {
            if (values?.['offering_price'] == null) return 0;
            if (values?.['offering_price']?.amount) return values?.['offering_price']?.amount;
            return initialValues?.price?.amount;
          }, [initialValues, values?.['offering_price']?.amount]);

          const offeringSubmitDisabled =
            !values?.session_format_type ||
            !values?.session_location ||
            !values?.offering_title ||
            !values?.offering_description ||
            !values?.offering_style ||
            !values?.offering_price ||
            !values?.booking_time_availability ||
            !values?.offering_accessibility ||
            errorMessages.includes('session_format_type') ||
            errorMessages.includes('session_location') ||
            errorMessages.includes('offering_title') ||
            errorMessages.includes('offering_description') ||
            errorMessages.includes('offering_style') ||
            errorMessages.includes('offering_price') ||
            errorMessages.includes('booking_time_availability') ||
            errorMessages.includes('offering_accessibility') ||
            errorMessages.includes('physical_location_address');
          const priceRequired = required('A session price starting at $10.00 USD is required.');
          const minPrice = new Money(config.listingMinimumPriceSubUnits, config.currency);
          const minPriceRequired = moneySubUnitAmountAtLeast(
            'A session price starting at $10.00 USD is required.',
            config.listingMinimumPriceSubUnits
          );
          const notFractions = decimalNotAllowed('Price with decimals is not allowed.');

          const priceValidators = config.listingMinimumPriceSubUnits
            ? composeValidators(priceRequired, minPriceRequired, notFractions)
            : priceRequired;

          return (
            <Form onSubmit={handleSubmit}>
              <h3>
                {intl.formatMessage({ id: 'EditListingOfferingsForm.EditOfferingModalTitle' })}
              </h3>
              <div className="push-top-smedium">
                <label>
                  {intl.formatMessage({ id: 'EditListingOfferingsForm.offeringTitleLabel' })}
                  <span className="red">*</span>
                </label>
                <FieldTextInput
                  name="offering_title"
                  id={`${formId}.offering_title`}
                  placeholder={intl.formatMessage({
                    id: 'EditListingOfferingsForm.offeringTitlePlaceholder',
                  })}
                  className="hwInput"
                  inputRootClass="hwInput"
                  validate={required(
                    intl.formatMessage({ id: 'EditListingOfferingsForm.offeringTitleRequired' })
                  )}
                />
              </div>
              <div className="push-top-smedium">
                <label>
                  {intl.formatMessage({ id: 'EditListingOfferingsForm.offeringDescriptionLabel' })}{' '}
                  <span className="red">*</span>
                </label>
                <FieldTextInput
                  name="offering_description"
                  id={`${formId}.offering_description`}
                  placeholder={intl.formatMessage({
                    id: 'EditListingOfferingsForm.offeringDescriptionPlaceholder',
                  })}
                  className="hwInput"
                  inputRootClass="hwInput"
                  type="textarea"
                  validate={composeValidators(
                    required(
                      intl.formatMessage({
                        id: 'EditListingOfferingsForm.offeringDescriptionRequired',
                      })
                    ),
                    maxLength(
                      intl.formatMessage({
                        id: 'EditListingOfferingsForm.offeringDescriptionMaxCharsMessage',
                      }),
                      600
                    )
                  )}
                />
              </div>
              <div className="push-top-smedium">
                <label>
                  {intl.formatMessage({ id: 'EditListingOfferingsForm.sessionOfferingsLabel' })}{' '}
                  <span className="red">*</span>
                </label>
                {/* <FieldTextInput
                id="offering_style"
                name="offering_style"
                placeholder="Offering style"
                className="hwInput"
                validate={required('Offering style is required')}
              /> */}
                <FieldSelectModern
                  name="offering_style"
                  id={`${formId}.offering_style`}
                  placeholder={intl.formatMessage({
                    id: 'EditListingOfferingsForm.offeringStylePlaceholder',
                  })}
                  options={config.custom.allModalities}
                  isMulti={true}
                  validate={requiredFieldArrayCheckbox(
                    intl.formatMessage({ id: 'EditListingOfferingsForm.sessionOfferingsRequired' })
                  )}
                />
              </div>
              <div className="push-top-smedium">
                <label>
                  {intl.formatMessage({ id: 'EditListingOfferingsForm.sessionFormatLabel' })}{' '}
                  <span className="red">*</span>
                </label>

                <div>
                  <FieldCheckboxGroup
                    name="session_format_type"
                    id={`${formId}.session_format_type`}
                    options={config.custom.offeringSessionFormatOptions}
                    validate={requiredFieldArrayCheckbox(
                      intl.formatMessage({ id: 'EditListingOfferingsForm.sessionFormatRequired' })
                    )}
                    twoColumns
                    nopadding
                  />
                </div>
              </div>
              <div className="push-top-smedium">
                <label>
                  {intl.formatMessage({ id: 'EditListingOfferingsForm.sessionLocationLabel' })}{' '}
                  <span className="red">*</span>
                </label>
                <div>
                  {/* {config.custom.sessionLocations.map(sessionLocation => (
                  <FieldRadioButton
                    key={sessionLocation.key}
                    id={sessionLocation.key}
                    value={sessionLocation.key}
                    label={sessionLocation.label}
                    name="session_location"
                  />
                ))} */}
                  <div>
                    <FieldCheckboxGroup
                      name="session_location"
                      id={`${formId}.session_location`}
                      options={config.custom.sessionLocations}
                      validate={requiredFieldArrayCheckbox(
                        intl.formatMessage({
                          id: 'EditListingOfferingsForm.sessionLocationRequiredMessage',
                        })
                      )}
                      horizontal
                    />
                  </div>
                </div>
              </div>
              {(values?.session_location ?? []).includes('physical_location') && (
                <div className="push-top-smedium">
                  <label>
                    {intl.formatMessage({
                      id: 'EditListingOfferingsForm.physicalLocationAddressLabel',
                    })}{' '}
                    <span className="red">*</span>{' '}
                    <span className="grey typeLight">
                      {intl.formatMessage({
                        id: 'EditListingOfferingsForm.physicalLocationAddressSubText',
                      })}
                    </span>
                  </label>

                  <LocationAutocompleteInputField
                    className={locationCss.locationAddress}
                    inputClassName="hwInput h-36"
                    // inputClassName={locationCss.locationAutocompleteInput}
                    iconClassName={locationCss.locationAutocompleteInputIcon}
                    predictionsClassName={locationCss.predictionsRoot}
                    validClassName={locationCss.validLocation}
                    name="physical_location_address"
                    // label={locationRequiredMessage}
                    placeholder={intl.formatMessage({
                      id: 'EditListingOfferingsForm.physicalLocationAddressPlaceholder',
                    })}
                    useDefaultPredictions={false}
                    format={identity}
                    valueFromForm={values.physical_location_address}
                    validate={composeValidators(
                      autocompleteSearchRequired(
                        intl.formatMessage({
                          id: 'EditListingOfferingsForm.physicalLocationAddressRequiredMessage',
                        })
                      ),
                      autocompletePlaceSelected(
                        intl.formatMessage({
                          id: 'EditListingOfferingsForm.physicalLocationAddresssNotSelected',
                        })
                      )
                    )}
                  />
                </div>
              )}

              <div className="push-top-smedium">
                <label>
                  {intl.formatMessage({ id: 'EditListingOfferingsForm.sessionPriceLabel' })}{' '}
                  <span className="red">*</span>{' '}
                  <span className="grey typeLight">
                    {intl.formatMessage({ id: 'EditListingOfferingsForm.sessionPriceSubText' })}
                  </span>
                </label>

                <FieldCurrencyInput
                  name="offering_price"
                  id={`${formId}.offering_price`}
                  inputRootClass="hwInput"
                  currencyConfig={config.currencyConfig}
                  validate={priceValidators}
                />
                {breakdownAmount ? (
                  <div style={{ marginTop: '1rem' }}>
                    <p
                      style={{
                        borderLeft: '5px solid #17a2b8',
                        padding: '1rem .5rem',
                        color: '#17a2b8',
                        background: 'hsl(188deg 78% 89%)',
                        lineHeight: 1.4,
                      }}
                    >
                      {intl.formatMessage(
                        { id: 'EditListingOfferingsForm.sessionPriceInfoMessage' },
                        {
                          actualSessionPrice: formatMoney(
                            intl,
                            new Money(getMoney(), config.currency)
                          ),
                          providerCommission: PROVIDER_COMMISSION,
                        }
                      )}
                    </p>
                  </div>
                ) : null}
              </div>

              <div className="push-top-smedium">
                <label>
                  {intl.formatMessage({ id: 'EditListingOfferingsForm.sessionDurationLabel' })}{' '}
                  <span className="red">*</span>{' '}
                  <span className="grey typeLight">
                    {intl.formatMessage({ id: 'EditListingOfferingsForm.sessionDurationSubText' })}
                  </span>
                </label>
                <FieldTextInput
                  name="booking_time_availability"
                  id={`${formId}.booking_time_availability`}
                  placeholder={intl.formatMessage({
                    id: 'EditListingOfferingsForm.bookingTimeAvailabilityLabel',
                  })}
                  type="number"
                  step="5"
                  className="hwInput"
                  inputRootClass="hwInput"
                  validate={composeValidators(
                    required(
                      intl.formatMessage({
                        id: 'EditListingOfferingsForm.sessionDurationRequiredMessage',
                      })
                    ),
                    multipleOf5(
                      intl.formatMessage({ id: 'EditListingOfferingsForm.multipleOf5Message' })
                    )
                  )}
                />
              </div>
              <div className="push-top-smedium">
                <label>
                  {intl.formatMessage({ id: 'EditListingOfferingsForm.sessionAccessibilityLabel' })}{' '}
                  <span className="red">*</span>{' '}
                  <span className="grey typeLight">
                    {intl.formatMessage({
                      id: 'EditListingOfferingsForm.sessionAccessibilitySubText',
                    })}
                  </span>
                </label>
                <FieldTextInput
                  name="offering_accessibility"
                  id={`${formId}.offering_accessibility`}
                  className="hwInput"
                  inputRootClass="hwInput"
                  type="textarea"
                  placeholder={intl.formatMessage({
                    id: 'EditListingOfferingsForm.sessionAccessibilityPlaceholder',
                  })}
                  validate={composeValidators(
                    required(
                      intl.formatMessage({
                        id: 'EditListingOfferingsForm.sessionAccessibilityRequired',
                      })
                    ),
                    maxLength(
                      intl.formatMessage({
                        id: 'EditListingOfferingsForm.sessionAccessibilityMaxCharsMessage',
                      }),
                      300
                    )
                  )}
                />
              </div>

              <Button className={css.button} disabled={offeringSubmitDisabled}>
                {intl.formatMessage({
                  id: 'EditListingOfferingsForm.EditOfferingModal.closeAndKeepEdittingButtonlabel',
                })}
              </Button>
              <h5 className="grey typeLight">
                {intl.formatMessage({ id: 'EditListingOfferingsForm.EditOfferingModal.Note' })}
              </h5>
            </Form>
          );
        }}
      />
    </Modal>
  );
}

export default EditOfferingModal;
