import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage, injectIntl } from '../../util/reactIntl';

import { LISTING_STATE_DRAFT } from '../../util/types';
import { ensureListing } from '../../util/data';
import { EditListingFeaturesForm } from '../../forms';
import { ListingLink } from '../../components';
import config from '../../config';
import css from './EditListingFeaturesPanel.module.css';
import { useHistory } from 'react-router-dom';

const FEATURES_NAME = 'healerStyles';

const EditListingFeaturesPanel = props => {
  const {
    rootClassName,
    className,
    listing,
    disabled,
    ready,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
    prevTabName,
    pageURLParams,
    goBackLink,
    isNewListingFlow,
    intl,
  } = props;

  const [updateValues, setUpdateValues] = useState(undefined);
  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureListing(listing);
  const { publicData } = currentListing.attributes;

  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
  const panelTitle = isPublished ? (
    <FormattedMessage id="EditListingIdentitiesPanel.title" />
  ) : (
    <FormattedMessage id="EditListingFeaturesPanel.createListingTitle" />
  );

  const {
    misc_identities = [],
    spirituality_identities = [],
    sexuality_identities = [],
    disability_identities = [],
    background_identities = [],
    gender_identities = [],
    featuredIdentities = [],
    class_background_identities = [],
  } = publicData || {};

  // const initialValues = {
  //   misc_identities,
  //   spirituality_identities,
  //   sexuality_identities,
  //   disability_identities,
  //   background_identities,
  //   gender_identities,
  // };

  const initialValues = useMemo(() => {
    if (typeof updateValues == 'undefined')
      return {
        misc_identities,
        spirituality_identities,
        sexuality_identities,
        disability_identities,
        background_identities,
        gender_identities,
        featuredIdentities,
        class_background_identities,
      };

    return updateValues;
  }, [updateValues]);

  return (
    <div className={classes}>
      <div className="hWalkthroughForm">
        {/* <h1 className={css.title}>{panelTitle}</h1> */}
        {/* <h6>{panelTitle}</h6> */}
        <h1 className={css.panelTitle}>{panelTitle}</h1>

        <EditListingFeaturesForm
          className={css.form}
          intl={intl}
          name={FEATURES_NAME}
          initialValues={initialValues}
          onSubmit={values => {
            const {
              misc_identities = [],
              spirituality_identities = [],
              sexuality_identities = [],
              disability_identities = [],
              background_identities = [],
              gender_identities = [],
              featuredIdentities = [],
              class_background_identities = [],
            } = values;
            const identities = [
              ...misc_identities,
              ...spirituality_identities,
              ...sexuality_identities,
              ...disability_identities,
              ...background_identities,
              ...gender_identities,
              ...class_background_identities,
            ];
            const identities_keyword = identities
              .map(v => config.custom.allIdentities?.find(f => f.key == v)?.label)
              .toString();

            const updatedValues = {
              publicData: {
                misc_identities,
                spirituality_identities,
                sexuality_identities,
                disability_identities,
                background_identities,
                gender_identities,
                featuredIdentities,
                class_background_identities,
                identities,
                identities_keyword,
              },
            };

            setUpdateValues(updatedValues.publicData);
            onSubmit(updatedValues);
          }}
          onChange={onChange}
          saveActionMsg={submitButtonText}
          disabled={disabled}
          ready={ready}
          updated={panelUpdated}
          updateInProgress={updateInProgress}
          fetchErrors={errors}
          goBackLink={goBackLink}
          isNewListingFlow={isNewListingFlow}
        />
      </div>
    </div>
  );
};

EditListingFeaturesPanel.defaultProps = {
  rootClassName: null,
  className: null,
  listing: null,
};

const { bool, func, object, string } = PropTypes;

EditListingFeaturesPanel.propTypes = {
  rootClassName: string,
  className: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default injectIntl(EditListingFeaturesPanel);
