import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';

import { LISTING_STATE_DRAFT } from '../../util/types';
import { ensureListing } from '../../util/data';
import { EditListingOfferingsForm } from '../../forms';
import { ListingLink } from '../../components';

import css from './EditListingOfferingsPanel.module.css';
import { types as sdkTypes } from '../../util/sdkLoader';
import config from '../../config';
const { Money } = sdkTypes;

const EditListingFeaturedIdentitiesPanel = props => {
  const {
    rootClassName,
    className,
    listing,
    disabled,
    ready,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
    onManageDisableScrolling,
    goBackLink,
  } = props;
  const [updateValues, setUpdateValues] = useState(undefined);
  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureListing(listing);
  const { publicData } = currentListing.attributes;

  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
  const panelTitle = isPublished ? (
    <FormattedMessage id="EditListingOfferingsPanel.title" />
  ) : (
    <FormattedMessage id="EditListingOfferingsPanel.createListingTitle" />
  );

  let { offerings = [] } = publicData || {};

  // let initialValues = {};

  // if (offerings.length > 0) {
  //   initialValues = {

  //   };
  // }

  const initialValues = useMemo(() => {
    if (typeof updateValues == 'undefined') {
      return {
        offerings: offerings.map(o => ({
          ...o,
          offering_price: new Money(o.offering_price, config.currency),
          offering_style: o.offering_style.map(v =>
            config.custom.allModalities.find(b => b.key == v)
          ),
        })),
      };
    }

    return {
      offerings: updateValues.map(o => ({
        ...o,
        offering_price: new Money(o.offering_price, config.currency),
        offering_style: o.offering_style.map(v =>
          config.custom.allModalities.find(b => b.key == v)
        ),
      })),
    };
  }, [updateValues]);

  return (
    <div className={classes}>
      <div className="hWalkthroughForm">
        {/* <h1 className={css.title}>{panelTitle}</h1> */}
        {/* <h6>{panelTitle}</h6> */}
        <h1 className={css.panelTitle}>{panelTitle}</h1>

        <EditListingOfferingsForm
          className={css.form}
          name={'offerings'}
          initialValues={initialValues}
          onManageDisableScrolling={onManageDisableScrolling}
          onSubmit={values => {
            const { offerings } = values;

            const newOfferings = offerings.map(o => ({
              ...o,
              offering_price: o.offering_price.amount,
              offering_style: o.offering_style.map(v => v.key),
            }));

            const virtualSession = offerings?.some(
              i =>
                i?.session_location?.includes('phone') ||
                i?.session_location?.includes('text') ||
                i?.session_location?.includes('video')
            );

            const modalities = [
              ...new Set(
                newOfferings.map(v => v.offering_style).reduce((acc, item) => [...acc, ...item], [])
              ),
            ];
            const modalities_keyword = modalities
              .map(v => config.custom.allModalities?.find(f => f.key == v)?.label)
              .toString();
            const offeringsPrices = (offerings ?? []).map(t => t.offering_price.amount);

            const minPrice =
              offeringsPrices.reduce(
                (min, price) => (typeof min == 'undefined' ? price : Math.min(min, price)),
                undefined
              ) ?? 0;

            const maxPrice = offeringsPrices.reduce((max, price) => Math.max(max, price), 0);

            const updateValues = {
              publicData: {
                offerings: newOfferings,
                modalities,
                modalities_keyword,
                virtualSession,
                minPrice: minPrice,
                maxPrice: maxPrice,
              },
            };

            setUpdateValues(newOfferings);
            onSubmit(updateValues);
          }}
          onChange={onChange}
          saveActionMsg={submitButtonText}
          disabled={disabled}
          ready={ready}
          updated={panelUpdated}
          updateInProgress={updateInProgress}
          fetchErrors={errors}
          goBackLink={goBackLink}
        />
      </div>
    </div>
  );
};

EditListingFeaturedIdentitiesPanel.defaultProps = {
  rootClassName: null,
  className: null,
  listing: null,
};

const { bool, func, object, string } = PropTypes;

EditListingFeaturedIdentitiesPanel.propTypes = {
  rootClassName: string,
  className: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EditListingFeaturedIdentitiesPanel;
