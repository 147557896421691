import React, { useMemo, useState } from 'react';
import { bool, func, shape, string } from 'prop-types';
import classNames from 'classnames';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { FormattedMessage } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import config from '../../config';
import {
  Button,
  FieldCheckboxGroup,
  FieldCurrencyInput,
  FieldRadioButton,
  FieldSelectModern,
  FieldTextInput,
  Form,
  GoBackButton,
  LocationAutocompleteInputField,
  Modal,
} from '../../components';

import css from './EditListingOfferingsForm.module.css';
import { FieldArray } from 'react-final-form-arrays';
import {
  autocompletePlaceSelected,
  autocompleteSearchRequired,
  composeValidators,
  decimalNotAllowed,
  maxLength,
  moneySubUnitAmountAtLeast,
  multipleOf5,
  required,
  requiredFieldArrayCheckbox,
} from '../../util/validators';
import { formatMoney } from '../../util/currency';
import { types as sdkTypes } from '../../util/sdkLoader';
import { injectIntl } from '../../util/reactIntl';
import { FaPlusCircle } from 'react-icons/fa';
import EditOfferingModal from './EditOfferingModal';
import { BsFillPencilFill } from 'react-icons/bs';
import locationCss from '../EditListingLocationForm/EditListingLocationForm.module.css';

const { Money, LatLng } = sdkTypes;
const COMMISSION = +process.env.REACT_APP_CUSTOMER_COMMISSION_PERCENTAGE;
const PROVIDER_COMMISSION = +process.env.REACT_APP_PROVIDER_COMMISSION_PERCENTAGE;
const identity = v => v;

const EditListingOfferingsFormComponent = props => (
  <FinalForm
    {...props}
    mutators={{ ...arrayMutators }}
    render={formRenderProps => {
      const {
        disabled,
        ready,
        rootClassName,
        className,
        name,
        handleSubmit,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
        form,
        values,
        initialValues,
        intl,
        onManageDisableScrolling,
        errors,
        goBackLink,
      } = formRenderProps;
      console.log('values', values);
      const {
        mutators: { push, pop },
      } = form;

      const [isOpen, setIsOpen] = useState(false);
      const [isEditOpen, setIsEditOpen] = useState(false);
      const [deleteIndex, setDeleteIndex] = useState(undefined);

      const getMoney = () => breakdownAmount + (COMMISSION * breakdownAmount) / 100;

      const breakdownAmount = useMemo(() => {
        if (values?.['offering_price'] == null) return 0;
        if (values?.['offering_price']?.amount) return values?.['offering_price']?.amount;
        return 0;
      }, [values?.['offering_price']?.amount]);

      const classes = classNames(rootClassName || css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;

      const { updateListingError, showListingsError } = fetchErrors || {};
      const errorMessage = updateListingError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingFeaturesForm.updateFailed" />
        </p>
      ) : null;

      const errorMessageShowListing = showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingFeaturesForm.showListingFailed" />
        </p>
      ) : null;

      const noOfferings = (values?.offerings ?? []).length == 0;

      const submitDisabled = disabled || submitInProgress || noOfferings;

      const priceRequired = required('A session price starting at $10.00 USD is required.');
      const minPrice = new Money(config.listingMinimumPriceSubUnits, config.currency);
      const minPriceRequired = moneySubUnitAmountAtLeast(
        'A session price starting at $10.00 USD is required.',
        config.listingMinimumPriceSubUnits
      );

      const notFractions = decimalNotAllowed('Price with decimals is not allowed.');

      const priceValidators = config.listingMinimumPriceSubUnits
        ? composeValidators(priceRequired, minPriceRequired, notFractions)
        : priceRequired;

      const onAdd = () => {
        push('offerings', {
          session_format_type: values?.['session_format_type'],
          session_location: values?.['session_location'],
          offering_title: values?.['offering_title'],
          offering_description: values?.['offering_description'],
          offering_style: values?.['offering_style'],
          offering_price: values?.['offering_price'],
          booking_time_availability: values?.['booking_time_availability'],
          offering_accessibility: values?.['offering_accessibility'],
          physical_location_address: (values?.['session_location'] ?? []).includes(
            'physical_location'
          )
            ? values?.['physical_location_address']
            : undefined,
        });

        form.batch(() => {
          form.change('session_format_type', undefined),
            form.change('session_location', undefined),
            form.change('offering_title', undefined),
            form.change('offering_description', undefined),
            form.change('offering_style', undefined),
            form.change('offering_price', new Money(0, config.currency)),
            form.change('booking_time_availability', undefined),
            form.change('offering_accessibility', undefined);
          form.change('physical_location_address', undefined);
        });
        setIsOpen(false);
      };

      const errorMessages = Object.keys(errors);

      const offeringSubmitDisabled =
        !values?.session_format_type ||
        !values?.session_location ||
        !values?.offering_title ||
        !values?.offering_description ||
        !values?.offering_style ||
        !values?.offering_price ||
        !values?.booking_time_availability ||
        !values?.offering_accessibility ||
        errorMessages.includes('session_format_type') ||
        errorMessages.includes('session_location') ||
        errorMessages.includes('offering_title') ||
        errorMessages.includes('offering_description') ||
        errorMessages.includes('offering_style') ||
        errorMessages.includes('offering_price') ||
        errorMessages.includes('booking_time_availability') ||
        errorMessages.includes('offering_accessibility') ||
        errorMessages.includes('physical_location_address');

      // console.log(errorMessages);

      const modal = (
        <Modal
          usePortal
          id="offerings-modal"
          name="offerings-modal"
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
          onManageDisableScrolling={onManageDisableScrolling}
        >
          <div className={classNames('hWalkthroughForm', css.minWidth)}>
            <h3>{intl.formatMessage({ id: 'EditListingOfferingsForm.newOfferingTitle' })}</h3>
            <div className="push-top-smedium">
              <label>
                {intl.formatMessage({ id: 'EditListingOfferingsForm.offeringTitleLabel' })}{' '}
                <span className="red">*</span>
              </label>
              <FieldTextInput
                id="offering_title"
                name="offering_title"
                placeholder={intl.formatMessage({
                  id: 'EditListingOfferingsForm.offeringTitlePlaceholder',
                })}
                className="hwInput"
                inputRootClass="hwInput"
                validate={required(
                  intl.formatMessage({ id: 'EditListingOfferingsForm.offeringTitleRequired' })
                )}
                required
              />
            </div>
            <div className="push-top-smedium">
              <label>
                {intl.formatMessage({ id: 'EditListingOfferingsForm.offeringDescriptionLabel' })}{' '}
                <span className="red">*</span>
              </label>
              <FieldTextInput
                id="offering_description"
                name="offering_description"
                placeholder={intl.formatMessage({
                  id: 'EditListingOfferingsForm.offeringDescriptionPlaceholder',
                })}
                className="hwInput"
                inputRootClass="hwInput"
                type="textarea"
                validate={composeValidators(
                  required(
                    intl.formatMessage({
                      id: 'EditListingOfferingsForm.offeringDescriptionRequired',
                    })
                  ),
                  maxLength(
                    intl.formatMessage({
                      id: 'EditListingOfferingsForm.offeringDescriptionMaxCharsMessage',
                    }),
                    600
                  )
                )}
                required
              />
            </div>
            <div className="push-top-smedium">
              <label>
                {intl.formatMessage({ id: 'EditListingOfferingsForm.sessionOfferingsLabel' })}{' '}
                <span className="red">*</span>
              </label>
              {/* <FieldTextInput
                id="offering_style"
                name="offering_style"
                placeholder="Offering style"
                className="hwInput"
                validate={required('Offering style is required')}
              /> */}
              <FieldSelectModern
                id="offering_style"
                name="offering_style"
                placeholder={intl.formatMessage({
                  id: 'EditListingOfferingsForm.offeringStylePlaceholder',
                })}
                options={config.custom.allModalities}
                isMulti={true}
                validate={requiredFieldArrayCheckbox(
                  intl.formatMessage({ id: 'EditListingOfferingsForm.sessionOfferingsRequired' })
                )}
                required
              />
            </div>
            <div className="push-top-smedium">
              <label>
                {intl.formatMessage({ id: 'EditListingOfferingsForm.sessionFormatLabel' })}{' '}
                <span className="red">*</span>
              </label>
              {/* <FieldTextInput
                className="hwInput"
                inputRootClass="hwInput"
                id="session_format_type"
                name="session_format_type"
                placeholder="Session Format Type"
                validate={required('Session Format Type is required')}
              /> */}
              <div>
                <FieldCheckboxGroup
                  name="session_format_type"
                  id="session_format_type"
                  options={config.custom.offeringSessionFormatOptions}
                  validate={required(
                    intl.formatMessage({ id: 'EditListingOfferingsForm.sessionFormatRequired' })
                  )}
                  twoColumns
                  nopadding
                  required
                />
              </div>
            </div>
            <div className="push-top-smedium">
              <label>
                {intl.formatMessage({ id: 'EditListingOfferingsForm.sessionLocationLabel' })}{' '}
                <span className="red">*</span>
              </label>
              <div>
                {/* {config.custom.sessionLocations.map(sessionLocation => (
                  <FieldRadioButton
                    key={sessionLocation.key}
                    id={sessionLocation.key}
                    value={sessionLocation.key}
                    label={sessionLocation.label}
                    name="session_location"
                  />
                ))} */}
                <div>
                  <FieldCheckboxGroup
                    name="session_location"
                    id="session_location"
                    options={config.custom.sessionLocations}
                    horizontal
                    validate={requiredFieldArrayCheckbox(
                      intl.formatMessage({
                        id: 'EditListingOfferingsForm.sessionLocationRequiredMessage',
                      })
                    )}
                    required
                  />
                </div>
              </div>
            </div>
            {(values?.session_location ?? []).includes('physical_location') && (
              <div className="push-top-smedium">
                <label>
                  {intl.formatMessage({
                    id: 'EditListingOfferingsForm.physicalLocationAddressLabel',
                  })}{' '}
                  <span className="red">*</span>{' '}
                  <span className="grey typeLight">
                    {intl.formatMessage({
                      id: 'EditListingOfferingsForm.physicalLocationAddressSubText',
                    })}
                  </span>
                </label>

                <LocationAutocompleteInputField
                  className={locationCss.locationAddress}
                  inputClassName="hwInput h-36"
                  // inputClassName={locationCss.locationAutocompleteInput}
                  iconClassName={locationCss.locationAutocompleteInputIcon}
                  predictionsClassName={locationCss.predictionsRoot}
                  validClassName={locationCss.validLocation}
                  name="physical_location_address"
                  // label={locationRequiredMessage}
                  placeholder={intl.formatMessage({
                    id: 'EditListingOfferingsForm.physicalLocationAddressPlaceholder',
                  })}
                  useDefaultPredictions={false}
                  format={identity}
                  valueFromForm={values.physical_location_address}
                  validate={composeValidators(
                    autocompleteSearchRequired(
                      intl.formatMessage({
                        id: 'EditListingOfferingsForm.physicalLocationAddressRequiredMessage',
                      })
                    ),
                    autocompletePlaceSelected(
                      intl.formatMessage({
                        id: 'EditListingOfferingsForm.physicalLocationAddresssNotSelected',
                      })
                    )
                  )}
                />
              </div>
            )}

            <div className="push-top-smedium">
              <label>
                {intl.formatMessage({ id: 'EditListingOfferingsForm.sessionPriceLabel' })}{' '}
                <span className="red">*</span>{' '}
                <span className="grey typeLight">
                  {intl.formatMessage({ id: 'EditListingOfferingsForm.sessionPriceSubText' })}
                </span>
              </label>
              <FieldCurrencyInput
                name="offering_price"
                id="offering_price"
                inputRootClass="hwInput"
                currencyConfig={config.currencyConfig}
                validate={priceValidators}
                required
              />

              {breakdownAmount ? (
                <div style={{ marginTop: '1rem' }}>
                  <p
                    style={{
                      borderLeft: '5px solid #17a2b8',
                      padding: '1rem .5rem',
                      color: '#17a2b8',
                      background: 'hsl(188deg 78% 89%)',
                      lineHeight: 1.4,
                    }}
                  >
                    {/* {formatMoney(intl, new Money(getMoney(), config.currency))} is the displayed
                    price &amp; the total paid by clients. This combined amount reflects your rate
                    as well as a bank transaction fee and our service fee. Additionally,{' '}
                    {PROVIDER_COMMISSION}% will be subtracted from your payout to cover bank payout
                    fees and to support the platform as a whole. */}
                    {intl.formatMessage(
                      { id: 'EditListingOfferingsForm.sessionPriceInfoMessage' },
                      {
                        actualSessionPrice: formatMoney(
                          intl,
                          new Money(getMoney(), config.currency)
                        ),
                        providerCommission: PROVIDER_COMMISSION,
                      }
                    )}
                  </p>
                </div>
              ) : null}
            </div>

            <div className="push-top-smedium">
              <label>
                {intl.formatMessage({ id: 'EditListingOfferingsForm.sessionDurationLabel' })}{' '}
                <span className="red">*</span>{' '}
                <span className="grey typeLight">
                  {intl.formatMessage({ id: 'EditListingOfferingsForm.sessionDurationSubText' })}
                </span>
              </label>
              <FieldTextInput
                id="booking_time_availability"
                name="booking_time_availability"
                placeholder={intl.formatMessage({
                  id: 'EditListingOfferingsForm.bookingTimeAvailabilityLabel',
                })}
                type="number"
                step="5"
                className="hwInput"
                inputRootClass="hwInput"
                validate={composeValidators(
                  required(
                    intl.formatMessage({
                      id: 'EditListingOfferingsForm.sessionDurationRequiredMessage',
                    })
                  ),
                  multipleOf5(
                    intl.formatMessage({ id: 'EditListingOfferingsForm.multipleOf5Message' })
                  )
                )}
                required
              />
            </div>
            <div className="push-top-smedium">
              <label>
                {intl.formatMessage({ id: 'EditListingOfferingsForm.sessionAccessibilityLabel' })}{' '}
                <span className="red">*</span>{' '}
                <span className="grey typeLight">
                  {intl.formatMessage({
                    id: 'EditListingOfferingsForm.sessionAccessibilitySubText',
                  })}
                </span>
              </label>
              <FieldTextInput
                id="offering_accessibility"
                name="offering_accessibility"
                placeholder={intl.formatMessage({
                  id: 'EditListingOfferingsForm.sessionAccessibilityPlaceholder',
                })}
                className="hwInput"
                type="textarea"
                inputRootClass="hwInput"
                validate={composeValidators(
                  required(
                    intl.formatMessage({
                      id: 'EditListingOfferingsForm.sessionAccessibilityRequired',
                    })
                  ),
                  maxLength(
                    intl.formatMessage({
                      id: 'EditListingOfferingsForm.sessionAccessibilityMaxCharsMessage',
                    }),
                    300
                  )
                )}
                required
              />
            </div>

            <Button
              onClick={onAdd}
              type="button"
              className={css.button}
              disabled={offeringSubmitDisabled}
            >
              {intl.formatMessage({ id: 'EditListingOfferingsForm.addNewOfferButtonlabel' })}
            </Button>
          </div>
        </Modal>
      );

      // const modal = (
      //   <Modal open={isOpen} onClose={() => setIsOpen(false)} center>
      //     {content}
      //   </Modal>
      // );
      // console.log(values);

      const EditOfferingModalContent = typeof isEditOpen?.index != 'undefined' &&
        values?.offerings?.[isEditOpen?.index]?.offering_price?.amount && (
          <EditOfferingModal
            isOpen={isEditOpen}
            formId={'EditOfferingModal'}
            onClose={() => setIsEditOpen(false)}
            onSubmit={vals => {
              const { physical_location_address, ...restVals } = vals;

              const locationMaybe = (vals?.session_location ?? []).includes('physical_location')
                ? { physical_location_address }
                : {};

              const newVals = {
                ...restVals,
                ...locationMaybe,
              };

              // console.log(newVals);

              // return;

              const edittedOfferings = values?.offerings ?? [];
              edittedOfferings.splice(isEditOpen.index, 1, newVals);
              form.change('offerings', edittedOfferings);
              setIsEditOpen(false);
            }}
            intl={intl}
            onManageDisableScrolling={onManageDisableScrolling}
            initialValues={{
              ...values?.offerings?.[isEditOpen?.index],
              offering_price: values?.offerings?.[isEditOpen?.index]?.offering_price?.amount
                ? new Money(
                    values?.offerings?.[isEditOpen?.index]?.offering_price?.amount,
                    config.currency
                  )
                : null,
              physical_location_address: values?.offerings?.[isEditOpen?.index]
                ?.physical_location_address
                ? {
                    search:
                      values?.offerings?.[isEditOpen?.index]?.physical_location_address
                        ?.selectedPlace?.address,
                    selectedPlace: {
                      address:
                        values?.offerings?.[isEditOpen?.index]?.physical_location_address
                          ?.selectedPlace?.address,
                      origin: new LatLng(
                        values?.offerings?.[
                          isEditOpen?.index
                        ]?.physical_location_address?.selectedPlace?.origin?.lat,
                        values?.offerings?.[
                          isEditOpen?.index
                        ]?.physical_location_address?.selectedPlace?.address?.origin?.lng
                      ),
                    },
                  }
                : undefined,
            }}
          />
        );

      const onDeleteOffering = index => {
        // pop('offerings', index);
        // () => fields.remove(index)
        setDeleteIndex(index);
      };

      const onDelete = () => {
        pop('offerings', deleteIndex);
        setDeleteIndex(undefined);
      };

      const deleteOfferingModal = (
        <Modal
          id="delete-offering-warning"
          isOpen={typeof deleteIndex != 'undefined'}
          onClose={() => setDeleteIndex(undefined)}
          onManageDisableScrolling={onManageDisableScrolling}
          usePortal
        >
          <h3>{intl.formatMessage({ id: 'EditListingOfferingsForm.deleteOfferingModalTitle' })}</h3>
          <p className={css.text}>
            {intl.formatMessage({ id: 'EditListingOfferingsForm.deleteofferingModalText' })}
          </p>
          <Button className={css.deleteButton} type="button" onClick={onDelete}>
            {intl.formatMessage({ id: 'EditListingOfferingsForm.deleteButtonText' })}
          </Button>
        </Modal>
      );

      return (
        <div>
          {EditOfferingModalContent}
          <Form className={classes} onSubmit={handleSubmit}>
            {errorMessage}
            {errorMessageShowListing}
            {isOpen && modal}

            <FieldArray name="offerings">
              {({ fields }) => (
                <div>
                  {fields?.map((name, index) => (
                    <div key={name} className={css.box}>
                      <div>
                        {/* offeringTitle */}
                        {values?.offerings?.[index]?.offering_title && (
                          <div className={css.flex}>
                            <p>Offering Title</p>
                            <p>{values?.offerings?.[index]?.offering_title}</p>
                          </div>
                        )}
                        {/* offering_description */}
                        {values?.offerings?.[index]?.offering_description && (
                          <div className={css.flex}>
                            <p>Offering Description</p>
                            <p>{values?.offerings?.[index]?.offering_description}</p>
                          </div>
                        )}
                        {values?.offerings?.[index]?.offering_style && (
                          <div className={css.flex}>
                            <p>Session Offering/s</p>
                            <p>
                              {values?.offerings?.[index]?.offering_style
                                ?.map(v => v.label)
                                ?.join(', ')}
                            </p>
                          </div>
                        )}
                        {values?.offerings?.[index]?.session_format_type && (
                          <div className={css.flex}>
                            <p>Session Format/s</p>
                            <p>
                              {values?.offerings?.[index]?.session_format_type
                                ?.map(
                                  v =>
                                    config.custom.offeringSessionFormatOptions.find(l => l.key == v)
                                      ?.label
                                )
                                ?.join(', ')}
                            </p>
                          </div>
                        )}
                        {values?.offerings?.[index]?.session_location && (
                          <div className={css.flex}>
                            <p>Session Location/s</p>
                            <p>
                              {values?.offerings?.[index]?.session_location
                                ?.map(v => config.custom.sessionLocations?.find(l => l.key == v))
                                ?.map(v => v.label)
                                ?.join(', ')}
                            </p>
                          </div>
                        )}

                        {values?.offerings?.[index]?.physical_location_address && (
                          <div className={css.flex}>
                            <p>Physical Location Address</p>
                            <p>
                              {
                                values?.offerings?.[index]?.physical_location_address?.selectedPlace
                                  .address
                              }
                            </p>
                          </div>
                        )}

                        {values?.offerings?.[index]?.offering_price && (
                          <div className={css.flex}>
                            <p>Session Price</p>

                            <p>{formatMoney(intl, values?.offerings?.[index]?.offering_price)}</p>
                          </div>
                        )}
                        {values?.offerings?.[index]?.booking_time_availability && (
                          <div className={css.flex}>
                            <p>Session Duration</p>
                            <p>{values?.offerings?.[index]?.booking_time_availability} minutes</p>
                          </div>
                        )}
                        {values?.offerings?.[index]?.offering_accessibility && (
                          <div className={css.flex}>
                            <p>Session Accessibility</p>
                            <p>{values?.offerings?.[index]?.offering_accessibility}</p>
                          </div>
                        )}
                      </div>
                      <div className={css.controls}>
                        <span
                          className={css.close}
                          title="Delete Offering"
                          onClick={onDeleteOffering}
                        >
                          &times;
                        </span>
                        <span
                          className={css.edit}
                          title="Edit Offering"
                          onClick={() => setIsEditOpen({ index })}
                        >
                          <BsFillPencilFill className={css.icon} />
                        </span>
                      </div>
                    </div>
                  ))}
                  {/* <button type="button" onClick={() => fields.push({ name: '' })}>
                  Add
                </button> */}
                </div>
              )}
            </FieldArray>
            {deleteOfferingModal}

            <div className="push-top-smedium">
              <button
                onClick={() => setIsOpen(true)}
                type="button"
                className="btn-large-outline gapped-flex"
              >
                <FaPlusCircle />
                <span>
                  {intl.formatMessage({ id: 'EditListingOfferingsForm.AddOfferingLabel' })}
                </span>
              </button>
            </div>

            <Button
              className={css.submitButton}
              type="submit"
              inProgress={submitInProgress}
              disabled={submitDisabled}
              ready={submitReady}
            >
              {saveActionMsg}
            </Button>
            <GoBackButton url={goBackLink} />
          </Form>
        </div>
      );

      // return (
      //   <Form className={classes} onSubmit={handleSubmit}>
      //     {errorMessage}
      //     {errorMessageShowListing}
      //     <div className="push-top-smedium">
      //       <label className="push-bottom-small">
      //         Background -{' '}
      //         <span className="grey typeLight">
      //           Select options applicable to your lived-experience
      //         </span>
      //       </label>
      //       <div className="highlighted-top">
      //         <div
      //           className="tags flex"
      //           onClick={e => {
      //             const name = e.target.dataset.name;
      //             if (typeof name == 'undefined') return;
      //             handleClick(e.target.dataset.name, 'featured_background_identities');
      //           }}
      //         >
      //           {config.custom.backgroundIdentities
      //             .filter(v => initialValues?.['background_identities'].includes(v.key))
      //             .map(l => (
      //               <li
      //                 style={{
      //                   pointerEvents: allIdentities?.includes(l.key)
      //                     ? 'auto'
      //                     : pointerEventsNoneMaybe,
      //                 }}
      //                 key={l.key}
      //                 data-name={l.key}
      //                 className={
      //                   values?.['featured_background_identities']?.includes(l.key)
      //                     ? 'selected btn greyOutlineRounded'
      //                     : ' btn greyOutlineRounded'
      //                 }
      //               >
      //                 {l.label}
      //               </li>
      //             ))}
      //         </div>
      //       </div>
      //     </div>
      //     <div className="push-top-smedium">
      //       <label className="push-bottom-small">
      //         Disability -{' '}
      //         <span className="grey typeLight">
      //           Select options applicable to your lived-experience
      //         </span>
      //       </label>
      //       <div className="highlighted-top">
      //         <div
      //           className="tags flex"
      //           onClick={e => {
      //             const name = e.target.dataset.name;
      //             if (typeof name == 'undefined') return;
      //             handleClick(e.target.dataset.name, 'featured_disability_identities');
      //           }}
      //         >
      //           {config.custom.disabilitiesIdentity
      //             .filter(v => initialValues?.['disability_identities'].includes(v.key))
      //             .map(l => (
      //               <li
      //                 style={{
      //                   pointerEvents: allIdentities?.includes(l.key)
      //                     ? 'auto'
      //                     : pointerEventsNoneMaybe,
      //                 }}
      //                 key={l.key}
      //                 data-name={l.key}
      //                 className={
      //                   values?.['featured_disability_identities']?.includes(l.key)
      //                     ? 'selected btn greyOutlineRounded'
      //                     : ' btn greyOutlineRounded'
      //                 }
      //               >
      //                 {l.label}
      //               </li>
      //             ))}
      //         </div>
      //       </div>
      //     </div>
      //     <div className="push-top-smedium">
      //       <label className="push-bottom-small">
      //         Sexuality -{' '}
      //         <span className="grey typeLight">
      //           Select options applicable to your lived-experience
      //         </span>
      //       </label>
      //       <div className="highlighted-top">
      //         <div
      //           className="tags flex"
      //           onClick={e => {
      //             const name = e.target.dataset.name;
      //             if (typeof name == 'undefined') return;
      //             handleClick(e.target.dataset.name, 'featured_sexuality_identities');
      //           }}
      //         >
      //           {config.custom.sexualityIdentities
      //             .filter(v => initialValues?.['sexuality_identities'].includes(v.key))
      //             .map(l => (
      //               <li
      //                 style={{
      //                   pointerEvents: allIdentities?.includes(l.key)
      //                     ? 'auto'
      //                     : pointerEventsNoneMaybe,
      //                 }}
      //                 key={l.key}
      //                 data-name={l.key}
      //                 className={
      //                   values?.['featured_sexuality_identities']?.includes(l.key)
      //                     ? 'selected btn greyOutlineRounded'
      //                     : ' btn greyOutlineRounded'
      //                 }
      //               >
      //                 {l.label}
      //               </li>
      //             ))}
      //         </div>
      //       </div>
      //     </div>
      //     <div className="push-top-smedium">
      //       <label className="push-bottom-small">
      //         Spirituality -{' '}
      //         <span className="grey typeLight">
      //           Select options applicable to your lived-experience
      //         </span>
      //       </label>
      //       <div className="highlighted-top">
      //         <div
      //           className="tags flex"
      //           onClick={e => {
      //             const name = e.target.dataset.name;
      //             if (typeof name == 'undefined') return;
      //             handleClick(e.target.dataset.name, 'featured_spirituality_identities');
      //           }}
      //         >
      //           {config.custom.spiritualityIdentities
      //             .filter(v => initialValues?.['spirituality_identities'].includes(v.key))
      //             .map(l => (
      //               <li
      //                 style={{
      //                   pointerEvents: allIdentities?.includes(l.key)
      //                     ? 'auto'
      //                     : pointerEventsNoneMaybe,
      //                 }}
      //                 key={l.key}
      //                 data-name={l.key}
      //                 className={
      //                   values?.['featured_spirituality_identities']?.includes(l.key)
      //                     ? 'selected btn greyOutlineRounded'
      //                     : ' btn greyOutlineRounded'
      //                 }
      //               >
      //                 {l.label}
      //               </li>
      //             ))}
      //         </div>
      //       </div>
      //     </div>

      //     <Button
      //       className={css.submitButton}
      //       type="submit"
      //       inProgress={submitInProgress}
      //       disabled={submitDisabled}
      //       ready={submitReady}
      //     >
      //       {saveActionMsg}
      //     </Button>
      //   </Form>
      // );
    }}
  />
);

EditListingOfferingsFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  fetchErrors: null,
  filterConfig: config.custom.filters,
};

EditListingOfferingsFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  name: string.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  filterConfig: propTypes.filterConfig,
};

const EditListingOfferingsForm = injectIntl(EditListingOfferingsFormComponent);

export default EditListingOfferingsForm;
