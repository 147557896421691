import React, { Component } from 'react';
import { array, string, func } from 'prop-types';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import { lazyLoadWithDimensions } from '../../util/contextHelpers';
import { LINE_ITEM_DAY, LINE_ITEM_NIGHT, propTypes } from '../../util/types';
import { formatMoney } from '../../util/currency';
import { ensureListing } from '../../util/data';
import { richText } from '../../util/richText';
import { findOptionsForSelectFilter } from '../../util/search';
import { createSlug } from '../../util/urlHelpers';
import config from '../../config';
import { NamedLink, ResponsiveImage } from '../../components';
import { types as sdkTypes } from '../../util/sdkLoader';

import css from './ListingCard.module.css';
import { getPriceWithComission } from '../../util/functions';
const { Money } = sdkTypes;

const MIN_LENGTH_FOR_LONG_WORDS = 10;

const priceData = (price, intl) => {
  if (price && price.currency === config.currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: intl.formatMessage(
        { id: 'ListingCard.unsupportedPrice' },
        { currency: price.currency }
      ),
      priceTitle: intl.formatMessage(
        { id: 'ListingCard.unsupportedPriceTitle' },
        { currency: price.currency }
      ),
    };
  }
  return {};
};

const getCertificateInfo = (certificateOptions, key) => {
  return certificateOptions.find(c => c.key === key);
};

class ListingImage extends Component {
  render() {
    return <ResponsiveImage {...this.props} />;
  }
}
const LazyImage = lazyLoadWithDimensions(ListingImage, { loadAfterInitialRendering: 3000 });

export const ListingCardComponent = props => {
  const {
    className,
    rootClassName,
    intl,
    listing,
    renderSizes,
    filtersConfig,
    setActiveListing,
  } = props;
  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureListing(listing);
  if (!currentListing) return null;
  const id = currentListing.id.uuid;
  const { title = '', price, publicData, metadata } = currentListing.attributes;
  const { listingTitle } = metadata || {};

  const { primaryImage } = publicData;
  const slug = createSlug(title);
  const firstImage =
    currentListing.images && currentListing.images.length > 0 ? currentListing.images[0] : null;

  const certificateOptions = findOptionsForSelectFilter('certificate', filtersConfig);
  const certificate = publicData
    ? getCertificateInfo(certificateOptions, publicData.certificate)
    : null;
  const { formattedPrice, priceTitle } = priceData(price, intl);

  const unitType = config.bookingUnitType;
  const isNightly = unitType === LINE_ITEM_NIGHT;
  const isDaily = unitType === LINE_ITEM_DAY;

  const unitTranslationKey = isNightly
    ? 'ListingCard.perNight'
    : isDaily
    ? 'ListingCard.perDay'
    : 'ListingCard.perUnit';

  const featuredIdentities = (currentListing?.attributes?.publicData?.featured_identities ?? [])
    .map(v => config.custom.allIdentities.find(i => i.key == v))
    .map(v => v.label)
    .join('•');

  const businessName = currentListing?.attributes?.publicData?.business_name;
  const offerings = currentListing?.attributes?.publicData?.offerings ?? [];
  const modalities = currentListing?.attributes?.publicData?.modalities ?? [];
  const address = currentListing?.attributes?.publicData?.location?.address;
  // console.log({ publicData });
  const offeringTitles = offerings.map(o => o.offering_title).join(' • ');

  const modalitiesTitles = modalities
    .map(o => config.custom.allModalities.find(m => m.key == o)?.label)
    .join(' • ');

  let lowestPrice;

  offerings.forEach(off => {
    if (!lowestPrice) {
      lowestPrice = getPriceWithComission(off.offering_price);
    } else if (off.offering_price < lowestPrice) {
      lowestPrice = getPriceWithComission(off.offering_price);
    }
  });

  // if (lowestPrice) {
  //   lowestPrice = Math.ceil(lowestPrice / 100) * 100;
  // }
  if (lowestPrice) {
    lowestPrice = Math.ceil(lowestPrice / 100);
  }
  const formattedLowestPrice = lowestPrice ? `$${lowestPrice}` : null;

  return (
    // <NamedLink className={classes} name="ListingPage" params={{ id, slug }}>
    <NamedLink
      className={classes}
      name={listingTitle ? 'FetchListing' : 'ListingPage'}
      params={listingTitle ? { listingTitle: listingTitle } : { id, slug }}
    >
      <div className={classNames('healerCard', css.grid)}>
        <div
          className={css.threeToTwoWrapper}
          onMouseEnter={() => setActiveListing(currentListing.id)}
          onMouseLeave={() => setActiveListing(null)}
        >
          <div className={css.aspectWrapper}>
            <LazyImage
              rootClassName={css.rootForImage}
              alt={title}
              image={firstImage}
              variants={['landscape-crop', 'landscape-crop2x']}
              sizes={renderSizes}
              customImage={primaryImage}
            />
          </div>
        </div>
        <div className="col">
          <div className="hcLeftAligned">
            <div className="flexCardLeft">
              <div>
                {featuredIdentities && <h6>{featuredIdentities}</h6>}
                <h4 className={css.title}>{title}</h4>
              </div>
              <div>
                {businessName && <p className="strong grey">{businessName}</p>}
                {/* <p>{offeringTitles}</p> */}
                <p>{modalitiesTitles}</p>
                <p className={css.offeringsAddress}>
                  {offerings.length} offerings •{' '}
                  {/* {address?.length > 40 ? address?.slice(40) + '...' : address} */}
                  <span className={css.address}>{address}</span>
                </p>
              </div>
              <div>
                <p className="strong grey">
                  {intl.formatMessage({ id: 'ListingCard.offeringStartsAtLabel' })}
                </p>
                <h3 className="price">{formattedLowestPrice}</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </NamedLink>
  );

  return (
    <NamedLink className={classes} name="ListingPage" params={{ id, slug }}>
      <div
        className={css.threeToTwoWrapper}
        onMouseEnter={() => setActiveListing(currentListing.id)}
        onMouseLeave={() => setActiveListing(null)}
      >
        <div className={css.aspectWrapper}>
          <LazyImage
            rootClassName={css.rootForImage}
            alt={title}
            image={firstImage}
            variants={['landscape-crop', 'landscape-crop2x']}
            sizes={renderSizes}
          />
        </div>
      </div>
      <div className={css.info}>
        {/* <div className={css.price}>
          <div className={css.priceValue} title={priceTitle}>
            {formattedPrice}
          </div>
          <div className={css.perUnit}>
            <FormattedMessage id={unitTranslationKey} />
          </div>
        </div> */}
        <div className={css.mainInfo}>
          <div className={css.title}>
            {richText(title, {
              longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
              longWordClass: css.longWord,
            })}
          </div>
          <div className={css.certificateInfo}>
            {certificate && !certificate.hideFromListingInfo ? (
              <span>{certificate.label}</span>
            ) : null}
          </div>
        </div>
      </div>
    </NamedLink>
  );
};

ListingCardComponent.defaultProps = {
  className: null,
  rootClassName: null,
  renderSizes: null,
  filtersConfig: config.custom.filters,
  setActiveListing: () => null,
};

ListingCardComponent.propTypes = {
  className: string,
  rootClassName: string,
  filtersConfig: array,
  intl: intlShape.isRequired,
  listing: propTypes.listing.isRequired,

  // Responsive image sizes hint
  renderSizes: string,

  setActiveListing: func,
};

export default injectIntl(ListingCardComponent);
